import { Component, Input } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';


@Component({
    selector: 'app-edit-wtdetail',
    template: `
    <dx-scroll-view height='100%'>
        <dx-form [colCount]="2" [formData]='wtDetailData' [showValidationSummary]="false" validationGroup="wkValidation">
            <dxi-item itemType="group" [colSpan]="2" [colCount]='2'>
                <dxi-item [label]="{text: ds.lg.format('NAME')}" dataField="name" editorType="dxTextBox" [editorOptions]="{}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item [label]="{text: ds.lg.format('FISCAL_CODE')}" dataField="cif" editorType="dxTextBox" [editorOptions]="{}"></dxi-item>
                <dxi-item [label]="{text: ds.lg.format('UNIT_TYPE')}" dataField="typeId" editorType="dxSelectBox"
                [editorOptions]="{ dataSource: ds.static.unitType, valueExpr: 'id', displayExpr: 'value' }">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item [label]="{text: ds.lg.format('LOCALITY')}" dataField="localityId" editorType="dxSelectBox"
                    [editorOptions]="{ dataSource: dsLocality, valueExpr: 'id', displayExpr: 'name' }">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item [label]="{text: ds.lg.format('ADDRESS')}" dataField="address" editorType="dxTextBox" [editorOptions]="{}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item [label]="{text: ds.lg.format('ACTIVE')}" dataField="isActiv" editorType="dxCheckBox" [editorOptions]="{ }"></dxi-item>
                <dxi-item [label]="{text: ds.lg.format('SUBCONTRACTOR')}" dataField="isSubcontractor" editorType="dxCheckBox"
                    [editorOptions]="{ }"></dxi-item>
                <dxi-item [label]="{text: ds.lg.format('PARENT')}" dataField="parentId" editorType="dxSelectBox" [editorOptions]="{
                    dataSource: dsUnitsExcept, valueExpr: 'id', displayExpr: 'name', disabled: !wtDetailData.isSubcontractor}"></dxi-item>
                <dxi-item [label]="{text: ds.lg.format('WASTE_RESPONSABLE')}" dataField="wasteResponsible" editorType="dxTextBox"
                    [editorOptions]="{ }"></dxi-item>
                <dxi-item [label]="{text: ds.lg.format('ENVIRONMENT_RESPONSABLE')}" dataField="environmentResponsible" editorType="dxTextBox"
                    [editorOptions]="{ }"></dxi-item>
            </dxi-item>
        </dx-form>
    </dx-scroll-view>`
})

export class EditWastetypeDetailComponent {

    @Input() wtDetailData: any;
    dsLocality: any;
    dsUnitsExcept: CustomStore;

    constructor(public ds: DataService) {
        this.dsLocality = new DataSource({
            store: this.ds.geographicAreas(1),
            paginate: true,
            pageSize: 50
        });
        this.dsUnitsExcept = this.ds.getParty('un');
    }
}
