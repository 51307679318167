import { Injectable } from '@angular/core';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import { DataService } from '../../../service/data.service';
import { iBisEditService } from '@dohu/ibis-common';

@Injectable()
export class EditPartyLinkService extends iBisEditService {

    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'lkValidation';
    }

    updateWasteInfo(id: string) {
        const q = new EntityQuery('WasteType').eq('id', id);
        q.fields.push('name', 'hazard');
        EntityStore.fromQuery(q).single().then((obj) => {
            if (obj) {
                this.model.wt_name = obj.name;
                this.model.wt_hazard = obj.hazard;
            }
        });
    }

    createDefault(): any {
        return { isActiv: true };
    }

    getById(id: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const q = new EntityQuery('WasteTypePartyLinkView').eq('l.id', id);
            q.fields.push('l.id', 'l.workCenterId', 'l.senderId', 'l.wasteTypeId', 'l.observations', 'l.isActiv', 'wt.name', 'wt.hazard');
            EntityStore.fromQuery(q).single().then((obj) => {
                if (obj === null) {
                    reject();
                } else {
                    resolve({
                        id: obj.l_id, workCenterId: obj.l_workCenterId, senderId: obj.l_senderId,
                        wasteTypeId: obj.l_wasteTypeId, observations: obj.l_observations, isActiv: obj.wtp_isActiv,
                        wt_name: obj.wt_name, wt_hazard: obj.wt_hazard
                    });
                }
            }, (e) => reject(e));
        });
    }

    onRemove(id: string): Promise<void> {
        return new Promise<void>((resolve, reject) => reject());
    }

    onSaveEv(): Promise<any> {
        const s = EntityStore.fromQuery(new EntityQuery('WasteTypePartyLink'));
        return this.model.id ? s.update(this.model.id, this.model) : s.insert(this.model);
    }

    reset(): void {
        this.model = this.createDefault();
    }
}
