import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';
import { EditActualStockService } from './editActualStock.service';
import notify from 'devextreme/ui/notify';

@Component({
    selector: 'app-edit-actual-stock',
    templateUrl: './editActualStock.component.html'
})

export class EditActualStockComponent implements OnInit {
    dsWasteType: any;
    @ViewChild('gridActualStock') gridActualStock: DxDataGridComponent;
    constructor(public ds: DataService, public ui: UIService, public edit: EditActualStockService) { }
    ngOnInit() { }

    // onInitializedEv(ev: any) {
    //     ev.component.selectRows(this.edit.selectedKeys, true);
    // }

    onSave = (ev: any) => {
        this.gridActualStock.instance.saveEditData().then(data => {
            const selData = this.gridActualStock.instance.getSelectedRowsData();
            this.edit.dsSource = [];
            for (const s of selData) {
                this.edit.dsSource.push({ id: s.id, type: s.typeId, quantity: s.quantity, remain: s.remain });
            }
            this.edit.onSaveClose(ev);
        });
    }

    onStockUpdating(ev: any) {
        if (ev.newData.quantity > ev.oldData.remain) {
            ev.cancel = true;
            return;
        }
        if (ev.newData.quantity < 0) {
            ev.cancel = true;
            return;
        }
        setTimeout(() => {
            const selData = ev.component.getSelectedRowsData();
            this.edit.model.totalQuantity = 0;
            for (const s of selData) {
                this.edit.model.totalQuantity += s.quantity;
            }
            this.edit.model.totalQuantity = Math.round(this.edit.model.totalQuantity * Math.pow(10, 6)) / Math.pow(10, 6);
        }, 0);
    }

    onEditingStart = (ev: any) => {
        const keyExist = ev.component.getSelectedRowKeys().find(x => x === ev.key);
        if (!keyExist) {
            ev.cancel = true;
            notify('Selectati linia inainte de editare', 'error', 3000);
        }
    }

    onSelectionChange(ev: any) {
        if (ev.currentSelectedRowKeys && ev.currentSelectedRowKeys.length > 0) {
            for (const key of ev.currentSelectedRowKeys) {
                const row = this.edit.dsStock.find(x => x.id === key);
                if (row) {
                    row.quantity = row.quantity === 0 ? row.remain : row.quantity;
                    this.edit.model.totalQuantity += row.quantity;
                }
            }
        }
        if (ev.currentDeselectedRowKeys && ev.currentDeselectedRowKeys.length > 0) {
            for (const key of ev.currentDeselectedRowKeys) {
                const row = this.edit.dsStock.find(x => x.id === key);
                if (row) {
                    this.edit.model.totalQuantity -= row.quantity;
                    // row.quantity = 0;
                }
            }
        }
        this.edit.model.totalQuantity = Math.round(this.edit.model.totalQuantity * Math.pow(10, 6)) / Math.pow(10, 6);
    }

    onStockShown(ev: any) {
        if (this.edit.model.workCenterId) {
            this.dsWasteType = this.ds.getWasteTypeFromStock(this.edit.model.workCenterId, this.edit.model.wasteTypeId);
            //this.dsWasteType = this.ds.getWasteTypeForWorkCenter(this.edit.model.workCenterId);
        }
        if (!this.edit.model.wasteTypeId) {
            this.edit.dsSource = [];
        }
        if (this.gridActualStock.instance) {
            if (this.gridActualStock.instance.getSelectedRowKeys().length > 0) {
                this.gridActualStock.instance.deselectAll();
                this.edit.model.totalQuantity = 0;
            }
            this.gridActualStock.instance.selectRows(this.edit.selectedKeys, false);
        }
    }

    loadStockDataSource(ev: any) {
        if (ev.event && ev.value) {
            this.ds.getActualStock(ev.value, this.edit.model.workCenterId).load().then(dd => {
                if (dd && dd.data) {
                    for (const i of dd.data) {
                        i.quantity = 0;
                        i.totalRemain = i.remain;
                    }
                    this.edit.dsStock = dd.data;
                    this.edit.model.totalQuantity = 0;
                    this.edit.model.dsSource = [];
                }
            });
        }

    }
}
