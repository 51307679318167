import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { currentTheme } from 'devextreme/viz/themes';
import DxThemes from 'devextreme/ui/themes';
// import { UpgradeModule } from '@angular/upgrade/static';

if (environment.production) {
  enableProdMode();
}

// document.addEventListener('DOMContentLoaded', function (event) {
//   // currentTheme('material.light');
//   // DxThemes.current('material.light');

//   platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {
//     // const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
//     // upgrade.bootstrap(document.documentElement, ['app']);
//   });
// });

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
