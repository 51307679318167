import { Component, OnInit, ViewChild } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { iBisAuthService } from '@dohu/ibis-auth';
import { DataService } from '../../service/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { custom } from 'devextreme/ui/dialog';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    year: number;
    registerData: any;
    forgotPassword: boolean;
    resetEmail: string;
    termCond: string;
    @ViewChild('emailValidation') emailValidation: DxValidationGroupComponent;

    constructor(private auth: iBisAuthService, public ds: DataService, public activeRoute: ActivatedRoute, public router: Router) {
        this.year = new Date().getFullYear();
        this.activeRoute.queryParams.subscribe(params => {
            this.forgotPassword = params['forgotPassword'] === 'true';
        });
    }

    ngOnInit() {
        this.termCond = environment.termCond;
        this.registerData = { isConfirm: false };
    }

    onRegister(e: any) {
        const validation = e.validationGroup.validate();
        if (!validation.isValid) {
            notify(this.ds.lg.format('ALL_REQ_FIELDS', null), 'error', 3000);
            return;
        }

        const fields = ['_Companie_', '_CIF_', '_Telefon_'];
        const values = new Array();
        for (let i = 0; i < fields.length; i++) {
            values.push(this.registerData[fields[i]]);
        }
        const obj = {
            registerType: 'QHSE',
            fullName: this.registerData.fullName,
            emailAddress: this.registerData.emailAddress,
            deployId: environment.deployId,
            fields: fields,
            values: values
        };

        const customDialog = custom({
            title: this.ds.lg.format('CONFIRM_REGISTER', null),
            message: this.ds.lg.format('CONFIRMATION_EMAIL', null),
            buttons: [{
                text: 'OK',
                onClick: (e) => {
                    this.router.navigateByUrl('/login');
                    this.registerData = { isConfirm: false };
                    this.ds.isButtonDisable = false;
                }
            }],
            showTitle: true
        });
        this.ds.isButtonDisable = true;
        this.auth.register(obj).then(() => {
           customDialog.show();
        }, (error: any) => {
           this.ds.serverError(error);
           this.ds.isButtonDisable = false;
        });
    }

    onResetPassword(e: any) {
        const validation = e.validationGroup.validate();
        if (!validation.isValid) {
            return;
        }
        this.ds.isButtonDisable = true;
        this.auth.forgotPassword(this.resetEmail).then((result) => {
            // raspunsul vine prea devreme
            setTimeout(() => {
                this.ds.isButtonDisable = false;
                if (result === 'OK') {
                    notify(this.ds.lg.format('CONFIRMATION_EMAIL'), 'success', 10000);
                    this.emailValidation.instance.reset();
                    setTimeout(() => {
                        this.router.navigateByUrl('/login');
                    }, 3000);
                } else {
                    notify(this.ds.lg.format('EMAIL_ADDRESS_IS_INVALID'), 'error', 10000);
                }
            }, 500);
        }, (error: any) => {
            this.ds.serverError(error);
            this.ds.isButtonDisable = false;
        });
    }
}
