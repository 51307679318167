import { Component, Input, NgModule } from '@angular/core';
import { DataService } from '../../../service/data.service';
import DataSource from 'devextreme/data/data_source';
import { DxScrollViewModule, DxFormModule, DxPopupModule } from 'devextreme-angular';
import { EditPermitService } from './editPermit.service';
import { UIService } from '../../../service/ui.service';

@Component({
    selector: 'app-edit-permit',
    templateUrl: 'editPermit.component.html'
})
export class EditPermitComponent {
    @Input() permitData: any;
    dsPermitsType: any;
    dsBoolean: Array<{ id, name }>;

    pWithActiv: DataSource;


    constructor(public ds: DataService, public ui: UIService, public edit: EditPermitService) {
        this.dsPermitsType = this.ds.static.documentType;
        this.pWithActiv = this.ds.getPartyLinkViewPaginate([1, 2, 3, 4]);
        this.dsBoolean = this.ds.boolean('bool');
    }
}

