import { Component, OnInit, OnDestroy } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import notify from 'devextreme/ui/notify';
import { alert } from 'devextreme/ui/dialog';
import { EntityStoreOptions, EntityStore } from '@dohu/ibis-entity';
import { HttpClient } from '@angular/common/http';
import { iBisLanguageService } from '@dohu/ibis-common';
import * as st from '../../../../assets/static.json';
import { environment } from '../../../../environments/environment';
import { DataService } from '../../../service/data.service';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs';
import { UIService } from '../../../service/ui.service';

@Component({
    selector: 'app-billing',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit, OnDestroy {
    offerData: any = {};
    subscriptionData: any;
    extraInOffer: any = [];
    addWorkCenterButton: any;
    addUserButton: any;
    addWasteCodeButton: any;

    popoverData: any;
    popoverTarget: string;
    staticFile: typeof st;

    isPopoverVisible: boolean;
    userVisibleCondition: boolean;
    workCenterVisibleCondion: boolean;
    wasteCodeVisibleCondition: boolean;

    totalToPay: number;
    updateTotal: Subject<any> = new Subject<any>();
    watchTotal: Subscription;

    constructor(public ds: DataService, public auth: iBisAuthService, http: HttpClient, public lg: iBisLanguageService,
            public ui: UIService) {
        EntityStoreOptions.Http = http;
        this.staticFile = st;
    }

    ngOnInit() {
        this.offerData = { fromDate: new Date() };
        this.subscriptionData = st.default.subscriptionData;

        this.watchTotal = this.updateTotal.subscribe(
            (response: any) => {
                const offerPrice = parseInt(this.offerData.priceArray[this.offerData.price - 1].name, 10);
                if (response.event === 'recalculate') {
                    let offerExtra = 0;
                    if (this.extraInOffer.length) {
                        this.extraInOffer.forEach((e: any) => {
                            offerExtra += e.price;
                        });
                    }
                    this.totalToPay = offerPrice + offerExtra;
                } else if (response.event === 'reset') {
                    this.totalToPay = offerPrice;
                } else if (response.event === 'minus') {
                    if (response.data.quantity >= 2) {
                        response.data.quantity = response.data.quantity - 1;
                        response.data.price = (this.offerData.id === 3 ? 50 : 15) * response.data.quantity;
                        switch (response.data.id) {
                            case 1:
                                response.data.name = response.data.quantity +
                                    ' x ' + this.ds.lg.format('WORKSTATION', null);
                                break;
                            case 2:
                                response.data.name = response.data.quantity + ' x ' + this.ds.lg.format('USER', null);
                                break;
                            case 3:
                                response.data.name = response.data.quantity + ' x ' + this.ds.lg.format('WASTE_CODE', null);
                                break;
                        }
                    } else {
                        const findIndex = this.extraInOffer.findIndex((x: any) => x.id === response.data.id);
                        this.extraInOffer.splice(findIndex, 1);
                    }
                    this.updateTotal.next({ event: 'recalculate' });
                }
            });

        this.addWorkCenterButton = {
            icon: 'add',
            text: this.ds.lg.format('WORKSTATION', null),
            onClick: () => {
                const existingOffer = this.extraInOffer.find((x: any) => x.id === 1);
                if (existingOffer) {
                    existingOffer.quantity = existingOffer.quantity + 1;
                    existingOffer.price = (this.offerData.id === 3 ? 50 : 15) * existingOffer.quantity;
                    existingOffer.name = existingOffer.quantity +
                        ' x ' + this.ds.lg.format('WORKSTATION', null);
                    this.updateTotal.next({ event: 'recalculate' });
                    return;
                }
                switch (this.offerData.id) {
                    case 1:
                    case 2:
                        this.extraInOffer.push({ id: 1, name: this.ds.lg.format('WORKSTATION', null), price: 15, quantity: 1 });
                        break;
                    case 3:
                        this.extraInOffer.push({ id: 1, name: this.ds.lg.format('WORKSTATION', null), price: 50, quantity: 1 });
                }
                this.updateTotal.next({ event: 'recalculate' });
            }
        };
        this.addUserButton = {
            icon: 'add',
            text: this.ds.lg.format('USER', null),
            onClick: () => {
                const existingOffer = this.extraInOffer.find((x: any) => x.id === 2);
                if (existingOffer) {
                    existingOffer.quantity = existingOffer.quantity + 1;
                    existingOffer.price = 15 * existingOffer.quantity;
                    existingOffer.name = existingOffer.quantity + ' x ' + this.ds.lg.format('USER', null);
                    this.updateTotal.next({ event: 'recalculate' });
                    return;
                }
                switch (this.offerData.id) {
                    case 1:
                    case 2:
                        this.extraInOffer.push({ id: 2, name: this.ds.lg.format('USER', null), price: 15, quantity: 1 });
                        break;
                }
                this.updateTotal.next({ event: 'recalculate' });
            }
        };
        this.addWasteCodeButton = {
            icon: 'add',
            text: this.ds.lg.format('WASTE_CODE', null),
            onClick: () => {
                const existingOffer = this.extraInOffer.find((x: any) => x.id === 3);
                if (existingOffer) {
                    existingOffer.quantity = existingOffer.quantity + 1;
                    existingOffer.price = 15 * existingOffer.quantity;
                    existingOffer.name = existingOffer.quantity + ' x ' + this.ds.lg.format('WASTE_CODE', null);
                    this.updateTotal.next({ event: 'recalculate' });
                    return;
                }
                this.extraInOffer.push({ id: 3, name: this.ds.lg.format('WASTE_CODE', null), price: 15, quantity: 1 });
                this.updateTotal.next({ event: 'recalculate' });
            }
        };
    }

    ngOnDestroy() {
        this.watchTotal.unsubscribe();
    }

    onDateChanged = (ev: any) => {
        if (ev.value) {
            if (this.offerData.price && this.offerData.price === 2) {
                this.offerData.thruDate = new Date(new Date(ev.value).setFullYear(new Date(ev.value).getFullYear() + 1));
            } else if (this.offerData.price && this.offerData.price === 1) {
                this.offerData.thruDate = new Date(new Date(ev.value).setMonth(new Date(ev.value).getMonth() + 1));
            }
        }
    }

    onPriceChanged = (ev: any) => {
        if (ev.value === 1) {
            this.offerData.thruDate = new Date(new Date(this.offerData.fromDate)
                .setMonth(new Date(this.offerData.fromDate).getMonth() + 1));
        } else if (ev.value === 2) {
            this.offerData.thruDate = new Date(new Date(this.offerData.fromDate)
                .setFullYear(new Date(this.offerData.fromDate).getFullYear() + 1));
        }
        this.updateTotal.next({ event: 'recalculate' });
    }


    setOffer(offerId: number) {
        this.subscriptionData.forEach((el: any) => {
            if (el.id === offerId) {
                document.getElementById('box_' + offerId).style.border = '3px dashed gray';
                this.offerData = el;
                this.offerData.fromDate = new Date();
                this.offerData.thruDate = new Date(new Date(this.offerData.fromDate)
                    .setMonth(new Date(this.offerData.fromDate).getMonth() + 1));
                this.offerData.price = 1;
                this.isPopoverVisible = false;
            } else {
                document.getElementById('box_' + el.id).style.border = 'none';
            }
        });
        this.extraInOffer = [];
        this.updateTotal.next({ event: 'reset' });
        switch (offerId) {
            case 1:
                this.userVisibleCondition = true;
                this.workCenterVisibleCondion = true;
                this.wasteCodeVisibleCondition = true;
                break;
            case 2:
                this.userVisibleCondition = true;
                this.workCenterVisibleCondion = true;
                this.wasteCodeVisibleCondition = false;
                break;
            case 3:
                this.userVisibleCondition = false;
                this.workCenterVisibleCondion = true;
                this.wasteCodeVisibleCondition = false;
                break;
            case 4:
                this.userVisibleCondition = false;
                this.workCenterVisibleCondion = false;
                this.wasteCodeVisibleCondition = false;
        }
    }

    minusExtra(minusData: any) {
        this.updateTotal.next({ event: 'minus', data: minusData });
    }

    setBoxColor(offerId: number) {
        switch (offerId) {
            case 1:
                return '#a9d971';
            case 2:
                return '#f26f5a';
            case 3:
                return '#a886e0';
            case 4:
                return 'rgb(107, 149, 211)';
        }
    }

    togglePopover(offerId: number) {
        if (offerId) {
            this.isPopoverVisible = true;
            this.popoverTarget = '#box_' + offerId;
            this.popoverData = this.subscriptionData[offerId - 1];
        } else {
            this.isPopoverVisible = false;
        }
    }

    sendOfferRequest(ev: any) {
        const validation = ev.validationGroup.validate();
        if (!validation.isValid) {
            notify(this.ds.lg.format('ALL_REQ_FIELDS', null), 'error', 3000);
            return;
        }
        const obj: any = {
            fromDate: new Date(this.offerData.thruDate),
            thruDate: new Date(this.offerData.fromDate),
            name: this.offerData.name,
            price: this.offerData.price,
            observations: this.offerData.observations,
            extra: this.extraInOffer,
            deployId: environment.deployId
        };
        EntityStore.execute('RequestLicense', obj, environment.saasServer).then(data => {
            alert('An administrator will contact you as soon as possible', 'Contract confimed');
        });
    }


}
