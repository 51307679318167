import { Injectable } from '@angular/core';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import { iBisEditService } from '@dohu/ibis-common';
import { iBisLanguageService } from '@dohu/ibis-common';

@Injectable()
export class EditTransferService extends iBisEditService {

    validation: string;
    wasteTypeId: string;
    constructor(lg: iBisLanguageService) {
        super(lg);
        this.validation = 'trfValidation';
    }

    createDefault(): any {
        return { disabledSource: false };
    }

    public get title() {
        if (!this.model || !this.lg) {
            return '';
        }
        return this.model.t_id ? this.lg.format("EDIT_TRANSFER") : this.lg.format("ADD_TRANSFER")
    }

    getById(id: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            EntityStore.fromQuery(new EntityQuery('TransferView').eq('t_id', id)).single().
                then((obj) => {
                    if (obj === null) {
                        reject();
                    } else {
                        resolve(obj);
                    }
                }, (e) => reject(e));
        });
    }

    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Transfer')).remove(id);
    }

    onSaveEv(): Promise<any> {
        const obj = {
            sourceId: this.model.sourceId,
            senderWorkCenterId: this.model.t_senderWorkCenterId,
            destinationWorkCenterId: this.model.t_destinationWorkCenterId,
            sendDate: this.model.t_sendDate,
            wasteTypeId: this.model.t_wasteTypeId,
            quantity: this.model.t_quantity
        };
        const s = EntityStore.fromQuery(new EntityQuery('Transfer'));
        return this.model.t_id ? s.update(this.model.t_id, obj) : s.insert(obj);
    }

    reset(): void {
        this.model = this.createDefault();
    }
}
