import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';
import CustomStore from 'devextreme/data/custom_store';
import { ActivatedRoute } from '@angular/router';
import { EditContractService } from './editContract.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { environment } from '../../../../environments/environment';
import notify from 'devextreme/ui/notify';
import { iBisFileService } from '@dohu/ibis-common';

@Component({
    selector: 'app-contract',
    templateUrl: './contract.component.html'
})
export class ContractComponent implements OnInit {

    dsContractType: CustomStore;
    dsWorkPoint: CustomStore;
    dsContract: CustomStore;

    dsContractFile: CustomStore;
    dsBoolean: { id: any; name: any; }[];

    onDownloadClick: any;
    onUploadClick: any;
    onFileBtnVisible: any;
    onAdminTab: boolean;

    onEditContractTypeClick: any;

    @ViewChild('gridContractType') gridContractType: DxDataGridComponent;
    @ViewChild('gridWorkCenter') gridWorkCenter: DxDataGridComponent;
    @ViewChild('gridContractAnnex') gridContractAnnex: DxDataGridComponent;
    partyType: any;
    partyTypeName: string;

    constructor(public ds: DataService, public ui: UIService, public activeRoute: ActivatedRoute,
        public edit: EditContractService, private file: iBisFileService) {
        this.onEditContractTypeClick = this.onEditContractTypeClickEv.bind(this);

        this.onFileBtnVisible = this.onFileBtnVisibleEv.bind(this);

        this.onDownloadClick = this.onDownloadClickEv.bind(this);
        this.onUploadClick = this.onUploadClickEv.bind(this);

        this.activeRoute.queryParams.subscribe(params => {
            this.partyType = params['partyType'];
            if (this.partyType) {
                const ctrType = this.partyType.indexOf('0') > -1 ? 0 : (this.partyType.indexOf('2') > -1 ? 2 : 1);
                this.dsContractType = this.ds.getEnumValue(this.ds.static.contractTypeKeys[ctrType]);
                if (typeof this.partyType === 'object') {
                    const activity = [];
                    for (const t of this.partyType) {
                        if (ctrType === 1 && t !== '1') {
                            activity.push(this.ds.static.activityTypeKeys[t]);
                        }
                    }
                    this.partyTypeName = this.ds.static.contractTypeValue[ctrType];
                    this.dsContract = this.ds.getContractView(this.ds.static.contractTypeKeys[ctrType]);
                } else {
                    this.partyTypeName = this.ds.static.contractTypeValue[this.partyType];
                    this.dsContract = this.ds.getContractView(this.ds.static.contractTypeKeys[this.partyType]);
                }
            } else {
                this.partyTypeName = 'Partener';
                this.dsContractType = this.ds.getEnumValue('Contract');
                this.dsContract = this.ds.getContractView(null);
            }
        });
    }

    ngOnInit() {
        this.dsBoolean = this.ds.boolean('bool');
    }

    mainToolbarPreparing(ev: any) {
        this.ui.prepareToolbar(ev, true);

        ev.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: this.ds.lg.format('ADD_ROW', null),
                icon: 'plus',
                onClick: (e: any) => {
                    this.edit.role = this.partyType ? null : this.ds.static.activityTypeKeys[this.partyType];
                    this.edit.showPopup(null).then(
                        () => this.gridContractType.instance.refresh(),
                        () => { });
                }
            },
            location: 'after'
        });
    }

    onAnnexEditingStart(ev: any) {
        ev.data.date = new Date();
    }

    onRowInserting(ev: any, data: any) {
        ev.data.contractId = data.c_id;
    }

    subMenuToolbarPreparing(ev: any) {
        this.ui.prepareToolbar(ev, true);
        ev.toolbarOptions.items.unshift({
            text: this.ds.lg.format('WORKSTATIONS', null),
            location: 'before'
        });
    }

    onEditContractTypeClickEv(e: any) {
        e.event.preventDefault();
        e.row.data.role = this.partyType ? null : this.ds.static.activityTypeKeys[this.partyType];
        this.edit.showPopup(e.row.data, true).then(
            () => this.gridContractType.instance.refresh(),
            (error) => this.ds.serverError(error)
        );
    }
    onWorkCenterReady(e: any, contract: any) {
        if (e.component.option('dataSource') == null) {
            e.component.option('dataSource', this.ds.getContractParty(contract.c_id));
        }
    }

    onAnnexReady(e: any, contract: any) {
        if (e.component.option('dataSource') == null) {
            e.component.option('dataSource', this.ds.getContractAnnex(contract.c_id));
        }
    }

    onAnnexRemoving(e: any) {
        this.ds.getFileInfoSingle(e.key).then(data => {
            if (data && data.id) {
                this.ds.removeFile(data.id);
            }
        });
    }
    onFileBtnVisibleEv(e: any) {
        return e.row.data.id ? true : false;
    }

    onDownloadClickEv(ev: any) {
        ev.event.preventDefault();
        this.ds.getFileInfoSingle(ev.row.data.id).then(data => {
            if (data && data.id) {
                this.file.Download(data.id).then(
                    () => { notify(this.ds.lg.format('FILES_DOWNLOADED', null), 'success', 2000); },
                    () => { notify(this.ds.lg.format('DOWNLOAD_ERROR', null), 'error', 3000); });
            } else {
                notify('Fisier inexistent', 'error', 3000);
            }
        });
    }

    onUploadClickEv(ev: any) {
        ev.event.preventDefault();
        this.createUploadEvent(ev.row.data, this.gridContractAnnex);
    }

    createUploadEvent(entity: any, gridComponent) {
        this.file.Upload(entity.id).then(
            () => {                 
                gridComponent.instance.refresh();
                notify(this.ds.lg.format('FILES_UPLOADED', null), 'success', 2000); 
            },
            () => { notify(this.ds.lg.format('UPLOAD_ERROR', null), 'error', 3000); }
        );
    }
}
