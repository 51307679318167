import { Component, OnInit, ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { DxDataGridComponent } from 'devextreme-angular';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';
import { EditNomenclatureService } from './edit-nomenclature.service';

@Component({
  selector: 'app-nomenclature',
  templateUrl: './nomenclature.component.html'
})
export class NomenclatureComponent implements OnInit {

  dsNomenclatureTypes: CustomStore;
  dsNomenclatureValues: CustomStore;
  actualNomenId: string;
  onDeleteNomenclaturesClick: any;
  onEditNomenclaturesClick: any;

  @ViewChild('gridNomenclatureType') gridNomenclatureType: DxDataGridComponent;
  @ViewChild('gridNomenclatureVal') gridNomenclatureVal: DxDataGridComponent;

  constructor(public ds: DataService, public ui: UIService, public edit: EditNomenclatureService) {
    this.onEditNomenclaturesClick = this.onEditNomenclatures.bind(this);
    this.onDeleteNomenclaturesClick = this.onDeleteNomenclatures.bind(this);
  }

  ngOnInit() {
    this.dsNomenclatureTypes = this.ds.nomenclaturesType();
  }

  onEditNomenclatures(e: any) {
    e.event.preventDefault();
    this.edit.showPopup(e.row.data.id, true).then(() => {
      this.gridNomenclatureType.instance.refresh();
    }, err => {
      this.ds.serverError(err);
    });
  }

  onRowExpandingEv(event: any) {
    event.component.collapseAll(-1);
    this.actualNomenId = event.key;
    this.dsNomenclatureValues = this.ds.nomenclaturesVal(event.key);
  }

  onDeleteNomenclatures(e: any) {
    e.event.preventDefault();
    this.edit.remove(e.row.data.id).then(() => {
      notify('Succes', 'success', 2000);
      this.gridNomenclatureType.instance.refresh();
    }, err => {
      this.ds.serverError(err);
    });
  }

  onRowInsertingEv(event: any) {
    event.data.typeId = this.actualNomenId;
  }

  toolbarPreparing(event: any, details?: any) {
    if (details) {
      this.ui.prepareToolbar(event, false);
      if (this.ds.isSuperAdmin) {
      event.toolbarOptions.items.unshift({
        widget: 'dxButton',
        options: {
          hint: this.ds.lg.format('ADD', null),
          icon: 'assets/img/grid_add.svg',
          onClick: (e: any) => {
            this.gridNomenclatureVal.instance.addRow();
          }
        },
        location: 'after'
      });
      }
    } else {
      this.ui.prepareToolbar(event, true);

      if (this.ds.isSuperAdmin) {
      event.toolbarOptions.items.unshift({
        widget: 'dxButton',
        options: {
          hint: this.ds.lg.format('ADD', null),
          icon: 'assets/img/grid_add.svg',
          onClick: (e: any) => {
            this.edit.showPopup(null).then(() => {
              this.gridNomenclatureType.instance.refresh();
            }, err => {
              this.ds.serverError(err);
            });
          }
        },
        location: 'after'
      });
      }
    }
  }
}
