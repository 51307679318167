import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { UIService } from '../../../service/ui.service';
import { EditTransportService } from './editTransport.service';
import { EntityStoreOptions } from '@dohu/ibis-entity';
import { HttpClient } from '@angular/common/http';
import { FinishTransportService } from './finishTransport/finishTransport.service';
import { ActivatedRoute, Router } from '@angular/router';
import { iBisFileService } from '@dohu/ibis-common';

@Component({
    templateUrl: 'transport.component.html',
})
export class TransportComponent implements OnInit {
    dsTransportEntry: CustomStore;
    dsTransportFinish: CustomStore;

    @ViewChild('gridEntryTransport') gridEntryTransport: DxDataGridComponent;
    @ViewChild('gridFinishTransport') gridFinishTransport: DxDataGridComponent;
    @ViewChild('filesGrid') filesGrid: DxDataGridComponent;
    onFinishTransportClick: any;
    onEditTransportClick: any;
    onDeleteTransportClick: any;
    onEditTransFinishClick: any;
    onDeleteTransFinishClick: any;
    onNotesClick: any;
    wasteType: any;
    dsTransportType: CustomStore;
    onCustomizeColumnsEvent: any;

    constructor(public ds: DataService, public ui: UIService, public edit: EditTransportService, public finish: FinishTransportService,
        public file: iBisFileService, public http: HttpClient, public activeRoute: ActivatedRoute, public router: Router) {

        this.onEditTransportClick = (e: any) => this.onEdit(this, e, false, true);
        this.onFinishTransportClick = (e: any) => this.onEdit(this, e, true, true);
        this.onEditTransFinishClick = (e: any) => this.onEdit(this, e, true, false);

        this.onDeleteTransFinishClick = (e: any) => this.onDelete(this, e, true);
        this.onDeleteTransportClick = (e: any) => this.onDelete(this, e, false);
        this.onNotesClick = (e: any) => this.onNotesClickEv(e);

        this.activeRoute.queryParams.subscribe(params => {
            const displayKey = params['display'];
            this.wasteType = params['wasteType'];
            const customObj = this.ui.display[displayKey];
            if (displayKey && customObj) {
                this.onCustomizeColumnsEvent = (c) => { this.ui.onCustomizeColumns(c, displayKey); };
            }
            this.dsTransportEntry = this.ds.transportEntry(this.wasteType);
            this.dsTransportFinish = this.ds.getTransportFinish(null, null, this.wasteType);
        });
    }

    ngOnInit() {
        this.dsTransportType = this.ds.getEnumValue('Transport');
    }


    onRaportTransport(e: any, type: number) {
        if (e.row.data.t_ladingBill == null) {
            notify('Adaugati numarul avizului de transport', 'info', 6000);
        } else {
            const url = EntityStoreOptions.DefaultServer + 'DownloadAnex' + (type === 2 ? 2 : '') + '/' + e.row.data.t_id;
            this.file.DownloadByUrl(url);
        }
    }

    onNotesClickEv(e: any) {
        if (e.row.data.t_ladingBill == null) {
            notify('Adaugati numarul avizului de transport', 'info', 6000);
        } else {
            const url = EntityStoreOptions.DefaultServer + 'DownloadNotes/' + e.row.data.t_id;
            this.file.DownloadByUrl(url);
        }
    }

    onEdit(that: any, e: any, isFinish: boolean, refreshEntry: boolean) {
        e.event.preventDefault();
        if (isFinish) {
            that.finish.showPopup(e.row.data.t_id, true).then(() => {
                (refreshEntry ? that.gridEntryTransport : that.gridFinishTransport).instance.refresh();
                that.gridFinishTransport.instance.refresh();
            });
        } else {
            that.edit.wasteType = this.wasteType;
            that.edit.showPopup(e.row.data.t_id, true).then(() => {
                (refreshEntry ? that.gridEntryTransport : that.gridFinishTransport).instance.refresh();
            });
        }
    }

    calculateEEEQuantity(rowData) {
        return rowData.t_quantity * rowData.t_unitWeight;
    }

    onDelete(that: any, e: any, isFinish: boolean) {
        e.event.preventDefault();
        that.edit.remove(e.row.data.t_id).then(() => {
            notify('Success', 'success', 2000);
            (isFinish ? that.gridFinishTransport : that.gridEntryTransport).instance.refresh();
        }, err => {
            that.ds.serverError(err);
        });
    }

    // for file uploading
    toolbarPreparing(event: any, type: string, data?: any) {
        this.ui.prepareToolbar(event, true);
        if (type === 'add') {
            event.toolbarOptions.items.unshift({
                widget: 'dxButton',
                options: {
                    icon: 'assets/img/grid_add.svg', hint: this.ds.lg.format('ADD_ROW', null),
                    onClick: () => {
                        this.edit.wasteType = this.wasteType;
                        this.edit.showPopup(null).then(() => event.component.refresh());
                    }
                },
                location: 'after'
            });
        }
    }

    onFileUploaderReady(e: any, data: any) {
        const buttonInstance = e.component._selectButton;
        buttonInstance.option('icon', 'assets/img/grid_add.svg');
        buttonInstance.option('width', '130');
        buttonInstance.option('height', '26');
    }
}
