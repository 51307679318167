import { Component, OnInit } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import notify from 'devextreme/ui/notify';
import { alert } from 'devextreme/ui/dialog';
import { EntityStoreOptions, EntityStore } from '@dohu/ibis-entity';
import { HttpClient } from '@angular/common/http';
import { iBisLanguageService } from '@dohu/ibis-common';
import * as st from '../../../../assets/static.json';
import { environment } from '../../../../environments/environment';
import { DataService } from '../../../service/data.service';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
    licenseItem: any = [];
    staticFile: any;
    constructor(public ds: DataService, public auth: iBisAuthService, http: HttpClient, public lg: iBisLanguageService) { }

    ngOnInit() {
        this.staticFile = st;
        this.ds.getLicenseItem().load().then((res: any) => {
            res.data.forEach((licenseItem: any) => {
                this.ds.getLicenseItemExtra().load().then((r: any) => {
                    licenseItem.extra = [];
                    r.data.forEach((extraItem: any) => {
                        switch (extraItem.typeId) {
                            case 1:
                                licenseItem.extra.push(this.ds.lg.format('WORKSTATION', null));
                                break;
                        }

                    });
                    this.licenseItem = res.data;
                });
                this.staticFile.default.subscriptionData.forEach((element: any) => {
                    if (element.id === licenseItem.typeId) {
                        licenseItem.name = element.name;
                    }
                });
            });
            this.licenseItem = res.data;
        });

    }
}
