import { Component, OnInit } from '@angular/core';
import { EditProjectService } from './editProject.service';
import { UIService } from '../../../service/ui.service';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-edit-project',
  templateUrl: './editProject.component.html'
})
export class EditProjectComponent implements OnInit {
  dsParty: DataSource;
  dsPartyClient: DataSource;
  dsBoolean: { id: any; name: any; }[];

  constructor(public ui: UIService, public ds: DataService, public edit: EditProjectService) { }

  ngOnInit() {
    this.dsParty = this.ds.getPaginateParty('ent');
    this.dsPartyClient = this.ds.getPaginateParty('cs');
    this.dsBoolean = this.ds.boolean('bool');
  }

  dateComparison = () => {
    return new Date(this.edit.model.startDate);
  }
}
