import { Component, OnInit, ViewChild } from '@angular/core';
import { UIService } from '../../../service/ui.service';
import { DataService } from '../../../service/data.service';
import { EditCollectService } from './editCollect.service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { DxFormComponent } from 'devextreme-angular';

@Component({
    selector: 'app-edit-collect',
    templateUrl: './editCollect.component.html'
})
export class EditCollectComponent implements OnInit {

    dsWorkCenter: CustomStore;
    dsWasteType: DataSource;
    dsTransportApproval: any;
    dsTransporter: CustomStore;
    dsCar: CustomStore;
    dsDriver: CustomStore;
    dsEntities: DataSource;
    dsPartyWorkCenter: CustomStore;
    dsElimination: CustomStore;
    dsCapitalization: CustomStore;
    dsPartyGenerator: DataSource;
    onEntityChange: any;
    onWorkCenterChange: any;
    onWasteTypeChange: any;
    onGeneratorChange: any;
    onTransporterChange: any;
    formInstance: any;
    lastGenId: string; lastTransId: string; lastWCId: string;
    noFatQtyCollected: string;

    @ViewChild('collectForm', {static: false}) collectForm: DxFormComponent;

    constructor(public ui: UIService, public ds: DataService, public edit: EditCollectService) {
        // this.dsEntities = this.ds.getParty('ent');
        this.dsWorkCenter = this.ds.getParty('pc');
        // this.dsPartyGenerator = this.ds.validClients();
        this.dsPartyGenerator = this.ds.getPartyLinkViewPaginate([4], [0]);
        this.dsTransporter = this.ds.validTransports();
        this.dsElimination = this.ds.getEnumValue('Eliminare');
        this.dsCapitalization = this.ds.getEnumValue('Valorificare');
        this.dsEntities = this.ds.getPartyLinkViewPaginate([2]);

        this.onEntityChange = this.onEntityChangeEv.bind(this);
        this.onWorkCenterChange = this.onWorkCenterChangeEv.bind(this);
        this.onWasteTypeChange = this.onWasteTypeChangeEv.bind(this);
        this.onGeneratorChange = this.onGeneratorChangeEv.bind(this);
        this.onTransporterChange = this.onTransporterChangeEv.bind(this);
        // TODO: La schimbarea soferului sau a masinii de modificat FAT-ul(dsFAT)
    }

    ngOnInit() {
        this.initComponent();
    }

    onFormInit(event) {
        this.formInstance = event.component;
    }

    initComponent() {
        if (this.edit.model.entityId && this.edit.model.entityId.length) {
            this.dsWorkCenter = this.ds.getPartyMemberById(this.edit.model.entityId);
        }

        if (this.edit.model.workCenterId && this.edit.model.workCenterId.length) {
            this.lastWCId = this.edit.model.workCenterId;
            this.dsWasteType = this.ds.setWasteCodeDataSourceForWorkCenter(this.edit.model.workCenterId);
        }

        if (this.edit.model.generatorId && this.edit.model.generatorId.length) {
            this.lastGenId = this.edit.model.generatorId;
            this.dsPartyWorkCenter = this.ds.getWorkCenterByParent(this.edit.model.generatorId, 4);
            if (this.edit.model.wasteTypeId) {
                this.prepareFATDataSource(this.edit.model.partyWorkCenterId, this.edit.model.workCenterId, this.edit.model.transporterId,
                    this.edit.model.wasteTypeId);
            }
        }

        if (this.edit.model.transporterId && this.edit.model.transporterId.length) {
            this.lastTransId = this.edit.model.transporterId;
            this.dsCar = this.ds.getCarByTransporterId(this.edit.model.transporterId);
            this.dsDriver = this.ds.getDriverByTransporterId(this.edit.model.transporterId);
        }
    }

    onWorkCenterChangeEv(event: any) {
        if (event.value) {
            if (this.lastWCId !== event.value) {
                this.lastWCId = event.value;
                this.edit.model.wasteTypeId = null;
                this.dsWasteType = this.ds.setWasteCodeDataSourceForWorkCenter(event.value);
            }
        }
    }

    onEntityChangeEv(event: any) {
        if (event.value) {
            this.dsWorkCenter = this.ds.getPartyMemberById(event.value);
            this.prepareFATDataSource(this.edit.model.partyWorkCenterId, event.value, this.edit.model.transporterId,
                this.edit.model.wasteTypeId);
        }
    }

    onWasteTypeChangeEv(event: any) {
        if (event.value) {
            this.setWasteTypeDetails(event.value);
            this.prepareFATDataSource(this.edit.model.partyWorkCenterId, this.edit.model.workCenterId, this.edit.model.transporterId,
                event.value);

            // if (this.edit.model.generatorId) {
            //     this.ds.checkQuantityForGenerator(this.edit.model.generatorId, event.value).then(resolve => {
            //         this.noFatQtyCollected = resolve[0].f1;
            //     });
            // }
        }
    }

    customCollectingApprovalDisplay = (rowInfo: any) => {
        if (rowInfo) {
            const str = 'Nr.: ' + rowInfo.ta_no
                + ' - Cant.: ' + (rowInfo.c_quantity ? (rowInfo.ta_approvedQuantity - rowInfo.c_quantity) : rowInfo.ta_approvedQuantity)
                + ' - Nr.tr.: ' + (rowInfo.c_no ? (rowInfo.ta_approvedNo - rowInfo.c_no) : rowInfo.ta_approvedNo);
            return this.edit.model.id ? ('Nr.: ' + rowInfo.ta_no) : str;
        }
        return '';
    }

    onGeneratorChangeEv(event: any) {
        if (event.value) {
            if (this.lastGenId !== event.value) {
                this.lastGenId = event.value;
                // this.edit.model.partyWorkCenterId = null;
                // this.dsPartyWorkCenter = this.ds.getWorkCenterByParent(event.value, 4);
                this.prepareFATDataSource(event.value, this.edit.model.workCenterId, this.edit.model.transporterId,
                    this.edit.model.wasteTypeId);

                if (this.edit.model.wasteTypeId) {
                    this.ds.checkQuantityForGenerator(event.value, this.edit.model.wasteTypeId).then(resolve => {
                        this.noFatQtyCollected = resolve[0].f1;
                    });
                }
            }
        }
    }

    // tslint:disable-next-line: max-line-length
    prepareFATDataSource(generatorId: string, destinationId: string, transporterId: string, wasteTypeId: string) {
        this.ds.getTransportApprovalView(generatorId, destinationId, transporterId, wasteTypeId).load().then(data => {
            const ds = data.data;
            if (this.edit.model.id) {
                this.dsTransportApproval = ds;
            } else {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                for (let i = 0; i < ds.length; i++) {
                    const expireDate = new Date(ds[i].ta_expireDate);
                    if ((ds[i].c_no && (ds[i].c_no <= 0 || ds[i].c_no >= ds[i].ta_approvedNo)) ||
                        (ds[i].c_quantity && (ds[i].c_quantity <= 0 || ds[i].c_quantity >= ds[i].ta_approvedQuantity)) ||
                        (expireDate < today)) {
                        ds.splice(i, 1);
                        i--;
                    }
                }
                this.dsTransportApproval = ds;
            }
        });
    }

    onTransporterChangeEv(event: any) {
        if (event.value) {
            if (this.lastTransId !== event.value) {
                this.lastTransId = event.value;
                this.edit.model.carId = null;
                this.edit.model.driverId = null;

                this.dsCar = this.ds.getCarByTransporterId(event.value);
                this.dsDriver = this.ds.getDriverByTransporterId(event.value);
            }
            if (this.edit.model.carId == null) {
                this.dsCar.load().then(data => {
                    if (data && data.data && data.data.length > 0) {
                        this.edit.model.carId = data.data[0].id;
                    }
                });
            }
            if (this.edit.model.driverId == null) {
                this.dsDriver.load().then(data => {
                    if (data && data.data && data.data.length > 0) {
                        this.edit.model.driverId = data.data[0].id;
                    }
                });
            }
            this.prepareFATDataSource(this.edit.model.partyWorkCenterId, this.edit.model.workCenterId, event.value,
                this.edit.model.wasteTypeId);
        }
    }

    onCollectPopupShow(event: any) {
        this.initComponent();
    }

    setWasteTypeDetails(id: string) {
        this.ds.getWasteTypeById(id).then(dr => {
            if (dr) {
                this.edit.model.hazard = dr.hazard;
                this.edit.model.hazardName = DataService.getHarzardName(dr.hazard);
                this.edit.model.wasteName = dr.name;
                this.edit.model.uom = DataService.getUOM(dr.uom);
                this.edit.model.physicalCondition = dr.physicalCondition;
                this.edit.model.wasteCode = dr.code;
                setTimeout(() => {
                    this.collectForm.instance.getEditor('quantity').focus();
                }, 0);
            }
        });
    }

    vFatValidationCallback = (options) => {
        if (this.edit.model.hazard === 1 && this.edit.model.quantity >= 1000 && !this.edit.model.approvalId) {
            return false;
        } else if ((parseInt(this.noFatQtyCollected, 10) + this.edit.model.quantity) >= 1000 &&
            this.edit.model.hazard === 1 && !this.edit.model.approvalId) {
            return false;
        } else {
            return true;
        }
    }
    onFatChanged = (ev: any) => {
        if (ev.selectedItem) {
            this.edit.model.fatQtyLeft = ev.selectedItem.c_quantity ? (ev.selectedItem.ta_approvedQuantity - ev.selectedItem.c_quantity)
                : ev.selectedItem.ta_approvedQuantity;
        }
    }

    vCodeValidationCallback = (options) => {
        const elimination = this.formInstance.getEditor('eliminationId').option('value');
        const capital = this.formInstance.getEditor('capitalizationId').option('value');
        return (elimination || capital) ? true : false;
    }

    vQuantityValidationCallback = (options) => {
        const q = this.formInstance.getEditor('quantity').option('value');
        if (q <= 0 || (!this.edit.model.id && this.edit.model.fatQtyLeft < this.edit.model.quantity)) {
            return false;
        } else {
            return true;
        }
    }
}
