import { Injectable } from '@angular/core';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';

@Injectable({
  providedIn: 'root'
})
export class EditGeographicareaService extends iBisEditService {

  constructor(public ds: DataService) {
    super(ds.lg);
    this.validation = 'GeographicareaValidation';
  }
  createDefault() {
    return {};
  }
  getById(id: string, serverUrl?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      EntityStore.single(new EntityQuery('GeographicArea').eq('id', id)).then(result => {
        if (!result) {
          reject();
        }
        resolve(result);
      }, err => {
        this.ds.serverError(err);
      });
    });
  }

  onRemove(id: string): Promise<void> {
    return EntityStore.fromQuery(new EntityQuery('GeographicArea')).remove(id);
  }
  onSaveEv(serverUrl?: string): Promise<any> {
    const gaQ = EntityStore.fromQuery(new EntityQuery('GeographiArea'));
    return (this.model.id ? gaQ.update(this.model.id, this.model) : gaQ.insert(this.model));
  }
  reset(): void {
    this.model = this.createDefault();
  }

}
