import { Component, OnInit } from '@angular/core';
import { UIService } from '../../../service/ui.service';
import { DataService } from '../../../service/data.service';
import { EditTransportApprovalService } from './editTransportApproval.service';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-edit-transport-approval',
  templateUrl: './editTransportApproval.component.html'
})
export class EditTransportApprovalComponent implements OnInit {

  dsWasteType: DataSource;
  dsEntity: DataSource;
  dsDestination: DataSource;
  dsTransporter: DataSource;
  lastTransId: string;
  onTransporterChange: any;

  constructor(public ui: UIService, public ds: DataService, public edit: EditTransportApprovalService) {
  }

  ngOnInit() {
    this.dsEntity = this.ds.getPartyLinkViewPaginate([2, 4], [0, 1, 3, 4, 5]);
    this.dsTransporter = this.ds.getValidPaginateTransports();
    this.dsDestination = this.ds.getPartyLinkViewPaginate([2, 4], [0, 1, 3, 4, 5]);
    this.dsWasteType = this.ds.setWasteCodeDataSource(null, 1);
    this.onTransporterChange = this.onTransporterChangeEv.bind(this);
  }

  onShownTransportApp(ev: any) {
  }

  onStartChange = (ev: any) => {
    if (ev.value) {
      const time = new Date(ev.value).setFullYear(new Date(ev.value).getFullYear() + 2);
      this.edit.model.expireDate = new Date(time);

    }
  }

  endDateValidationCallback = options => {
    const startDate = new Date(this.edit.model.startDate);
    const thruDate = new Date(options.value);
    if (thruDate < startDate) { return false; }
    const maxDate = new Date(startDate.getFullYear() + 2, startDate.getMonth(), startDate.getDate());
    if (thruDate > maxDate) { return false; }
    return true;
  }

  minDateComparison = () => {
    const d = new Date();
    // const dd = d.getDate();
    return this.edit.model.startDate;
  }
  maxDateComparison = () => {
    const time = new Date(this.edit.model.startDate).setFullYear(new Date(this.edit.model.startDate).getFullYear() + 2);
    return new Date(time);
  }

  onTransporterChangeEv(event: any) {
    if (event.value) {
      if (this.lastTransId !== event.value) {
        this.lastTransId = event.value;
        this.edit.model.carId = null;
        this.edit.model.driverId = null;

        this.edit.dsCar = this.ds.getPaginateCarByTransporterId(event.value);
        this.edit.dsDriver = this.ds.getPaginateDriverByTransporterId(event.value);
      }
      if (this.edit.model.carId == null) {
        this.edit.dsCar.load().then(data => {
          if (data && data.length > 0) {
            this.edit.model.carId = data[0].id;
          }
        });
      }
      if (this.edit.model.driverId == null) {
        this.edit.dsDriver.load().then(data => {
          if (data && data.length > 0) {
            this.edit.model.driverId = data[0].id;
          }
        });
      }
    }
  }
}
