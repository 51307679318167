import { Component, Input } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'app-edit-archive',
    templateUrl: 'editArchive.component.html'
})
export class EditArchiveComponent {
    @Input() archiveData: any;
    dsReportType: any;
    pWithActiv: CustomStore;

    constructor(public ds: DataService) {
        this.pWithActiv = this.ds.getParty('wa');
        this.dsReportType = this.ds.getReportType();
    }
}
