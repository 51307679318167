import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'app-edit-monthly',
    templateUrl: 'editMonthly.component.html'
})
export class EditMonthlyComponent implements OnInit {
    @Input() monthlyData: any;
    dspEntities: CustomStore;
    dsWasteType: CustomStore;
    dspWorkCenter: CustomStore;
    dsMonths: any;

    onEntityChange: any;
    workCenterInst: any;
    wasteCodeInst: any;
    onWorkCenterInit: any;
    onWasteCodeInit: any;
    onWasteChange: any;
    onWorkCenterChange: any;
    year: { min: number; current: number; };

    constructor(public ds: DataService) {
        this.dspEntities = this.ds.getParty('ent');
        this.dsWasteType = this.ds.getWasteTypeForWorkCenter(null);
        this.dspWorkCenter = this.ds.getParty('wc');
        this.onWorkCenterInit = this.onWorkCenterInitEv.bind(this);
        this.onWasteCodeInit = this.onWasteCodeInitEv.bind(this);
        this.onEntityChange = this.onEntityChangeEv.bind(this);
        this.onWasteChange = this.onWasteChangeEv.bind(this);
        this.onWorkCenterChange = this.onWorkCenterChangeEv.bind(this);
        this.dsMonths = this.ds.static.months;
        this.year = { min: new Date().getFullYear() - 15, current: new Date().getFullYear() };
    }

    ngOnInit() { }

    onWasteCodeInitEv(event: any) {
        this.wasteCodeInst = event.component;
    }

    onWorkCenterInitEv(event: any) {
        this.workCenterInst = event.component;
    }

    onEntityChangeEv(event: any) {
        if (event.event) {
            this.ds.resetValidation(this.workCenterInst);
            this.ds.resetValidation(this.wasteCodeInst);
            this.setWasteInfo();
            this.dspWorkCenter = this.ds.getPartyMemberById(event.value);
        }
    }

    onWorkCenterChangeEv(event: any) {
        if (event.event) {
            this.ds.resetValidation(this.wasteCodeInst);
            this.setWasteInfo();
            this.dsWasteType = this.ds.getWasteTypeForWorkCenter(event.value);
        }
    }

    setWasteInfo(hazardName = null, wt_name = null) {
        this.monthlyData.hazardName = hazardName;
        this.monthlyData.wt_name = wt_name;
    }

    onWasteChangeEv(event: any) {
        if (event.value) {
            const items = this.wasteCodeInst.getDataSource().items();
            if (items.length) {
                const item = items.filter((x: any) => x.id === event.value);
                const hazard = DataService.getHarzardName(item[0].hazard);
                this.setWasteInfo(hazard, item[0].name);
            }
        }
    }
}
