import { Component, Input } from '@angular/core';
import { DataService } from '../../../service/data.service';
import { EditNomenclatureService } from './edit-nomenclature.service';


@Component({
    selector: 'app-edit-nomenclature',
    templateUrl: 'edit-nomenclature.component.html'
})

export class EditNomenclatureComponent {

    constructor(public ds: DataService, public edit: EditNomenclatureService) {
    }
}
