import { Component, Input } from '@angular/core';
import { DataService } from '../../../service/data.service';


@Component({
    selector: 'app-edit-action',
    template: `
    <dx-scroll-view height='100%'>
        <dx-form [colCount]="1" [formData]='actionForm' [showValidationSummary]="false" validationGroup="actionValidation">
            <dxi-item itemType="group" [colSpan]="1" [colCount]='2'>
                <dxi-item [label]="{text: ds.lg.format('MEASURE_DESCRIPTION')}" dataField="actionDescription" editorType="dxTextBox"
                    [editorOptions]="{ }">
                </dxi-item>
                <dxi-item [label]="{text: ds.lg.format('STATUS')}" dataField="actionStatus" editorType="dxSelectBox"
                    [editorOptions]="{dataSource: ds.static.actionStatus, displayExpr: 'value', valueExpr: 'id' }"></dxi-item>
            </dxi-item>
            <dxi-item itemType="group" [colSpan]="1" [colCount]='2'>
                <dxi-item [label]="{text: ds.lg.format('MEASURE_TERM')}" dataField="termAction" editorType="dxDateBox"
                    [editorOptions]="{ displayFormat: 'dd.MM.yyyy', width: '100%'}"></dxi-item>
                <dxi-item [label]="{text: ds.lg.format('OBSERVATIONS')}" dataField="notifications" editorType="dxTextBox"
                    [editorOptions]="{ }">
                </dxi-item>
            </dxi-item>
        </dx-form>
    </dx-scroll-view>`
})
export class EditActionComponent {
    @Input() actionForm: any;
    constructor(public ds: DataService) {
    }
}
