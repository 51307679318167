import { Component, OnInit, ViewChild } from '@angular/core';
import { EditCollectService } from './editCollect.service';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'app-collect',
    templateUrl: './collect.component.html'
})
export class CollectComponent {

    dsCollect: CustomStore;
    dsElimination: CustomStore;
    dsCapitalization: CustomStore;
    dsCar: CustomStore;
    dsDriver: CustomStore;
    onEditCollectClick: any;
    onDeleteCollectClick: any;

    @ViewChild('gridCollect') gridCollect: DxDataGridComponent;
    constructor(public ds: DataService, public ui: UIService, public edit: EditCollectService) {

        this.dsCollect = this.ds.getCollectingView();
        this.dsElimination = this.ds.getEnumValue('Eliminare');
        this.dsCapitalization = this.ds.getEnumValue('Valorificare');
        this.dsCar = this.ds.getCar();
        this.dsDriver = this.ds.getDriver();
        this.onDeleteCollectClick = this.onDeleteCollectClickEv.bind(this);
        this.onEditCollectClick = this.onEditCollectClickEv.bind(this);
    }

    mainToolbarPreparing(ev: any) {
        this.ui.prepareToolbar(ev, true);
        ev.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: this.ds.lg.format('ADD_COLLECT', null),
                icon: 'plus',
                onClick: (e: any) => this.edit.showPopup(null).then(
                    () => this.gridCollect.instance.refresh(), () => { })
            },
            location: 'after'
        });
    }
    onEditCollectClickEv(ev: any) {
        ev.event.preventDefault();

        this.edit.showPopup(ev.row.data, true).then(
            () => this.gridCollect.instance.refresh(),
            (error) => this.ds.serverError(error)
        );
    }

    onDeleteCollectClickEv(ev: any) {
        ev.event.preventDefault();
        confirm('Ești sigur că vrei să ștergi această înregistrare ?', 'Confirmare').then(val => {
            if (val) {
                this.edit.onRemove(ev.row.data.c_id).then(() => {
                    notify('Success', 'success', 2000);
                    this.gridCollect.instance.refresh();
                }, err => {
                    this.ds.serverError(err);
                });
            }
        });
    }
}
