import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { DxDataGridComponent } from 'devextreme-angular';
import { UIService } from '../../../service/ui.service';
import { EditGeographicareaService } from './edit-geographicarea.service';

@Component({
    templateUrl: 'geographicarea.component.html'
})
export class GeographicareaComponent implements OnInit {
    dsGa: CustomStore;

    onEditGareasClick: any;
    onDeleteGareasClick: any;

    @ViewChild('gridGa') gridGa: DxDataGridComponent;

    constructor(public ds: DataService, public ui: UIService, public edit: EditGeographicareaService) {
        this.onEditGareasClick = this.onEditGareas.bind(this);
        this.onDeleteGareasClick = this.onDeleteGareas.bind(this);
    }

    ngOnInit() {
        this.dsGa = this.ds.geographicAreas(null);
    }

    onEditGareas(e: any) {
        e.event.preventDefault();
        this.edit.showPopup(e.row.data.id, true).then(() => {
            this.gridGa.instance.refresh();
        }, err => {
            this.ds.serverError(err);
        });
    }

    onDeleteGareas(e: any) {
        e.event.preventDefault();
        confirm(this.ds.lg.format('DELETE_RECORD', null), this.ds.lg.format('CONFIRM', null)).then(val => {
            if (val) {
                this.edit.onRemove(e.row.data.id).then(() => {
                    notify('Success', 'success', 2000);
                    this.gridGa.instance.refresh();
                }, err => {
                    this.ds.serverError(err);
                });
            }
        });
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
        if (this.ds.isSuperAdmin) {
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: this.ds.lg.format('ADD', null),
                icon: 'assets/img/grid_add.svg',
                onClick: (e: any) => {
                    this.edit.showPopup(null).then(() => {
                        this.gridGa.instance.refresh();
                    }, err => {
                        this.ds.serverError(err);
                    });
                }
            },
            location: 'after'
        });
        }
    }
}

