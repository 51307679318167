
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { UIService } from '../../../service/ui.service';
import { ProxyService } from '../../../service/proxy.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: 'generate.component.html',
})

export class GenerateComponent implements OnInit {
    dsGenerate: CustomStore;
    dsStorageType: CustomStore;
    dsElimination: CustomStore;
    dsCapitalization: CustomStore;

    onEditGenerateClick: any;
    onDeleteGenerateClick: any;
    onAddTransportClick: any;
    onTransferClick: any;
    onCustomizeColumnsEvent: any;

    @ViewChild('gridGenerate') gridGenerate: DxDataGridComponent;
    dsSource: any;
    hideTransportBtn: any;
    wasteType: any;
    dsMaterialType: any;

    constructor(public ds: DataService, public ui: UIService, public proxy: ProxyService,
        public activeRoute: ActivatedRoute) {
        this.onEditGenerateClick = this.onEditGenerate.bind(this);
        this.onDeleteGenerateClick = this.onDeleteGenerate.bind(this);
        this.onAddTransportClick = this.onAddTransport.bind(this);
        this.onTransferClick = this.onTransferWaste.bind(this);

        this.activeRoute.queryParams.subscribe(params => {
            const displayKey = params['display'];
            this.wasteType = params['wasteType'];
            const customObj = this.ui.display[displayKey];
            if (displayKey && customObj && customObj.hideTransportBtn) {
                this.hideTransportBtn = customObj.hideTransportBtn || false;
            }
            if (displayKey && customObj) {
                this.onCustomizeColumnsEvent = (c) => { this.ui.onCustomizeColumns(c, displayKey); };
            }
            this.dsGenerate = this.ds.getWasteGenerate(this.wasteType);
        });
    }

    ngOnInit() {
        this.dsSource = this.ds.static.source;
        this.dsMaterialType = this.ds.static.materialType;
        this.dsStorageType = this.ds.getEnumValue('Stocare');
    }

    onEditGenerate(e: any) {
        e.event.preventDefault();
        this.proxy.editGenerate.wasteType = this.wasteType;
        this.proxy.editGenerate.showPopup(e.row.data.g_id, true).then(
            () => this.gridGenerate.instance.refresh(),
            (error) => this.ds.serverError(error)
        );
    }

    onDeleteGenerate(e: any) {
        e.event.preventDefault();
        this.proxy.editGenerate.remove(e.row.data.g_id).then(() => {
            notify('Success', 'success', 2000);
            this.gridGenerate.instance.refresh();
        });
    }

    onAddTransport(event: any) {
        this.proxy.editGenerate.getViewById(event.row.data.g_id).then(response => {
            if (response) {
                const obj = this.proxy.editTransport.createDefault();
                obj.wc_parentId = response.wc_parentId;
                obj.workCenterId = response.g_workCenterId;
                obj.t_wasteTypeId = response.g_wasteTypeId;
                obj.t_quantity = response.g_quantity;
                obj.t_unitWeight = response.g_unitWeight;
                obj.t_materialId = response.g_materialId;
                obj.wt_uom = response.wt_uom;
                obj.uom = DataService.getUOM(response.wt_uom);
                obj.wt_hazard = DataService.getHarzardName(response.wt_hazard);
                obj.wt_name = response.wt_name;
                this.proxy.editTransport.wasteType = this.wasteType;
                this.proxy.editTransport.showPopup(obj).then(() => this.gridGenerate.instance.refresh());
            } else {
                this.gridGenerate.instance.refresh();
            }
        });
    }

    onTransferWaste(event: any) {
        this.proxy.editGenerate.getViewById(event.row.data.g_id).then((response) => {
            if (response) {
                const obj = this.proxy.editTransfer.createDefault();
                obj.disabledSource = true;
                obj.t_sourceId = response.g_id;
                obj.t_senderWorkCenterId = response.g_workCenterId,
                    obj.t_wasteTypeId = response.g_wasteTypeId,
                    obj.wt_uom = response.wt_uom,
                    obj.uom = DataService.getUOM(response.wt_uom);
                obj.wt_hazard = DataService.getHarzardName(response.wt_hazard),
                    obj.wt_name = response.wt_name,
                    obj.t_sendDate = new Date(),
                    this.proxy.editTransfer.showPopup(obj).then(() => this.gridGenerate.instance.refresh());
            } else {
                this.gridGenerate.instance.refresh();
            }
        });
    }

    calculateEEEQuantity(rowData) {
        return rowData.g_quantity * rowData.g_unitWeight;
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                icon: 'assets/img/grid_add.svg', hint: this.ds.lg.format('ADD_ROW', null),
                onClick: (e: any) => {
                    this.proxy.editGenerate.wasteType = this.wasteType;
                    this.proxy.editGenerate.showPopup(null).then(() => this.gridGenerate.instance.refresh(), () => { });
                }
            },
            location: 'after'
        });
    }
}
