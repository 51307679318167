export const environment = {
    production: false,
    defaultUrlServer: 'https://api-test.sistemis.ro/qhse/',
    deployId: '72453e51-5018-4b5f-b9b0-404a7e398850',
    saasServer: 'https://api-test.sistemis.ro/saas/',
    authServer: 'https://api-test.sistemis.ro/auth/',
    filePath: 'https://api-test.sistemis.ro/qhse_file/',
    termCond: 'https://www.evidentadeseuri.ro/termeni-si-conditii/',
    manualUrl: 'https://www.evidentadeseuri.ro/manual-de-utilizare/',
    allowRegister: false
  };

