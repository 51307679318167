import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { confirm } from 'devextreme/ui/dialog';
import { DxDataGridComponent, DxSankeyComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { UIService } from '../../../service/ui.service';

@Component({
    templateUrl: 'wastetype.component.html'
})
export class WastetypeComponent implements OnInit {
    dsWasteType: CustomStore;
    dsLocality: CustomStore;
    isWorkcenterVisible: boolean;
    WTobj: any;
    currentWc: any;
    isWasteTypeVisible: boolean;
    popupTitle: any;

    onEditWastetypeClick: any;
    onDeleteWastetypeClick: any;
    onEditWorkCenterClick: any;
    onDeleteWorkCenterClick: any;

    @ViewChild('detailsWasteType') detailsWasteType: DxDataGridComponent;
    @ViewChild('wasteType') wasteType: DxDataGridComponent;
    onSaveWorkcenter: any;
    onSaveWasteType: any;

    constructor(public ds: DataService, public ui: UIService) {

        this.onEditWastetypeClick = this.onEditWastetype.bind(this);
        this.onDeleteWastetypeClick = this.onDeleteWastetype.bind(this);
        this.onEditWorkCenterClick = this.onEditWorkCenter.bind(this);
        this.onDeleteWorkCenterClick = this.onDeleteWorkCenter.bind(this);
        this.onSaveWorkcenter = this.onSaveWorkCenterEv.bind(this);
        this.onSaveWasteType = this.onSaveWasteTypeEv.bind(this);
    }

    ngOnInit() {
        this.dsWasteType = this.ds.wasteTypes();
        this.dsLocality = this.ds.geographicAreas(1);
        this.isWorkcenterVisible = false;
        this.currentWc = new Workcenter();
        this.WTobj = new WasteTypeObj();
        this.isWasteTypeVisible = false;
    }

    onContentReady(event: any, data: any) {
        if (event.component.option('dataSource') == null) {
            event.component.option('dataSource', this.ds.getWasteTypePartyView(data.id, 'wtp.wasteTypeId'));
        }
    }

    onEditWastetype(e: any) {
        e.event.preventDefault();
        this.isWasteTypeVisible = true;
        this.popupTitle = this.ds.lg.format('WASTE_TYPE', null);
        this.WTobj = e.row.data;
    }

    onDeleteWastetype(e: any) {
        e.event.preventDefault();
        confirm(this.ds.lg.format('DELETE_RECORD', null), this.ds.lg.format('CONFIRM', null)).then(val => {
            if (val) {
                this.ds.removeWasteType(e.row.data.id).then(() => {
                    notify('Success', 'success', 2000);
                    this.wasteType.instance.refresh();
                }, err => {
                    this.ds.serverError(err);
                });
            }
        });
    }

    onSaveWasteTypeEv(event: any) {
        event.event.preventDefault();
        const validation = event.validationGroup.validate();
        if (!validation.isValid) {
            notify(this.ds.lg.format('ALL_REQ_FIELDS', null), 'error', 3000);
            return;
        }

        this.ds.addEditWastetype(this.WTobj, this.WTobj.id).then((result: any) => {
            this.whenDone(false);
        }, error => {
            this.ds.serverError(error);
        });
    }

    // master detail grid

    onEditWorkCenter(e: any) {
        e.event.preventDefault();
        this.isWorkcenterVisible = true;
        this.popupTitle = this.ds.lg.format('UNIT', null);
        this.ds.getPartyById(e.row.data.wtp_workCenterId).then((result) => {
            this.currentWc = result;
        }, (error) => {
            this.ds.serverError(error);
        });
    }

    onDeleteWorkCenter(e: any) {
        e.event.preventDefault();
        confirm(this.ds.lg.format('DELETE_RECORD', null), this.ds.lg.format('CONFIRM', null)).then(val => {
            if (val) {
                this.ds.removeParty(e.row.data.wtp_workCenterId).then(() => {
                    notify('Success', 'success', 2000);
                    this.detailsWasteType.instance.refresh();
                }, err => {
                    this.ds.serverError(err);
                });
            }
        });
    }

    onSaveWorkCenterEv(event: any) {
        event.event.preventDefault();
        const validation = event.validationGroup.validate();
        if (!validation.isValid) {
            notify(this.ds.lg.format('ALL_REQ_FIELDS', null), 'error', 3000);
            return;
        }
        this.currentWc.parentId === null ? this.currentWc.isSubcontractor = false : this.currentWc.isSubcontractor = true;
        this.ds.addEditParty(this.currentWc, this.currentWc.id).then((result: any) => {
            this.whenDone(true);
        }, error => {
            this.ds.serverError(error);
        });
    }

    whenDone(masterDetail?: any) {
        if (masterDetail) {
            this.isWorkcenterVisible = false;
            this.detailsWasteType.instance.refresh();
            this.currentWc = new Workcenter();
        } else {
            this.wasteType.instance.refresh();
            this.isWasteTypeVisible = false;
            this.WTobj = new WasteTypeObj();
        }
        notify('Success', 'success', 2000);
    }

    toolbarPreparing(event: any, type?: any) {
        this.ui.prepareToolbar(event, true);
        if (this.ds.isSuperAdmin) {
            if (type !== 'sec') {
                event.toolbarOptions.items.unshift({
                    widget: 'dxButton',
                    options: {
                        hint: this.ds.lg.format('ADD_WASTE_TYPE', null), icon: 'assets/img/grid_add.svg', onClick: (e: any) => {
                            this.WTobj = {};
                            this.isWasteTypeVisible = true;
                            this.popupTitle = this.ds.lg.format('WASTES_TYPE', null);
                        }
                    },
                    location: 'after'
                });
            } else {
                event.toolbarOptions.items.unshift({
                    widget: 'dxButton',
                    options: {
                        hint: this.ds.lg.format('ADD_WASTE_TYPE', null), icon: 'assets/img/grid_add.svg', onClick: (e: any) => {
                            this.currentWc = {};
                            this.popupTitle = this.ds.lg.format('UNIT', null);
                            this.isWorkcenterVisible = true;
                        }
                    },
                    location: 'after'
                });
            }
        }
    }
}

class Workcenter {
    EntityID: string;
    id: string;
    name: string;
    cif: string;
    typeId: number;
    localityId: string;
    address: string;
    parentId: string;
    isActiv: boolean;
    wasteResponsible: string;
    environmentResponsible: string;
    isSubcontractor: boolean;
    constructor() {
        this.name = null;
        this.cif = null;
        this.typeId = null;
        this.localityId = null;
        this.address = null;
        this.parentId = null;
        this.isActiv = false;
        this.wasteResponsible = null;
        this.environmentResponsible = null;
        this.isSubcontractor = null;
    }
}

class WasteTypeObj {
    EntityID: string;
    id: string;
    name: string;
    code: string;
    group: string;
    hazard: number;
    physicalCondition: string;
    uom: number;
    constructor() {
        this.EntityID = null;
        this.id = null;
        this.name = null;
        this.code = null;
        this.group = null;
        this.hazard = null;
        this.physicalCondition = null;
        this.uom = null;
    }
}
