import { Component, OnInit } from '@angular/core';
import { UIService } from '../../../service/ui.service';
import { DataService } from '../../../service/data.service';
import { EditTransporterService } from './editTransporter.service';

@Component({
    selector: 'app-edit-transporter',
    templateUrl: './editTransporter.component.html'
})
export class EditTransporterComponent implements OnInit {
    constructor(public ui: UIService, public ds: DataService, public edit: EditTransporterService) { }
    ngOnInit() { }
}
