import { Injectable } from '@angular/core';
import { iBisEditService } from '@dohu/ibis-common';
  import { DataService } from '../../../service/data.service';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';

@Injectable({
  providedIn: 'root'
})
export class EditNomenclatureService extends iBisEditService {

  constructor(public ds: DataService) {
    super(ds.lg);
    this.validation = 'NomenclatureValidation';
  }

  createDefault() {
    return {};
  }
  getById(id: string, serverUrl?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      EntityStore.single(new EntityQuery('EnumType').eq('id', id)).then(result => {
        if (!result) {
          reject();
        }
        resolve(result);
      });
    });
  }

  onRemove(id: string): Promise<void> {
    return EntityStore.fromQuery(new EntityQuery('EnumType')).remove(id);
  }
  onSaveEv(serverUrl?: string): Promise<any> {
    const etQ = EntityStore.fromQuery(new EntityQuery('EnumType'));
    return (this.model.id ? etQ.update(this.model.id, this.model) : etQ.insert(this.model));
  }
  reset(): void {
    this.model = this.createDefault();
  }

}
