import { Injectable } from '@angular/core';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';

@Injectable({
    providedIn: 'root'
})
export class EditTreatmentService extends iBisEditService {
    dsTreatmentItem: any;
    dsTreatmentSource: any;
    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'treatmentValidate';
    }

    public showPopup(data: any, byId?: boolean): Promise<any> {
        this.dsTreatmentItem = [];
        this.dsTreatmentSource = [];
        return super.showPopup(data, byId);
    }

    createDefault() {
        this.dsTreatmentItem = [];
        this.dsTreatmentSource = [];
        if (this && this.ds) {
            return { date: new Date(), typeId: 3, entityId: this.ds.defaultParty.ent, workCenterId: this.ds.defaultParty.wc, totalQty: 0 };
        } else {
            return { date: new Date(), typeId: 3, totalQty: 0 };
        }
    }

    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            EntityStore.fromQuery(new EntityQuery('Treatment').eq('id', data.t_id)).single().then((obj) => {
                if (!obj) {
                    reject();
                } else {
                    obj.entityId = data.wc_parentId;
                    obj.uom = DataService.getUOM(data.wt_uom);
                    obj.wasteCode = data.wt_code;
                    obj.wasteName = data.wt_name;
                    obj.hazardName = DataService.getHarzardName(data.wt_hazard);
                    this.dsTreatmentItem = [];
                    this.dsTreatmentSource = [];
                    obj.totalQty = 0;
                    EntityStore.fromQuery(new EntityQuery('TreatmentItem').eq('treatmentId', data.t_id)).load().then((dr) => {
                        this.dsTreatmentItem = dr.data;
                        for (const q of dr.data) {
                            obj.totalQty += q.quantity;
                        }
                        resolve(obj);
                    });
                }
            });
        });
    }

    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Treatment')).remove(id);
    }

    onRemoveItem(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('TreatmentItem')).remove(id);
    }

    onSaveEv(): Promise<any> {
        this.dsTreatmentItem.forEach(item => {
            if (item.physicalCondition == undefined || item.physicalCondition == null) {
                item.physicalCondition = 0;
            }
        });
        this.model.items = this.dsTreatmentItem;
        this.model.sources = this.dsTreatmentSource;
        const s = EntityStore.fromQuery(new EntityQuery('Treatment'));
        return (this.model.id ? s.update(this.model.id, this.model) : s.insert(this.model));
    }

    reset(): void {
        this.model = this.createDefault();
    }
}
