import { Injectable } from '@angular/core';
import { iBisEditService } from '@dohu/ibis-common';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { DataService } from '../../../service/data.service';
import notify from 'devextreme/ui/notify';
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';

@Injectable({
    providedIn: 'root'
})
export class EditCollectService extends iBisEditService {

    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'collectingValidate';
    }

    createDefault() {
        const result = {
            isValid: true, date: new Date(), entityId: null, workCenterId: null,
            generatorId: null
        };
        return result;
    }

    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            EntityStore.fromQuery(new EntityQuery('CollectingView').eq('c_id', data.c_id)).single().then((obj) => {
                if (!obj) {
                    reject();
                } else {
                    const dr: any = {};
                    for (let j of Object.keys(obj)) {
                        if (j.startsWith('c_')) {
                            j = j.replace('c_', '');
                            dr[j] = obj['c_' + j];
                        }
                    }
                    dr.id = obj.c_id;
                    dr.entityId = obj.wc_parentId;
                    dr.generatorId = obj.pwc_parentId;
                    dr.transporterId = obj.tr_id;
                    dr.hazard = obj.wt_hazard;
                    dr.hazardName = DataService.getHarzardName(obj.wt_hazard);
                    dr.wasteName = obj.wt_name;
                    dr.uom = DataService.getUOM(obj.wt_uom);
                    resolve(dr);
                }
            });
        });
    }
    onRemove(id: string): Promise<void> {
        var q = new EntityQuery('EliminationSource').eq('collectionId', id);
        return new Promise<void>((resolve, reject) => {
            EntityStore.fromQuery(q).totalCount().then((count) => {
                if(count > 0) {
                    notify('Eroare: Aceasta colectare are o sursa de eliminare asignata!', 'error', 3000);
                } else{
                    EntityStore.fromQuery(new EntityQuery('Collecting')).remove(id).then(() => resolve(), error => reject());
                }
            });
        });
    }
    onSaveEv(): Promise<any> {
        const obj = this.model;
        const s = EntityStore.fromQuery(new EntityQuery('Collecting'));
        return new Promise<any>((resolve, reject) => {
            const q = new EntityQuery('Collecting').eq('fet', obj.fet.trim());
            if(this.model.id){
                q.neq('id', this.model.id);
            }
            EntityStore.fromQuery(q).single().then(result => {
                if(result){
                    notify('Acest F.E.T. / F.I.D. exista deja!', 'error', 3000);
                    reject('ERROR');
                } else{
                    if (this.model.id) {
                        s.update(this.model.id, obj).then(() => resolve('OK'), error => reject('ERROR'));
                    } else {
                        s.insert(obj).then((response) => {
                            if (response === 'ERROR') {
                                notify('Eroare la adaugare, asigurati-va ca toate datele sunt corecte!', 'error', 3000);
                                reject('ERROR');
                            } else {
                                resolve('OK');
                            }
                        }, error => {
                            notify('Eroare la adaugare, asigurati-va ca toate datele sunt corecte!', 'error', 3000);
                        });
                    }
                }
            });
        });
    }

    reset(): void {
        this.model = this.createDefault();
    }
}
