import { Injectable } from '@angular/core';
import { iBisGridConfigService, iBisLanguageService } from '@dohu/ibis-common';
import * as d from '../../assets/display.json';
import * as ui from '../../assets/uiCustomize.json';

@Injectable()
export class UIService {
    currentGridInst: any;
    tabsWidth: any;
    rowAlternationEnabled: boolean;
    showColumnLines: boolean;
    showRowLines: boolean;
    columnHidingEnabled: boolean;
    fullHeight: any;
    gridTabsHeight: any;
    scrollHeight: any;
    minWidthPopup: any;
    gridPopupHeight: string;
    showSearchPanel: any;
    showHeaderFilter: any;
    showGroupPanel: any;
    showFilterRow: any;
    columnChooserEnabled: any;
    exportEnabled: any;
    stateStoringEnabled: any;
    remoteOperationsSorting: any;
    remoteOperationsPaging: any;
    remoteOperationsFiltering: any;
    isWindows: boolean;
    scrollingMode: any;
    showScrollbar: any;
    showFilterPanel: any;

    constructor(public gridService: iBisGridConfigService, public lg: iBisLanguageService) {
        // isWindows este initializat in app.components.ts
        this.showRowLines = ui.default.showRowLines;
        this.showColumnLines = ui.default.showColumnLines;
        this.showSearchPanel = ui.default.showSearchPanel;
        this.showHeaderFilter = ui.default.showHeaderFilter;
        this.showGroupPanel = ui.default.showGroupPanel;
        this.showFilterRow = ui.default.showFilterRow;
        this.showFilterPanel = ui.default.showFilterPanel;
        this.columnChooserEnabled = ui.default.columnChooserEnabled;
        this.exportEnabled = ui.default.exportEnabled;
        this.stateStoringEnabled = ui.default.stateStoringEnabled;
        this.remoteOperationsSorting = ui.default.remoteOperationsSorting;
        this.remoteOperationsPaging = ui.default.remoteOperationsPaging;
        this.remoteOperationsFiltering = ui.default.remoteOperationsFiltering;
        this.rowAlternationEnabled = ui.default.rowAlternationEnabled;
        this.columnHidingEnabled = ui.default.columnHidingEnabled;
        this.fullHeight = ui.default.fullHeight;
        this.gridTabsHeight = ui.default.gridTabsHeight;
        this.gridPopupHeight = ui.default.gridPopupHeight;
        this.tabsWidth = ui.default.tabsWidth;
        this.scrollHeight = ui.default.scrollHeight;
        this.minWidthPopup = ui.default.minWidthPopup;
        this.scrollingMode = ui.default.scrollingMode;
        this.showScrollbar = ui.default.showScrollbar;
    }

    public get display() { return d.default; }

    onContentReady(e: any) {
        e.component.option('height', 'calc(100vh - 150px)');
    }

    onPopupShown(ev: any) {
        // const theme = localStorage.getItem('localTheme');
        let minusHeight: number;
        minusHeight = 20; // theme === 'material.light.compact' ? 20 : 40;
        this.scrollHeight = ev.component.content().clientHeight - minusHeight;
    }

    onPopupHidden(ev: any) {
        this.scrollHeight = '100%';
    }

    prepareToolbar(event: any, customStore: boolean = false) {
        const ss = event.component.option('stateStoring');
        if (customStore) {
            ss.type = 'custom';
            ss.customLoad = () => this.gridService.loadState(event.component, ss.storageKey);
            ss.customSave = (state: any) => this.gridService.saveState(ss.storageKey, state);
            event.component.option('stateStoring', ss);
        }
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: this.lg.format('RELOAD_DATA', null),
                icon: 'assets/img/grid_refresh.svg',
                onClick: (e: any) => event.component.refresh()
            },
            location: 'after'
        });
        if (customStore) {
            this.gridService.addButton(event, this);
        }
    }

    onCustomizeColumns(columns: any[], displayKey: any) {
        if (!displayKey || !this.display[displayKey]) {
            return;
        }
        const ds = this.display[displayKey];

        for (const c of columns) {
            if (ds.hasOwnProperty(c.dataField)) {
                c.visible = ds[c.dataField].visible;
            }
        }
    }
}
