import { Component, OnInit, ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';
import { UIService } from '../../../service/ui.service';
import { confirm } from 'devextreme/ui/dialog';
import { DataService } from '../../../service/data.service';

@Component({
    templateUrl: 'invoice.component.html'
})
export class InvoiceComponent implements OnInit {

    dsInvoices: CustomStore;
    dspContractors: CustomStore;
    dspEntities: CustomStore;

    dsReportType: any;
    invoices: any;
    isInvoicesPopupVisible: boolean;
    onEditInvoicesClick: any;
    onDeleteInvoicesClick: any;

    @ViewChild('filesGrid') filesGrid: DxDataGridComponent;
    @ViewChild('gridInvoices') gridInvoices: DxDataGridComponent;
    onSaveInvoice: any;

    constructor(public ds: DataService, public ui: UIService) {
        this.onEditInvoicesClick = this.onEditInvoices.bind(this);
        this.onDeleteInvoicesClick = this.onDeleteInvoices.bind(this);

        this.onSaveInvoice = this.onSaveInvoiceEv.bind(this);
    }

    ngOnInit() {
        this.dsInvoices = this.ds.invoices();
        this.dspContractors = this.ds.getParty('ct');
        this.dspEntities = this.ds.getParty('ent');
        this.isInvoicesPopupVisible = false;
        this.invoices = { isSales: true };
    }

    onFilesGridInit(e: any, data: any) {
        if (e.component.option('dataSource') == null) {
            e.component.option('dataSource', this.ds.getFileInfo(data.data.id));
        }
    }

    addNewRow(e: any) {
        this.isInvoicesPopupVisible = true;
        this.invoices = { isSales: true };
    }

    onSaveInvoiceEv(event: any) {
        event.event.preventDefault();
        const validation = event.validationGroup.validate();
        if (!validation.isValid) {
            notify(this.ds.lg.format("ALL_REQ_FIELDS", null), 'error', 3000);
            return;
        }
        const obj = {
            entityId: this.invoices.entityId,
            supplierId: this.invoices.supplierId,
            no: this.invoices.no,
            date: this.invoices.date,
            isSales: this.invoices.isSales,
            amount: this.invoices.amount,
            comments: this.invoices.comments,
        };

        this.ds.addEditInvoice(obj, this.invoices.id).then((result: any) => {
            this.whenDone();
        }, error => {
            this.ds.serverError(error);
        });
    }

    onEditInvoices(e: any) {
        e.event.preventDefault();
        this.isInvoicesPopupVisible = true;
        this.invoices = e.row.data;
    }

    onDeleteInvoices(e: any) {
        e.event.preventDefault();
        confirm(this.ds.lg.format("DELETE_RECORD", null), this.ds.lg.format("CONFIRM", null)).then(val => {
            if (val) {
                this.ds.removeInvoice(e.row.data.id).then(() => {
                    notify('Success', 'success', 2000);
                    this.gridInvoices.instance.refresh();
                }, err => {
                    this.ds.serverError(err);
                });
            }
        });
    }

    whenDone() {
        this.isInvoicesPopupVisible = false;
        this.invoices = {};
        notify('Success', 'success', 2000);
        this.gridInvoices.instance.refresh();
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                icon: 'assets/img/grid_add.svg', hint: this.ds.lg.format("ADD_ROW", null), onClick: (e: any) => {
                    this.addNewRow(null);
                }
            },
            location: 'after'
        });
    }
}

