import { Component, Input } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { EditPartyWorkCenterService } from './editPartyWorkCenter.service';

@Component({
    selector: 'app-edit-party-work-center',
    templateUrl: 'editPartyWorkCenter.component.html'
})
export class EditPartyWorkCenterComponent {
    onCountyChanged: any;
    onLocalityInit: any;
    localityComponent: any;

    dsLocality: any;
    dsCounty: CustomStore;

    constructor(public ds: DataService, public edit: EditPartyWorkCenterService) {
        this.dsCounty = this.ds.geographicAreas(2);

        setTimeout(() => {
            if (this.edit.model.countyId) {
                this.dsLocality = this.ds.getLocalitiesByCountyId(this.edit.model.countyId);
            } else {
                this.setLocalityDataSource();
            }
        }, 0);

        this.onCountyChanged = (data) => { this.onCountyChangedEv(data, this); };
        this.onLocalityInit = (data) => { this.onLocalityInitEv(data, this); };
    }

    onCountyChangedEv(event, that) {
        if (event.event) {
            that.ds.resetValidation(that.localityComponent.component);
        }
        if (event.value) {
            that.localityComponent.component.option('dataSource', that.ds.getLocalitiesByCountyId(event.value));
        } else if (event.value === null) {  // vine null doar la a doua deschidere de popup
            that.setLocalityDataSource();
        }
    }

    onLocalityInitEv(event, that) {
        that.localityComponent = event;
    }

    setLocalityDataSource() {
        this.dsLocality = new DataSource({
            store: this.ds.geographicAreas(1),
            paginate: true,
            pageSize: 50
        });
    }

    onFormReady(event: any) {
        event.component.resetValues();
    }
}
