import { Component, Input } from '@angular/core';
import { DataService } from '../../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { FinishTransportService } from './finishTransport.service';
import { UIService } from '../../../../service/ui.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-finish-transport',
    templateUrl: 'finishTransport.component.html'
})
export class FinishTransportComponent {

    dsTreatmentTypes: CustomStore;
    dsCapitalization: CustomStore;
    dsElimination: CustomStore;

    carTransport: string;

    formInstance: any;
    disableCapitalization: any;
    disableElimination: any;
    wasteType: any;

    constructor(public ds: DataService, public edit: FinishTransportService, public ui: UIService, public activeRoute: ActivatedRoute) {
        this.dsTreatmentTypes = this.ds.getEnumValue('Tratament');
        this.dsCapitalization = this.ds.getEnumValue('Valorificare');
        this.dsElimination = this.ds.getEnumValue('Eliminare');

        this.activeRoute.queryParams.subscribe(params => {
            this.wasteType = params['wasteType'];
        });

    }

    public get nrDate(): string {
        return this.edit ? this.edit.model.t_no + ' / ' + DataService.formatDate(this.edit.model.t_sendDate) : '';
    }

    public get entityWorkCenter(): string {
        return this.edit ? this.edit.model.ent_name + ' / ' + this.edit.model.wc_name : '';
    }

    public get carTransporter(): string {
        return this.edit ? this.edit.model.t_carNumber + ' / ' + this.edit.model.tr_name : '';
    }

    onFormInit(event) {
        this.formInstance = event.component;
    }

    checkValue(val) {
        if (val || val === 0) {
            return true;
        }
        return false;
    }

    initDisableCondition() {
        this.disableCapitalization = (this.edit.model.t_actions & 8) == 8 && this.edit.model.t_actions < 24;
        this.disableElimination = (this.edit.model.t_actions & 16) == 16 && this.edit.model.t_actions < 24;
    }

    setDisable(e) {
        if (e.dataField == 't_eliminatedQuantity') {
            this.initDisableCondition();
        }
    }

    eQuantityValidationCallback = (options) => {
        const vQuantity = this.formInstance.getEditor('t_valuedQuantity') ?
            this.formInstance.getEditor('t_valuedQuantity').option('value') : null;
        if (vQuantity === 0 && options.value === 0) { return false; }
        if (vQuantity === 0 || options.value === 0) { return true; }
        return vQuantity || options.value;
    }

    eCodeValidationCallback = (options) => {
        const eQuantity = this.formInstance.getEditor('t_eliminatedQuantity') ?
            this.formInstance.getEditor('t_eliminatedQuantity').option('value') : null;
        const vQuantity = this.formInstance.getEditor('t_valuedQuantity') ?
            this.formInstance.getEditor('t_valuedQuantity').option('value') : null;
        const condition = (this.checkValue(eQuantity) && !vQuantity) || (this.checkValue(eQuantity) && this.checkValue(vQuantity));
        if (condition) {
            if (eQuantity === 0) { return true; }
            return options.value;
        } else { return true; }
    }

    vQuantityValidationCallback = (options) => {
        switch (this.wasteType) {
            case '0':
                const eQuantity = this.formInstance.getEditor('t_eliminatedQuantity') ?
                    this.formInstance.getEditor('t_eliminatedQuantity').option('value') : null;
                if (eQuantity === 0 && options.value === 0) { return false; }
                if (eQuantity === 0 || options.value === 0) { return true; }
                return eQuantity || options.value;
            case '1':
                const expQuantity = this.formInstance.getEditor('t_exportedQuantity') ?
                    this.formInstance.getEditor('t_exportedQuantity').option('value') : null;
                const recQuantity = this.formInstance.getEditor('t_recycledQuantity') ?
                    this.formInstance.getEditor('t_recycledQuantity').option('value') : null;
                if (expQuantity === 0 && recQuantity === 0 && options.value === 0) { return false; }
                if (expQuantity > 0 || recQuantity > 0 || options.value > 0) { return true; }
                return expQuantity || recQuantity || options.value;
            case '2':
                const reuQuantity = this.formInstance.getEditor('t_reusedQuantity') ?
                    this.formInstance.getEditor('t_reusedQuantity').option('value') : null;
                const reccQuantity = this.formInstance.getEditor('t_recycledQuantity') ?
                    this.formInstance.getEditor('t_recycledQuantity').option('value') : null;
                if (reuQuantity === 0 && reccQuantity === 0 && options.value === 0) { return false; }
                if (reuQuantity > 0 || reccQuantity > 0 || options.value > 0) { return true; }
                return reuQuantity || reccQuantity || options.value;
        }
    }

    vCodeValidationCallback = (options) => {
        let vQuantity;
        let condition;
        switch (this.wasteType) {
            case '0':
                vQuantity = this.formInstance.getEditor('t_valuedQuantity') ?
                    this.formInstance.getEditor('t_valuedQuantity').option('value') : null;
                const eQuantity = this.formInstance.getEditor('t_eliminatedQuantity') ?
                    this.formInstance.getEditor('t_eliminatedQuantity').option('value') : null;
                condition = (this.checkValue(vQuantity) && !eQuantity) || (this.checkValue(eQuantity) && this.checkValue(vQuantity));
                if (condition) {
                    if (vQuantity === 0) { return true; }
                    return options.value;
                } else { return true; }
            case '1':
            case '2':
                vQuantity = this.formInstance.getEditor('t_valuedQuantity') ?
                    this.formInstance.getEditor('t_valuedQuantity').option('value') : null;
                const recQuantity = this.formInstance.getEditor('t_recycledQuantity') ?
                    this.formInstance.getEditor('t_recycledQuantity').option('value') : null;
                condition = (this.checkValue(vQuantity) && !recQuantity) || (this.checkValue(recQuantity) && this.checkValue(vQuantity));
                if (condition) {
                    if (vQuantity === 0) { return true; }
                    return options.value;
                } else { return true; }
        }

    }

    recQuantityValidationCallback = (options) => {
        let vQuantity: number;
        switch (this.wasteType) {
            case '1':
                vQuantity = this.formInstance.getEditor('t_valuedQuantity') ?
                    this.formInstance.getEditor('t_valuedQuantity').option('value') : null;
                const expQuantity = this.formInstance.getEditor('t_exportedQuantity') ?
                    this.formInstance.getEditor('t_exportedQuantity').option('value') : null;
                if (vQuantity === 0 && expQuantity === 0 && options.value === 0) { return false; }
                if (vQuantity > 0 || expQuantity > 0 || options.value > 0) { return true; }
                return vQuantity || expQuantity || options.value;
            case '2':
                vQuantity = this.formInstance.getEditor('t_valuedQuantity') ?
                    this.formInstance.getEditor('t_valuedQuantity').option('value') : null;
                const reuQuantity = this.formInstance.getEditor('t_reusedQuantity') ?
                    this.formInstance.getEditor('t_reusedQuantity').option('value') : null;
                if (vQuantity === 0 && reuQuantity === 0 && options.value === 0) { return false; }
                if (vQuantity > 0 || reuQuantity > 0 || options.value > 0) { return true; }
                return vQuantity || reuQuantity || options.value;
        }
    }

    recCodeValidationCallback = (options) => {
        const recQuantity = this.formInstance.getEditor('t_recycledQuantity') ?
            this.formInstance.getEditor('t_recycledQuantity').option('value') : null;
        const vQuantity = this.formInstance.getEditor('t_valuedQuantity') ?
            this.formInstance.getEditor('t_valuedQuantity').option('value') : null;
        const condition = (this.checkValue(recQuantity) && !vQuantity) || (this.checkValue(recQuantity) && this.checkValue(vQuantity));
        if (condition) {
            if (recQuantity === 0) { return true; }
            return options.value;
        } else { return true; }
    }

    expQuantityValidationCallback = options => {
        const vQuantity = this.formInstance.getEditor('t_valuedQuantity') ?
            this.formInstance.getEditor('t_valuedQuantity').option('value') : null;
        const recQuantity = this.formInstance.getEditor('t_recycledQuantity') ?
            this.formInstance.getEditor('t_recycledQuantity').option('value') : null;
        if (vQuantity === 0 && recQuantity === 0 && options.value === 0) { return false; }
        if (vQuantity > 0 || recQuantity > 0 || options.value > 0) { return true; }
        return vQuantity || recQuantity || options.value;
    }

    reuQuantityValidationCallback = options => {
        const vQuantity = this.formInstance.getEditor('t_valuedQuantity') ?
            this.formInstance.getEditor('t_valuedQuantity').option('value') : null;
        const recQuantity = this.formInstance.getEditor('t_recycledQuantity') ?
            this.formInstance.getEditor('t_recycledQuantity').option('value') : null;
        if (vQuantity === 0 && recQuantity === 0 && options.value === 0) { return false; }
        if (vQuantity > 0 || recQuantity > 0 || options.value > 0) { return true; }
        return vQuantity || recQuantity || options.value;
    }
}
