import { Component, OnInit, Input } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { EditTransferService } from './editTransfer.service';

@Component({
    selector: 'app-edit-transfer',
    templateUrl: './editTransfer.component.html'
})
export class EditTransferComponent implements OnInit {
    @Input() transferData: any;

    dspWorkCenter: CustomStore;
    dspExceptWc: CustomStore;
    dsWasteType: CustomStore;
    onWasteChange: any;
    onWasteCodeInit: any;
    wasteCodeInst: any;
    onWorkCenterSource: any;

    constructor(public ds: DataService, public edit: EditTransferService) {
        this.onWasteChange = this.onWasteChangeEv.bind(this);
        this.onWasteCodeInit = this.onWasteCodeInitEv.bind(this);
        this.onWorkCenterSource = this.onWorkCenterSourceEv.bind(this);
    }

    ngOnInit() {
        this.dspWorkCenter = this.ds.getParty('wc');
        if (this.edit.model.t_senderWorkCenterId) {
            this.dspExceptWc = this.ds.getParty('wc', true, this.edit.model.t_senderWorkCenterId);
        }
        this.dsWasteType = this.ds.getWasteTypeForWorkCenter(null, null, this.edit.wasteTypeId);
    }


    onWasteCodeInitEv(event: any) {
        this.wasteCodeInst = event.component;
    }

    onWasteChangeEv(event: any) {
        if (event.event && event.value) {
            this.setWasteTypeDetails(event.value);
        }
    }


    setWasteTypeDetails(id: string) {
        this.ds.getWasteTypeById(id).then(dr => {
            if (dr) {
                this.edit.model.wt_hazard = dr.hazard;
                this.edit.model.wt_hazardName = DataService.getHarzardName(dr.hazard);
                this.edit.model.wt_code = dr.code;
                this.edit.model.wt_name = dr.name;
                this.edit.model.uom = DataService.getUOM(dr.uom);
                this.edit.model.wt_physicalCondition = dr.physicalCondition;
                this.edit.model.t_physicalCondition = dr.physicalCondition;
            }
        });
    }

    onWorkCenterSourceEv(event: any) {
        if (event.event) {
            this.edit.model.t_destinationWorkCenterId = null;
        }
        if (event.value) {
            this.dspExceptWc = this.ds.getParty('wc', true, event.value);
            this.dsWasteType = this.ds.getWasteTypeForWorkCenter(event.value, null, this.edit.wasteTypeId);
        }
    }

}
