import { Component, Input } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'app-edit-invoice-item',
    templateUrl: 'editInvoiceItem.component.html'
})
export class EditInvoiceItemComponent {
    @Input() invoiceItemData: any;
    dsInvoices: CustomStore;

    constructor(public ds: DataService) {
        this.dsInvoices = this.ds.invoices();
    }
}
