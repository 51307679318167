import { Component, Input } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'app-edit-tax',
    templateUrl: 'editTax.component.html'
})
export class EditTaxComponent {
    @Input() taxData: any;

    dsTransportFinish: CustomStore;
    dspEntities: CustomStore;
    dspWorkCenter: CustomStore;
    dsBoolean: { id: any; name: any; }[];

    // onTransportNoChange: any;
    onEntityChanged: any;
    onWorkCenterChanged: any;
    dsAgency: CustomStore;

    constructor(public ds: DataService) {
        this.dsTransportFinish = this.ds.getTransportFinish();
        this.dspEntities = this.ds.getParty('ent');
        this.dspWorkCenter = this.ds.getParty('wc');
        this.dsBoolean = this.ds.boolean('bool');
        this.dsAgency = this.ds.getAgencies();

        // this.onTransportNoChange = this.onTransportNoChangeEv.bind(this);
        this.onEntityChanged = this.onEntityChangedEv.bind(this);
        this.onWorkCenterChanged = this.onWorkCenterChangedEv.bind(this);
    }


    // onTransportNoChangeEv(event: any) {
    //     if (event.event) {
    //         const items = event.component.getDataSource().items();
    //         const item = items.filter(x => x.t_id === event.value);
    //         this.workCenterInst.option('value', item[0].t_workCenterId);
    //         this.ds.getPartById(item[0].t_workCenterId).then((result) => {
    //             this.entityInst.option('value', result.parentId);
    //         }, (error) => {
    //             this.ds.serverError(error);
    //         });
    //     }
    // }

    onEntityChangedEv(event: any) {
        if (event.event) {
            this.dsTransportFinish = this.ds.getTransportFinish(null, event.value);
            this.taxData.t_transportId = null;
        }
    }

    onWorkCenterChangedEv(event: any) {
        if (event.event) {
            this.dsTransportFinish = this.ds.getTransportFinish(event.value);
            this.taxData.t_transportId = null;
        }
    }

    customTrDisplayExpr(info: any) {
        if (info) {
            return info.t_no + ' / ' + new Date(info.t_sendDate).getDate() + '.' +
                (new Date(info.t_sendDate).getMonth() + 1) + '.' +
                new Date(info.t_sendDate).getFullYear();
        }
        return '';
    }
}
