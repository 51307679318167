
import { Injectable } from '@angular/core';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';

@Injectable({
    providedIn: 'root'
})
export class EditActualStockService extends iBisEditService {
    dsTreatmentItem: any;
    dsStock: any;
    dsSource: any;
    selectedKeys: any = [];

    constructor(public ds: DataService) {
        super(ds.lg);
    }

    createDefault() {
        this.dsStock = [];
        return { totalQuantity: 0 };
    }

    getById(data: any): Promise<any> {
        this.selectedKeys = [];
        if (!data.edit) { // on inserting
            if (!data.wasteTypeId && data.dsSelected.length == 0) { // no data selected
                return this.getInsertWithNoElementsSelected(data);
            } else { // data selected
                return this.getInsertWithElementsSelected(data);
            }
        } else { // On update
            if (data.dsSelected.length == 0) { // no data selected
                if (data.eliminationId) {
                    return this.editIncinerationWithNoElementsSelected(data);
                } else if (data.fullTransportId) {
                    return this.editFullTransportWithNoElementsSelected(data);
                }
            } else { // with data selected
                if (data.eliminationId) {
                    return this.editIncinerationWithElementsSelected(data);
                } else if (data.fullTransportId) {
                    return this.editFullTransportWithElementsSelected(data);
                }
            }
        }
    }
    onRemove(id: string): Promise<void> {
        return new Promise((resolve) => { resolve(); });
    }
    onSaveEv(): Promise<any> {
        return new Promise((resolve) => {
            this.model.totalQuantity = Math.round(this.model.totalQuantity * Math.pow(10, 6)) / Math.pow(10, 6);
            this.model.dsSource = this.dsSource;
            this.model.isSaving = true;
            resolve();
        });
    }
    reset(): void {
        this.dsStock = [];
        this.model = this.createDefault();
    }

    getInsertWithNoElementsSelected(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (!data.wasteTypeId && data.dsSelected.length == 0) { // no data selected
                this.dsStock = [];
                this.dsSource = [];
                resolve({ workCenterId: data.workCenterId, wasteTypeId: data.wasteTypeId, totalQuantity: 0, edit: data.edit });
            }
        });
    }

    getInsertWithElementsSelected(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.ds.getActualStock(data.wasteTypeId, data.workCenterId).load().then(dd => {
                if (dd && dd.data) {
                    data.dsSelected.forEach(element => {
                        const i = dd.data.findIndex(x => x.id == element.id);
                        if (i > -1) {
                            dd.data[i].quantity = element.quantity;
                        }
                        this.selectedKeys.push(element.id);
                    });
                }
                let index = dd.data.findIndex(x => x.quantity == null || undefined);
                while (index > -1) {
                    dd.data[index].quantity = 0;
                    index = dd.data.findIndex(x => x.quantity == null || undefined);
                }
                this.dsStock = dd.data;
                this.model.dsSource = [];
                resolve({
                    workCenterId: data.workCenterId,
                    wasteTypeId: data.wasteTypeId,
                    totalQuantity: 0,
                    edit: data.edit
                });
            });
        });
    }

    editIncinerationWithNoElementsSelected(data: any): Promise<any> {

        return new Promise<any>((resolve, reject) => {
            this.ds.getActualStock(data.wasteTypeId, data.workCenterId).load().then(actualStock => {
                if (actualStock && actualStock.data) {
                    const q = new EntityQuery('Elimination').eq('id', data.eliminationId);
                    q.fields.push('id', 'observations');
                    const qq = new EntityQuery('EliminationSource');
                    qq.fields.push('id', 'quantity', 'eliminationId', 'generationId', 'collectionId', 'treatmentItemId');
                    EntityStore.fromQuery(q.link('id', 'eliminationId', qq), false, 'id').query().then(incinerations => {

                        const incinerationsArray: any = [];
                        const incinerationsQuantities: any = [];
                        incinerations.forEach(incineration => {
                            const id = incineration.f5 != null ? incineration.f5 :
                                (incineration.f6 != null ? incineration.f6 : incineration.f7);
                            const quantity = incineration.f3;
                            incinerationsQuantities.push({ id: id, quantity: quantity });
                            incinerationsArray.push(id);
                        });


                        let index;
                        this.ds.getIncineratedStock(incinerationsArray).load().then(incinerationStock => {
                            if (incinerationStock.data.length > 0) {
                                incinerationsQuantities.forEach(element => {
                                    index = incinerationStock.data.findIndex(x => x.id == element.id);
                                    if (index > -1) {
                                        incinerationStock.data[index].quantity = element.quantity;
                                        incinerationStock.data[index].remain += element.quantity;
                                    }
                                });
                                incinerationStock.data.forEach(element => {
                                    index = actualStock.data.findIndex(x => x.id == element.id);
                                    if (index > -1) {
                                        actualStock.data[index].quantity = element.quantity;
                                        actualStock.data[index].remain += element.quantity;
                                    } else {
                                        actualStock.data.push(element);
                                    }
                                    this.selectedKeys.push(element.id);
                                });
                            }
                            index = actualStock.data.findIndex(x => x.quantity == null || undefined);
                            while (index > -1) {
                                actualStock.data[index].quantity = 0;
                                index = actualStock.data.findIndex(x => x.quantity == null || undefined);
                            }
                            this.dsStock = actualStock.data;
                            this.model.dsSource = [];
                            resolve({
                                workCenterId: data.workCenterId,
                                wasteTypeId: data.wasteTypeId,
                                totalQuantity: 0,
                                edit: data.edit
                            });

                        });

                    });
                }
            });
        });
    }

    editFullTransportWithNoElementsSelected(data: any) {
        return new Promise<any>((resolve, reject) => {
            this.ds.getActualStock(data.wasteTypeId, data.workCenterId).load().then(actualStock => {
                if (actualStock && actualStock.data) {
                    const q = new EntityQuery('Transport').eq('id', data.fullTransportId);
                    q.fields.push('id', 'observations');
                    const qq = new EntityQuery('TransportSource');
                    qq.fields.push('id', 'quantity', 'transportId', 'generationId', 'collectionId', 'treatmentItemId');
                    EntityStore.fromQuery(q.link('id', 'transportId', qq), false, 'id').query().then(transports => {

                        const transportsArray: any = [];
                        const transportsQuantities: any = [];
                        transports.forEach(transport => {
                            const id = transport.f5 != null ? transport.f5 :
                                (transport.f6 != null ? transport.f6 : transport.f7);
                            const quantity = transport.f3;
                            transportsQuantities.push({ id: id, quantity: quantity });
                            transportsArray.push(id);
                        });
                        let index;
                        this.ds.getIncineratedStock(transportsArray).load().then(transportStock => {
                            if (transportStock.data.length > 0) {
                                transportsQuantities.forEach(element => {
                                    index = transportStock.data.findIndex(x => x.id == element.id);
                                    if (index > -1) {
                                        transportStock.data[index].quantity = element.quantity;
                                        transportStock.data[index].remain += element.quantity;
                                    }
                                });
                                transportStock.data.forEach(element => {
                                    index = actualStock.data.findIndex(x => x.id == element.id);
                                    if (index > -1) {
                                        actualStock.data[index].quantity = element.quantity;
                                        actualStock.data[index].remain += element.quantity;
                                    } else {
                                        actualStock.data.push(element);
                                    }
                                    this.selectedKeys.push(element.id);
                                });
                            }
                            index = actualStock.data.findIndex(x => x.quantity == null || undefined);
                            while (index > -1) {
                                actualStock.data[index].quantity = 0;
                                index = actualStock.data.findIndex(x => x.quantity == null || undefined);
                            }
                            this.dsStock = actualStock.data;
                            this.model.dsSource = [];
                            resolve({
                                workCenterId: data.workCenterId,
                                wasteTypeId: data.wasteTypeId,
                                totalQuantity: 0,
                                edit: data.edit
                            });

                        });

                    });
                }
            });
        });
    }

    editIncinerationWithElementsSelected(data: any) {
        return new Promise<any>((resolve, reject) => {
            this.ds.getActualStock(data.wasteTypeId, data.workCenterId).load().then(actualStock => {
                if (actualStock && actualStock.data) {
                    const q = new EntityQuery('Elimination').eq('id', data.eliminationId);
                    q.fields.push('id', 'observations');
                    const qq = new EntityQuery('EliminationSource');
                    qq.fields.push('id', 'quantity', 'eliminationId', 'generationId', 'collectionId', 'treatmentItemId');
                    EntityStore.fromQuery(q.link('id', 'eliminationId', qq), false, 'id').query().then(incinerations => {

                        const incinerationsArray: any = [];
                        const incinerationsQuantities: any = [];
                        incinerations.forEach(incineration => {
                            const id = incineration.f5 != null ? incineration.f5 :
                                (incineration.f6 != null ? incineration.f6 : incineration.f7);
                            const quantity = incineration.f3;
                            incinerationsQuantities.push({ id: id, quantity: quantity });
                            incinerationsArray.push(id);
                        });
                        let index;
                        this.ds.getIncineratedStock(incinerationsArray).load().then(incinerationStock => {
                            if (incinerationStock.data.length > 0) {
                                incinerationsQuantities.forEach(element => {
                                    index = incinerationStock.data.findIndex(x => x.id == element.id);
                                    if (index > -1) {
                                        incinerationStock.data[index].quantity = element.quantity;
                                        incinerationStock.data[index].remain += element.quantity;
                                    }
                                });
                                incinerationStock.data.forEach(element => {
                                    index = actualStock.data.findIndex(x => x.id == element.id);
                                    if (index > -1) {
                                        actualStock.data[index].quantity = element.quantity;
                                        actualStock.data[index].remain = element.remain;
                                    } else {
                                        actualStock.data.push(element);
                                    }
                                });
                            }
                            actualStock.data.forEach(element => {
                                index = data.dsSelected.findIndex(x => x.id == element.id);
                                if (index > -1) {
                                    element.quantity = data.dsSelected[index].quantity;
                                    this.selectedKeys.push(element.id);
                                } else {
                                    element.quantity = 0;
                                }
                            });
                            index = actualStock.data.findIndex(x => x.quantity == null || undefined);
                            while (index > -1) {
                                actualStock.data[index].quantity = 0;
                                index = actualStock.data.findIndex(x => x.quantity == null || undefined);
                            }
                            this.dsStock = actualStock.data;
                            this.model.dsSource = [];
                            resolve({
                                workCenterId: data.workCenterId,
                                wasteTypeId: data.wasteTypeId,
                                totalQuantity: 0,
                                edit: data.edit
                            });
                        });
                    });
                }
            });
        });
    }

    editFullTransportWithElementsSelected(data: any) {
        return new Promise<any>((resolve, reject) => {
            this.ds.getActualStock(data.wasteTypeId, data.workCenterId).load().then(actualStock => {
                if (actualStock && actualStock.data) {
                    const q = new EntityQuery('Transport').eq('id', data.fullTransportId);
                    q.fields.push('id', 'observations');
                    const qq = new EntityQuery('TransportSource');
                    qq.fields.push('id', 'quantity', 'transportId', 'generationId', 'collectionId', 'treatmentItemId');
                    EntityStore.fromQuery(q.link('id', 'transportId', qq), false, 'id').query().then(transports => {

                        const transportsArray: any = [];
                        const transportsQuantities: any = [];
                        transports.forEach(incineration => {
                            const id = incineration.f5 != null ? incineration.f5 :
                                (incineration.f6 != null ? incineration.f6 : incineration.f7);
                            const quantity = incineration.f3;
                            transportsQuantities.push({ id: id, quantity: quantity });
                            transportsArray.push(id);
                        });
                        let index;
                        this.ds.getIncineratedStock(transportsArray).load().then(transportStock => {
                            if (transportStock.data.length > 0) {
                                transportsQuantities.forEach(element => {
                                    index = transportStock.data.findIndex(x => x.id == element.id);
                                    if (index > -1) {
                                        transportStock.data[index].quantity = element.quantity;
                                        transportStock.data[index].remain += element.quantity;
                                    }
                                });
                                transportStock.data.forEach(element => {
                                    index = actualStock.data.findIndex(x => x.id == element.id);
                                    if (index > -1) {
                                        actualStock.data[index].quantity = element.quantity;
                                        actualStock.data[index].remain = element.remain;
                                    } else {
                                        actualStock.data.push(element);
                                    }
                                });
                            }
                            actualStock.data.forEach(element => {
                                index = data.dsSelected.findIndex(x => x.id == element.id);
                                if (index > -1) {
                                    element.quantity = data.dsSelected[index].quantity;
                                    this.selectedKeys.push(element.id);
                                } else {
                                    element.quantity = 0;
                                }
                            });
                            index = actualStock.data.findIndex(x => x.quantity == null || undefined);
                            while (index > -1) {
                                actualStock.data[index].quantity = 0;
                                index = actualStock.data.findIndex(x => x.quantity == null || undefined);
                            }
                            this.dsStock = actualStock.data;
                            this.model.dsSource = [];
                            resolve({
                                workCenterId: data.workCenterId,
                                wasteTypeId: data.wasteTypeId,
                                totalQuantity: 0,
                                edit: data.edit
                            });
                        });
                    });
                }
            });
        });
    }
}
