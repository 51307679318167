import { Injectable } from '@angular/core';
import { EditUnitService } from '../unit';
import { iBisLanguageService } from '@dohu/ibis-common';

@Injectable()
export class EditPartyWorkCenterService extends EditUnitService {

    constructor(lg: iBisLanguageService) {
        super(lg);
        this.validation = 'wcValidation';
    }

    createDefault(): any {
        return { isActiv: true, isSubcontractor: false, typeId: 4 };
    }
}
