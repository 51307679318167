import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { EditUnitService } from './editUnit.service';
import DataSource from 'devextreme/data/data_source';

@Component({
    selector: 'app-edit-unit',
    templateUrl: 'editUnit.component.html'

})
export class EditUnitComponent {
    @Input() isInitAppVisible: boolean;
    @Output() initForm = new EventEmitter<any>();
    dsLocality: any;
    dsCounty: CustomStore;

    onTypeChange: any;
    onCountyChange: any;
    onLocalityInit: any;
    onParentInit: any;
    cboLocality: any;
    cboParent: any;

    constructor(public ds: DataService, public edit: EditUnitService) {
        this.dsCounty = this.ds.geographicAreas(2);

        setTimeout(() => {
            if (this.edit.model.countyId) {
                this.dsLocality = this.ds.getLocalitiesByCountyId(this.edit.model.countyId);
            } else {
                this.setLocalityDataSource();
            }
        }, 0);

        this.onTypeChange = (data) => { this.onTypeChangeEv(data, this); };
        this.onCountyChange = (data) => { this.onCountyChangeEv(data, this); };
        this.onLocalityInit = (data) => { this.onLocalityInitEv(data, this); };
        this.onParentInit = (data) => { this.onParentInitEv(data, this); };
    }

    onInitDataForm(e: any) {
        this.initForm.emit(e.component);
    }

    onTypeChangeEv(event, that) {
        if (event.value) {
            that.cboParent.option('dataSource', that.ds.getParentParty(that.edit.model.typeId));
        }
        if (event.event) {
            that.cboLocality.option('value', null);
        }
    }

    onCountyChangeEv(event, that) {
        if (event.event) {
            that.ds.resetValidation(that.cboLocality);
        }
        if (event.value) {
            that.cboLocality.option('dataSource', that.ds.getLocalitiesByCountyId(event.value));
        } else if (event.value === null) {
            that.setLocalityDataSource();
        }
    }

    setLocalityDataSource() {
        this.dsLocality = new DataSource({
            store: this.ds.geographicAreas(1),
            paginate: true,
            pageSize: 50
        });
    }

    onParentInitEv(event, that) {
        that.cboParent = event.component;
        setTimeout(function () {
            event.component.option('dataSource', that.ds.getParentParty(that.edit.model.typeId));
        }, 0);
    }

    onLocalityInitEv(event, that) {
        that.cboLocality = event.component;
    }

    onFieldDataChanged(item) {
        if (item.dataField === 'typeId') {
            const editor = item.component.getEditor('parentId');
            if (item.value === 0) {
                editor.option('disabled', true);
                editor.option('value', null);
                return;
            } else {
                if (editor) {
                    editor.option('disabled', false);
                }
            }
        }
    }
}
