import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';


@Component({
    selector: 'app-edit-control',
    templateUrl: 'editControl.component.html'
})

export class EditControlComponent implements OnInit {
    dsEntities: CustomStore;
    dsWorkCenter: CustomStore;

    @Input() controlForm: any;
    currentDate: Date;
    onEntityChange: any;
    workingCenter: any;
    onWcInit: any;

    constructor(public ds: DataService) {
        this.onEntityChange = this.onEntityChangeEv.bind(this);
        this.onWcInit = this.onWcInitEv.bind(this);

        this.currentDate = new Date();
        this.dsEntities = this.ds.getParty('ent');
    }

    ngOnInit() {
        if (this.controlForm.c_entityId && this.controlForm.c_entityId.length) {
            this.dsWorkCenter = this.ds.getPartyMemberById(this.controlForm.c_entityId);
            this.controlForm.wcDisable = false;
        }
    }

    onEntityChangeEv(event: any) {
        if (event.event) {
            this.ds.resetValidation(this.workingCenter);
            this.controlForm.wcDisable = false;
        }
        if (event.value) {
            this.dsWorkCenter = this.ds.getPartyMemberById(event.value);
        }
    }

    onWcInitEv(event: any) {
        this.workingCenter = event.component;
    }
}
