import { Injectable } from '@angular/core';
import { iBisEditService } from '@dohu/ibis-common';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { DataService } from '../../../service/data.service';
import notify from 'devextreme/ui/notify';
import DataSource from 'devextreme/data/data_source';

@Injectable({
  providedIn: 'root'
})
export class EditTransportApprovalService extends iBisEditService {

  dsDriver: DataSource;
  dsCar: DataSource;

  constructor(public ds: DataService) {
    super(ds.lg);
    this.validation = 'transportApprovalValidation';
  }

  createDefault() {
    return { isValid: true, type: 'Anexa 1' };
  }

  getById(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      EntityStore.fromQuery(new EntityQuery('TransportApproval').eq('id', data.ta_id)).single().then((obj) => {
        if (!obj) {
          reject();
        } else {
          if (obj.transporterId) {
            this.dsDriver = this.ds.getPaginateDriverByTransporterId(obj.transporterId);
            this.dsCar = this.ds.getPaginateCarByTransporterId(obj.transporterId);
          }
          obj.quantityLeft = data.ta_approvedQuantity - data.c_quantity;
          obj.transLeft = data.ta_approvedNo - data.c_no;
          resolve(obj);
        }
      });
    });
  }

  onRemove(id: string): Promise<void> {
    return EntityStore.fromQuery(new EntityQuery('TransportApproval')).remove(id);
  }

  onSaveEv(): Promise<any> {
    const obj = this.model;
    const s = EntityStore.fromQuery(new EntityQuery('TransportApproval'));
    return (this.model.id ? s.update(this.model.id, obj) : s.insert(obj));
  }

  reset(): void {
    this.model = this.createDefault();
  }


}
