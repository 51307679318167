import { Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';

@Component({
  selector: 'app-actual-stock',
  templateUrl: './actualStock.component.html'
})
export class ActualStockComponent implements OnInit {

  dsActualStock: CustomStore;
  dsWorkCenterFilter: CustomStore;
  dsGenerator: CustomStore;
  fromDate: Date;
  toDate: Date;

  constructor(public ds: DataService, public ui: UIService) {
    this.dsActualStock = this.ds.getActualStock();
    this.dsWorkCenterFilter = this.ds.getWorkCenterForActualStock();
    this.dsGenerator = this.ds.getParty('cs');
    this.fromDate = new Date();
    this.toDate = new Date();
  }

  ngOnInit() {
  }

  onCellPreparedEv(event: any) {
    if (event.rowType == 'data' && event.row && event.row.data) {
      if (event.row.data.remain < 0) {
        event.cellElement.classList.add('dx-red');
      }
      if (event.row.data.remain == 0.0) {
        event.cellElement.classList.add('dx-bold');
      }
    }
  }

  mainToolbarPreparing(ev: any) {
    this.ui.prepareToolbar(ev, true);
    // ev.toolbarOptions.items.unshift(
    //   {
    //     text: 'De la data',
    //     location: 'before'
    //   },
    //   {
    //     widget: 'dxDateBox',
    //     options: {
    //       value: this.fromDate,
    //       displayFormat: 'dd.MM.yyyy',
    //       onValueChanged: (e) => {
    //         this.fromDate = e.value;
    //       }
    //     },
    //     location: 'before'
    //   },
    //   {
    //     text: 'Până la data',
    //     location: 'before'
    //   },
    //   {
    //     widget: 'dxDateBox',
    //     options: {
    //       value: this.toDate,
    //       displayFormat: 'dd.MM.yyyy',
    //       onValueChanged: (e) => {
    //         this.toDate = e.value;
    //       }
    //     },
    //     location: 'before'
    //   },
    //   {
    //     widget: 'dxButton',
    //     options: {
    //       text: 'Filtreaza',
    //       onClick: (e: any) => { console.log(this.fromDate + '<-pana la data de->' + this.toDate); }
    //     },
    //     location: 'before'
    //   });
    //     {
    //       widget: 'dxDateBox',
    //       options: {
    //         value: new Date(),
    //         displayFormat: 'dd.MM.yyyy',
    //         onValueChanged: (e) => {
    //           this.filterSearch.date = e.value;
    //         }
    //       },
    //       location: 'after'
    //     },
    //     {
    //       widget: 'dxSelectBox',
    //       options: {
    //         placeholder: 'Punct de lucru',
    //         dataSource: this.dsWorkCenterFilter,
    //         valueExpr: 'id',
    //         displayExpr: 'name',
    //         searchEnabled: true,
    //         showClearButton: true,
    //         onSelectionChanged: (e: any) => {
    //           if (e.selectedItem && e.selectedItem.id) {
    //             this.wasteCodeInstance.option('dataSource', this.ds.setWasteCodeDataSourceForWorkCenter(e.selectedItem.id));
    //             this.wasteCodeInstance.option('disabled', false);
    //             this.filterSearch.wc = e.selectedItem.name;
    //           } else {
    //             this.wasteCodeInstance.option('dataSource', null);
    //             this.wasteCodeInstance.option('disabled', true);
    //             this.wasteCodeInstance.option('value', null);
    //             this.filterSearch.wc = null;
    //           }
    //         }
    //       },
    //       location: 'after'
    //     },
    //     {
    //       widget: 'dxSelectBox',
    //       options: {
    //         placeholder: 'Cod deșeu',
    //         valueExpr: 'id',
    //         displayExpr: 'code',
    //         searchEnabled: true,
    //         disabled: true,
    //         showClearButton: true,
    //         onValueChanged: (e) => {
    //           this.filterSearch.wasteCode = e.value;
    //         },
    //         onInitialized: (e: any) => {
    //           this.wasteCodeInstance = e.component;
    //         }
    //       },
    //       location: 'after'
    //     },
    //     {
    //       widget: 'dxSelectBox',
    //       options: {
    //         placeholder: 'Generator',
    //         dataSource: this.dsGenerator,
    //         valueExpr: 'name',
    //         displayExpr: 'name',
    //         searchEnabled: true,
    //         showClearButton: true,
    //         onValueChanged: (e) => {
    //           this.filterSearch.entity = e.value;
    //         }
    //       },
    //       location: 'after'
    //     },
    //     {
    //       widget: 'dxButton',
    //       options: {
    //         hint: 'Caută',
    //         icon: 'search',
    //         onClick: (e: any) => { this.dsActualStock = this.ds.getActualStockFilter(this.filterSearch); }
    //       },
    //       location: 'after'
    //     });
  }
}
