import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { DataService } from '../service/data.service';
import DataSource from 'devextreme/data/data_source';
import { DxMultiViewComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';


@Component({
    selector: 'app-wizard-gentrans',
    templateUrl: 'generareTransport.component.html'
})
export class GenerareTransportComponent {
    @Input() isGenTransVisible: boolean;
    @Output() closePopup = new EventEmitter<any>();
    goToPrevious: any;
    goToNext: any;
    wizardState: number;
    onFinish: any;
    wizardValidationArr: string[];
    wizardValidation: string;
    multiViewItems: DataSource;
    generateModel: any;
    newTransport: any;
    finishTransport: any;
    showTransportData: any;

    @ViewChild('multiview') multiview: DxMultiViewComponent;

    constructor(public ds: DataService) {
        this.isGenTransVisible = false;

        this.goToPrevious = this.goToPreviousEv.bind(this);
        this.goToNext = this.gotoNextEv.bind(this);
        this.onFinish = this.onFinishEv.bind(this);

        this.wizardValidationArr = ['gdValidation', 'addTransProgValidation', 'addFinishTransValidation'];

        this.initMultiView();
    }


    onFinishEv(event: any) {
        event.event.preventDefault();
        this.closeWizard(event);
    }

    gotoNextEv(event: any) {
        event.event.preventDefault();
        const validation = event.validationGroup.validate();
        if (!validation.isValid) {
            notify(this.ds.lg.format("ALL_REQ_FIELDS", null), 'error', 3000);
            return;
        }
        this.wizardValidation = this.wizardValidationArr[this.wizardState];
        const current = this.multiview.instance.option('selectedIndex');
        this.wizardState = current + 1;
        this.multiview.instance.option('selectedIndex', current + 1);
    }
    goToPreviousEv(event: any) {
        event.event.preventDefault();
        const current = this.multiview.instance.option('selectedIndex');
        this.wizardState = current - 1;
        this.multiview.instance.option('selectedIndex', current - 1);
    }

    closeWizard(event: any) {
        const current = this.multiview.instance.option('selectedIndex');
        this.isGenTransVisible = false;
        this.wizardState = 0;
        this.closePopup.emit(this.isGenTransVisible);
    }

    initMultiView() {
        this.wizardState = 0;
        this.wizardValidation = this.wizardValidationArr[this.wizardState];
        this.generateModel = { g_date: new Date() };
        this.newTransport = { t_sendDate: new Date() };
        this.finishTransport = {};
        this.showTransportData = {};

        this.multiViewItems = new DataSource({
            store: [{
                title: this.ds.lg.format("GENERATE", null)
            }, {
                title: this.ds.lg.format("TRANSPORT", null),
            }, {
                title: this.ds.lg.format("TRANS_COMPLETION", null),
            }],
            // map: (itemData) => {
            //     itemData.dataArray = Object.keys(itemData.data).map((key) => {
            //         return {
            //             propertyKey: key,
            //             propertyValue: itemData.data[key]
            //         };
            //     });
            //     return itemData;
            // }
        });
    }
}
