
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { UIService } from '../../../service/ui.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: 'eliminate.component.html'
})
export class EliminateComponent implements OnInit {

    dsEliminate: CustomStore;
    dsElimination: any;
    wasteTypeId: string;

    constructor(public ds: DataService, public ui: UIService, public activeRoute: ActivatedRoute) { }

    ngOnInit() {
        this.dsElimination = this.ds.getEnumValue('Eliminare');

        this.activeRoute.queryParams.subscribe(params => {
            this.wasteTypeId = params['wasteType'];

            this.dsEliminate = this.ds.getEliminateInfo(this.wasteTypeId);
        });
    }
}
