import { Component, Input } from '@angular/core';
import { DataService } from '../../service/data.service';
import { UIService } from '../../service/ui.service';
import { iBisEditService } from '@dohu/ibis-common';

@Component({
    selector: 'app-edit-popup',
    templateUrl: './edit-popup.component.html'
})
export class EditPopupComponent {
    @Input() title: string;
    @Input() showAdd: boolean;
    @Input() edit: iBisEditService;
    constructor(public ds: DataService, public ui: UIService) { }
}
