import { Component, NgModule } from '@angular/core';
import { UIService } from '../../../service/ui.service';
import { DataService } from '../../../service/data.service';
import { EditContractService } from './editContract.service';
import CustomStore from 'devextreme/data/custom_store';
import { ActivatedRoute } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { DxScrollViewModule, DxFormModule, DxPopupModule } from 'devextreme-angular';

@Component({
    selector: 'app-edit-contract',
    templateUrl: './editContract.component.html'
})
export class EditContractComponent {
    partyTypeName: string;
    dsContractType: CustomStore;
    dsBoolean: { id: any; name: any; }[];
    dsTo: DataSource;

    constructor(public ui: UIService, public ds: DataService, public edit: EditContractService,
        public activeRoute: ActivatedRoute) {
        this.dsBoolean = this.ds.boolean('bool');

        this.activeRoute.queryParams.subscribe(params => {
            const partyType = params['partyType'];
            if (partyType) {
                const ctrType = partyType.indexOf('0') > -1 ? 0 : (partyType.indexOf('1') > -1 ? 1 : 2);
                this.dsContractType = this.ds.getEnumValue(this.ds.static.contractTypeKeys[ctrType]);

                if (typeof partyType === 'object') {
                    const activity = [];
                    for (const t of partyType) {
                        if (ctrType === 1 && t !== '1') {
                            activity.push(this.ds.static.activityTypeKeys[t]);
                        }
                    }
                    this.partyTypeName = ds.static.contractTypeValue[ctrType];
                    this.dsTo = this.ds.getPaginateParty('cl', true, null, activity);
                } else {
                    this.partyTypeName = ds.static.contractTypeValue[partyType];
                    this.dsContractType = this.ds.getEnumValue(this.ds.static.contractTypeKeys[partyType]);
                    this.dsTo = this.ds.getPaginateParty('cl', true, null, this.ds.static.activityTypeKeys[partyType]);

                }
            } else {
                this.partyTypeName = 'Partener';
                this.dsContractType = this.ds.getEnumValue('Contract');
                this.dsTo = this.ds.getPaginateParty('cl', true);
            }
        });
    }

    dateComparison = () => {
        const d = new Date(this.edit.model.startDate);
        d.setDate(d.getDate() - 1);
        return d;
    }
}