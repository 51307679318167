import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { UIService } from '../../../service/ui.service';

@Component({
    templateUrl: 'control.component.html'
})
export class ControlComponent implements OnInit {
    dsControls: CustomStore;
    ct: any;
    sanction: any;
    action: any;
    workingCenter: any;

    isControlsVisible: boolean;
    isSanctionPopupVisible: boolean;
    isActionPopupVisible: boolean;

    @ViewChild('gridControls') gridControls: DxDataGridComponent;
    @ViewChild('gridSanctions') gridSanctions: DxDataGridComponent;
    @ViewChild('gridActions') gridActions: DxDataGridComponent;
    onEditControlClick: any;
    onDeleteControlClick: any;
    onEditSanctionClick: any;
    onDeleteSanctionClick: any;
    onEditActionClick: any;
    onDeleteActionClick: any;
    onSaveControlsNew: any;
    onSaveControls: any;
    onSaveSanctionNew: any;
    onSaveSanction: any;
    onSaveActionNew: any;
    onSaveAction: any;
    constructor(public ds: DataService, public ui: UIService) {
        this.onEditControlClick = this.onEditControl.bind(this);
        this.onDeleteControlClick = this.onDeleteControl.bind(this);
        this.onEditSanctionClick = this.onEditSanction.bind(this);
        this.onDeleteSanctionClick = this.onDeleteSanction.bind(this);
        this.onEditActionClick = this.onEditAction.bind(this);
        this.onDeleteActionClick = this.onDeleteAction.bind(this);

        this.onSaveControlsNew = this.onSaveControlsEv.bind(this, 1);
        this.onSaveControls = this.onSaveControlsEv.bind(this, 0);
        this.onSaveSanctionNew = this.onSaveSanctionEv.bind(this, 1);
        this.onSaveSanction = this.onSaveSanctionEv.bind(this, 0);
        this.onSaveActionNew = this.onSaveActionEv.bind(this, 1);
        this.onSaveAction = this.onSaveActionEv.bind(this, 0);
    }

    ngOnInit() {
        this.dsControls = this.ds.getControlView();

        this.ct = {};
        this.sanction = {};
        this.action = {};

        this.isControlsVisible = false;
        this.isSanctionPopupVisible = false;
        this.isActionPopupVisible = false;
    }

    // Controls

    onEditControl(e: any) {
        e.event.preventDefault();
        this.ct = e.row.data;
        this.isControlsVisible = true;
    }

    onDeleteControl(e: any) {
        e.event.preventDefault();
        confirm(this.ds.lg.format("DELETE_RECORD", null), this.ds.lg.format("CONFIRM", null)).then(val => {
            if (val) {
                this.ds.deleteControl(e.row.data.c_id).then(result => {
                    notify('Succes!', 'success', 3000);
                    e.component.refresh();
                }, err => {
                    this.ds.serverError(err);
                });
            }
        });
    }

    onSaveControlsEv(type: number, event: any) {
        event.event.preventDefault();
        const validation = event.validationGroup.validate();
        if (!validation.isValid) {
            notify(this.ds.lg.format("ALL_REQ_FIELDS", null), 'error', 3000);
            return;
        }
        const obj = {
            authority: this.ct.c_authority,
            date: this.ct.c_date,
            entityId: this.ct.c_entityId,
            cases: this.ct.c_cases,
            workCenterId: this.ct.c_workCenterId,
            sanction: this.ct.c_sanction,
            typeId: this.ct.c_typeId,
            action: this.ct.c_action,
            planned: this.ct.c_planned,
            cost: this.ct.c_cost,
            reason: this.ct.c_reason,
            costDescription: this.ct.c_costDescription,
        };

        this.ds.addEditControl(obj, this.ct.c_id).then(() => {
            this.whenDone(type, 'controls');
        }, err => {
            this.ds.serverError(err);
        });
    }

    // Sanction

    onSanctionsContentReady(e: any, sanction: any) {
        if (e.component.option('dataSource') == null) {
            e.component.option('dataSource', this.ds.getControlSanctions(sanction.c_id));
        }
        this.sanction.controlId = sanction.c_id;
    }

    onSaveSanctionEv(type: number, event: any) {
        event.event.preventDefault();
        const obj = {
            controlId: this.sanction.controlId,
            sanctionDescription: this.sanction.sanctionDescription,
            termSanction: this.sanction.termSanction,
            sanctionStatus: this.sanction.sanctionStatus,
            notifications: this.sanction.notifications,
        };

        this.ds.addEditSanction(obj, this.sanction.id).then(() => {
            this.whenDone(type, 'sanctions');
        }, error => {
            this.ds.serverError(error);
        });
    }

    onEditSanction(e: any) {
        e.event.preventDefault();
        this.isSanctionPopupVisible = true;
        this.sanction = e.row.data;
    }
    onDeleteSanction(e: any) {
        e.event.preventDefault();
        confirm(this.ds.lg.format("DELETE_RECORD", null), this.ds.lg.format("CONFIRM", null)).then(val => {
            if (val) {
                this.ds.deleteSanction(e.row.data.id).then(() => {
                    notify('Success', 'success', 2000);
                    e.component.refresh();
                }, error => {
                    this.ds.serverError(error);
                });
            }
        });
    }

    // Action

    onActionContentReady(e: any, measure: any) {
        if (e.component.option('dataSource') == null) {
            e.component.option('dataSource', this.ds.getControlMeasures(measure.c_id));
        }
    }

    onSaveActionEv(type: number, event: any) {
        event.event.preventDefault();
        const obj = {
            controlId: this.action.controlId,
            actionDescription: this.action.actionDescription,
            termAction: this.action.termAction,
            actionStatus: this.action.actionStatus,
            notifications: this.action.notifications,
        };
        this.ds.addEditMeasure(obj, this.action.id).then(result => {
            this.whenDone(type, 'actions');
        }, error => {
            this.ds.serverError(error);
        });
    }

    onEditAction(e: any) {
        e.event.preventDefault();
        this.isActionPopupVisible = true;
        this.action = e.row.data;
    }
    onDeleteAction(e: any) {
        e.event.preventDefault();
        confirm(this.ds.lg.format("DELETE_RECORD", null), this.ds.lg.format("CONFIRM", null)).then(val => {
            if (val) {
                this.ds.deleteMeasure(e.row.data.id).then(() => {
                    notify('Success', 'success', 2000);
                    e.component.refresh();
                }, error => {
                    this.ds.serverError(error);
                });
            }
        });
    }

    toolbarPreparing(event: any, type: string, controlId?: string) {
        this.ui.prepareToolbar(event, true);

        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                icon: 'assets/img/grid_add.svg', hint: this.ds.lg.format("ADD_ROW", null), onClick: (e: any) => {
                    switch (type) {
                        case 'controls':
                            this.ct = { c_planned: false, c_action: false, c_sanction: false, wcDisable: true };
                            this.isControlsVisible = true;
                            break;
                        case 'sanctions':
                            this.sanction = { controlId: controlId };
                            this.isSanctionPopupVisible = true;
                            break;
                        case 'actions':
                            this.action = { controlId: controlId };
                            this.isActionPopupVisible = true;
                            break;
                    }
                }
            },
            location: 'after'
        });
    }

    whenDone(buttonType: number, eventType: string) {
        switch (eventType) {
            case 'controls':
                buttonType === 0 ? this.isControlsVisible = false : this.isControlsVisible = true;
                this.ct = { wcDisable: true };
                notify('Success', 'success', 2000);
                this.gridControls.instance.refresh();
                break;

            case 'sanctions':
                buttonType === 0 ? this.isSanctionPopupVisible = false : this.isSanctionPopupVisible = true;
                this.sanction = {};
                notify('Success', 'success', 2000);
                this.gridSanctions.instance.refresh();
                break;

            case 'actions':
                buttonType === 0 ? this.isActionPopupVisible = false : this.isActionPopupVisible = true;
                this.action = {};
                notify('Success', 'success', 2000);
                this.gridActions.instance.refresh();
                break;
        }
    }

}
