import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'app-treatment-item',
    templateUrl: './treatmentItem.component.html'
})
export class TreatmentItemComponent implements OnInit {

    dsTreatmentIn: CustomStore;

    @ViewChild('gridTreatmentOut') gridTreatmentOut: DxDataGridComponent;
    dsTreatmentType: { id: number; value: string; }[];
    constructor(public ds: DataService, public ui: UIService) {
    }

    ngOnInit() {
        this.dsTreatmentIn = this.ds.getTreatmentItemView();
        this.dsTreatmentType = this.ds.getTreatmentType('eliminare');
    }

    mainToolbarPreparing(ev: any) {
        this.ui.prepareToolbar(ev, true);
    }
}
