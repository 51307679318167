import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { DxDataGridComponent } from 'devextreme-angular';
import { UIService } from '../../../service/ui.service';
import { EditAgencyService } from './edit-agency.service';

@Component({
    templateUrl: 'agency.component.html'
})
export class AgencyComponent implements OnInit {
    dsCounty: CustomStore;
    dsLocality: any;
    dsAgency: CustomStore;

    isAgencyVisible: boolean;

    onEditAgencyClick: any;
    onDeleteAgencyClick: any;

    @ViewChild('gridAgency') gridAgency: DxDataGridComponent;

    constructor(public ds: DataService, public ui: UIService, public edit: EditAgencyService) {
        this.onEditAgencyClick = this.onEditAgency.bind(this);
        this.onDeleteAgencyClick = this.onDeleteAgency.bind(this);
    }

    ngOnInit() {

        this.dsAgency = this.ds.getAgencies();
        this.dsLocality = this.ds.geographicAreas(1);
        this.dsCounty = this.ds.geographicAreas(2);

        this.isAgencyVisible = false;

    }

    onEditAgency(e: any) {
        e.event.preventDefault();
        this.edit.showPopup(e.row.data.a_id, true).then(() => {
            this.gridAgency.instance.refresh();
        }, (error) => {
            this.ds.serverError(error);
        });
    }

    onDeleteAgency(e: any) {
        e.event.preventDefault();
        confirm(this.ds.lg.format('DELETE_RECORD', null), this.ds.lg.format('CONFIRM', null)).then(val => {
            if (val) {
                this.edit.onRemove(e.row.data.a_id).then(() => {
                    notify('Success', 'success', 2000);
                    this.gridAgency.instance.refresh();
                }, (error) => {
                    this.ds.serverError(error);
                });
            }
        });
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: this.ds.lg.format('ADD', null),
                icon: 'assets/img/grid_add.svg',
                onClick: (e: any) => {
                    this.edit.showPopup(null).then(() => {
                        this.gridAgency.instance.refresh();
                    });
                }
            },
            location: 'after'
        });
    }
}
