import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { DxFormComponent } from 'devextreme-angular';
import { EditPartyService } from '../../../admin/party/editParty.service';
import { UIService } from '../../../../service/ui.service';
import { EditTransportService } from '../editTransport.service';
import { EditActualStockService } from '../../treatment';
import DataSource from 'devextreme/data/data_source';

@Component({
    selector: 'app-edit-full-transport',
    templateUrl: './editFullTransport.component.html'
})
export class EditFullTransportComponent implements OnInit {

    // dsEntity: CustomStore;
    // dsWorkCenter: CustomStore;
    dsTransportTypes: CustomStore;
    dsValidTransports: CustomStore;
    dsDestinationTypes: CustomStore;
    dsValidDest: DataSource;
    dsCar: CustomStore;
    dsDriver: CustomStore;
    dsElimination: CustomStore;
    dsCapitalization: CustomStore;
    onEntityChange: any;
    onWorkCenterChange: any;
    wasteCodeInst: any;
    // workCenterInst: any;
    onWorkCenterInit: any;
    onWasteCodeInit: any;
    onDestinationInit: any;
    onDestinationChange: any;

    transportSelInstance: any;
    destinationInstance: any;
    onTransporterChange: any;
    formInstance: any;
    dsTransportApproval: CustomStore;
    lastTransId: string;
    onCarChange: any;
    onDriverChange: any;
    driverName: string;

    vFatValidationCallback: any;
    dsFullTransportActions: any;

    @ViewChild('fullTransportForm') fullTransportForm: DxFormComponent;
    noFatQtyCollected: any;
    dspEntities: DataSource;

    constructor(public ds: DataService, public edit: EditTransportService, public editParty: EditPartyService,
        public editS: EditActualStockService, public ui: UIService) {
        this.vFatValidationCallback = this.vFatValidationCallbackEv.bind(this);
        // this.dsEntity = this.ds.getParty('ent');
        this.dsTransportTypes = this.ds.getEnumValue('Transport');
        this.dsValidTransports = this.ds.validTransports();
        this.dsDestinationTypes = this.ds.getEnumValue('Destinatie');
        this.dsValidDest = this.ds.getPartyLinkViewPaginate([2, 4], [3, 4, 5]);
        this.dspEntities = this.ds.getPartyLinkViewPaginate([2]);

        this.dsElimination = this.ds.getEnumValue('Eliminare');
        this.dsCapitalization = this.ds.getEnumValue('Valorificare');

        // this.dsWorkCenter = this.ds.getPartyLinkView([2]);
        // this.onWorkCenterInit = this.onWorkCenterInitEv.bind(this);
        this.onWasteCodeInit = this.onWasteCodeInitEv.bind(this);
        this.onDestinationInit = this.onDestinationInitEv.bind(this);
        this.onTransporterChange = this.onTransporterChangeEv.bind(this);
        this.onCarChange = this.onCarChangeEv.bind(this);
        this.onDriverChange = this.onDriverChangeEv.bind(this);
        this.onDestinationChange = this.onDestinationChangeEv.bind(this);

        this.onEntityChange = this.onEntityChangeEv.bind(this);
        this.onWorkCenterChange = this.onWorkCenterChangeEv.bind(this);

        this.dsFullTransportActions = this.ds.static.transportActions.slice(1, this.edit.transportActions.length);

    }

    ngOnInit() {
        //this.initComponent();
    }

    onTransportPopupShow(event: any) {
        this.initComponent();
    }

    initComponent() {
        if (this.edit.model.t_wasteTypeId && this.edit.model.wc_parentId) {
            this.prepareFATDataSource(this.edit.model.t_workCenterId, this.edit.model.t_recipientId, this.edit.model.t_transporterId,
                this.edit.model.t_wasteTypeId, this.edit.model.t_carId, this.edit.model.t_tableDriverId);
        }

        if (this.edit.model.t_transporterId) {
            if (this.lastTransId !== this.edit.model.t_transporterId) {
                this.lastTransId = this.edit.model.t_transporterId;
                this.dsCar = this.ds.getCarByTransporterId(this.edit.model.t_transporterId);
                this.dsDriver = this.ds.getDriverByTransporterId(this.edit.model.t_transporterId);
            }
        }
    }

    onDestinationChangeEv(event: any) {
        if (event) {
            this.prepareFATDataSource(this.edit.model.t_workCenterId, event.value, this.edit.model.t_transporterId,
                this.edit.model.t_wasteTypeId, this.edit.model.t_carId, this.edit.model.t_tableDriverId);
        }
    }

    onCarChangeEv(event: any) {
        if (event && event.selectedItem) {
            this.edit.model.t_carNumber = event.selectedItem.registerNo;
            this.prepareFATDataSource(this.edit.model.t_workCenterId, this.edit.model.t_recipientId, this.edit.model.t_transporterId,
                this.edit.model.t_wasteTypeId, event.selectedItem.id, this.edit.model.t_tableDriverId);
        }
    }

    onDriverChangeEv(event: any) {
        if (event && event.selectedItem) {
            this.edit.model.t_driverName = event.selectedItem.lastName + ' ' + event.selectedItem.firstName;
            this.prepareFATDataSource(this.edit.model.t_workCenterId, this.edit.model.t_recipientId, this.edit.model.t_transporterId,
                this.edit.model.t_wasteTypeId, this.edit.model.t_carId, event.selectedItem.id);
        }
    }

    // tslint:disable-next-line: max-line-length
    prepareFATDataSource(generatorId: string, destinationId: string, transporterId: string, wasteTypeId: string, carId?: string, driverId?: string) {
        this.ds.getTransportApprovalView(generatorId, destinationId, transporterId, wasteTypeId, carId, driverId).load().then(data => {
            const ds = data.data;
            if (this.edit.model.t_id) {
                this.dsTransportApproval = ds;
            } else {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                for (let i = 0; i < ds.length; i++) {
                    const expireDate = new Date(ds[i].ta_expireDate);
                    if ((ds[i].t_no && (ds[i].t_no <= 0 || ds[i].t_no >= ds[i].ta_approvedNo)) ||
                        (ds[i].t_quantity && (ds[i].t_quantity <= 0 || ds[i].t_quantity >= ds[i].ta_approvedQuantity)) ||
                        (expireDate < today)) {
                        ds.splice(i, 1);
                        i--;
                    }
                }
                this.dsTransportApproval = ds;
            }
        });
    }

    // onWorkCenterInitEv(event: any) {
    //     this.workCenterInst = event.component;
    // }

    onWasteCodeInitEv(event: any) {
        this.wasteCodeInst = event.component;
    }

    customFullTransportApprovalDisplay(rowInfo: any) {
        if (rowInfo) {
            const str = 'Nr.: ' + rowInfo.ta_no
                + ' - Cant.: ' + (rowInfo.t_quantity ? rowInfo.ta_approvedQuantity - rowInfo.t_quantity : rowInfo.ta_approvedQuantity)
                + ' - Nr.tr.: ' + (rowInfo.t_no ? rowInfo.ta_approvedNo - rowInfo.t_no : rowInfo.ta_approvedNo);
            return (this && this.edit && this.edit.model.id) ? ('Nr.: ' + rowInfo.ta_no) : str;
        }
        return '';
    }

    onWasteCodeChange = (ev: any) => {
        this.fullTransportForm.instance.focus();
    }

    onOpenStockPopup = (ev: any) => {
        this.editS.showPopup({
            edit: this.edit.model.t_id ? true : false,
            fullTransportId: this.edit.model.t_id,
            wasteTypeId: this.edit.model.t_wasteTypeId,
            workCenterId: this.edit.model.t_workCenterId,
            dsSelected: this.edit.dsTransportSource
        }, true).then(data => {
            if (data && data.isSaving) {
                this.edit.model.t_quantity = data.totalQuantity;
                this.edit.model.t_wasteTypeId = data.wasteTypeId;
                this.edit.dsTransportSource = data.dsSource;
                if (this.edit.model.t_wasteTypeId) {
                    this.setWasteTypeDetails(this.edit.model.t_wasteTypeId);
                    this.prepareFATDataSource(this.edit.model.t_workCenterId, this.edit.model.t_recipientId, this.edit.model.t_transporterId,
                        this.edit.model.t_wasteTypeId, this.edit.model.t_carId, this.edit.model.t_tableDriverId);
                }
            }
        });
    }

    onTransporterInitEv(event: any) {
        this.transportSelInstance = event.component;
    }

    onDestinationInitEv(event: any) {
        this.destinationInstance = event.component;
    }

    onActionListChange(e: any, dropdown) {
        if (e.addedItems.length) {
            dropdown.instance.close();
        }
    }

    onWasteChangeEv(event: any) {
        if (event.event && event.value) {
            this.prepareFATDataSource(this.edit.model.t_workCenterId, this.edit.model.t_recipientId, this.edit.model.t_transporterId,
                this.edit.model.t_wasteTypeId, this.edit.model.t_carId, this.edit.model.t_driverTableId);
            this.setWasteTypeDetails(event.value);

            // if (this.edit.model.generatorId) {
            //     this.ds.checkQuantityForGenerator(this.edit.model.wc_parentId, event.value).then(resolve => {
            //         this.noFatQtyCollected = resolve[0].f1;
            //     });
            // }
        }
    }

    setWasteTypeDetails(id: string) {
        this.ds.getWasteTypeById(id).then(dr => {
            if (dr) {
                this.edit.model.wt_hazard = dr.hazard;
                this.edit.model.wt_hazardName = DataService.getHarzardName(dr.hazard);
                this.edit.model.wt_code = dr.code;
                this.edit.model.wt_name = dr.name;
                this.edit.model.uom = DataService.getUOM(dr.uom);
                this.edit.model.wt_physicalCondition = dr.physicalCondition;
                this.edit.model.t_physicalCondition = dr.physicalCondition;
            }
        });
    }

    onEntityChangeEv(event: any) {
        if (event.event) {
            // this.ds.resetValidation(this.workCenterInst);
            this.ds.resetValidation(this.wasteCodeInst);
            this.edit.model.t_wasteTypeId = null;
            // this.wtDisable = true;
        }
        if (event.value) {
            // this.wcDisable = false;
            this.prepareFATDataSource(this.edit.model.t_workCenterId, this.edit.model.t_recipientId, this.edit.model.t_transporterId,
                this.edit.model.t_wasteTypeId, this.edit.model.t_carId, this.edit.model.t_driverTableId);
            // this.dsWorkCenter = this.ds.getPartyMemberById(event.value);

            if (this.edit.model.t_wasteTypeId) {
                this.ds.checkQuantityForGenerator(event.value, this.edit.model.t_wasteTypeId).then(resolve => {
                    this.noFatQtyCollected = resolve[0].f1;
                });
            }
        }
    }

    onFatChanged = (ev: any) => {
        if (ev.selectedItem) {
            this.edit.model.fatQtyLeft = ev.selectedItem.c_quantity ? (ev.selectedItem.ta_approvedQuantity - ev.selectedItem.c_quantity)
                : ev.selectedItem.ta_approvedQuantity;
        }
    }

    onWorkCenterChangeEv(event: any) {
        if (event.event && event.value) {
            this.edit.model.wt_code = null;
            this.edit.model.t_quantity = null;
            this.edit.model.t_wasteTypeId = null;
            this.prepareFATDataSource(event.value, this.edit.model.t_recipientId, this.edit.model.t_transporterId,
                this.edit.model.t_wasteTypeId, this.edit.model.t_carId, this.edit.model.t_driverTableId);
        }
    }

    onTransporterChangeEv(event: any) {
        if (event.value) {
            if (this.lastTransId !== event.value) {
                this.lastTransId = event.value;
                this.edit.model.t_carNumber = null;
                this.edit.model.t_driverName = null;

                this.dsDriver = this.ds.getDriverByTransporterId(event.value);

                this.dsCar = this.ds.getCarByTransporterId(event.value);
            }
            if (this.edit.model.carId == null) {
                this.dsCar.load().then(data => {
                    if (data && data.data && data.data.length > 0) {
                        this.edit.model.t_carId = data.data[0].id;
                    }
                });
            }
            if (this.edit.model.driverId == null) {
                this.dsDriver.load().then(data => {
                    if (data && data.data && data.data.length > 0) {
                        this.edit.model.t_driverTableId = data.data[0].id;
                    }
                });
            }
            this.prepareFATDataSource(this.edit.model.t_workCenterId, this.edit.model.t_recipientId, this.edit.model.t_transporterId,
                this.edit.model.t_wasteTypeId, this.edit.model.t_carId, this.edit.model.t_driverTableId);
        }
    }

    vCodeValidationCallback = (options) => {
        const elimination = this.formInstance.getEditor('eliminationId').option('value');
        const capital = this.formInstance.getEditor('capitalizationId').option('value');
        return (elimination || capital) ? true : false;
    }

    onFormInit(ev: any) {
        this.formInstance = ev.component;
    }

    vQuantityValidationCallback = (options) => {
        const q = this.formInstance.getEditor('t_quantity').option('value');
        if (q <= 0 || (!this.edit.model.id && this.edit.model.fatQtyLeft < this.edit.model.t_quantity)) {
            return false;
        } else {
            return true;
        }
    }
    vFatValidationCallbackEv = (options) => {
        if (this.edit.model.wt_hazard === 1 && this.edit.model.t_quantity >= 1000 && !this.edit.model.t_approvalId) {
            return false;
        } else if ((parseInt(this.noFatQtyCollected, 10) + this.edit.model.t_quantity) >= 1000 &&
            this.edit.model.wt_hazard === 1 && !this.edit.model.t_approvalId) {
            return false;
        } else {
            return true;
        }
    }
}

