import { Injectable } from '@angular/core';
import { iBisEditService } from '@dohu/ibis-common';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { DataService } from '../../../service/data.service';

@Injectable()
export class EditTransporterService extends iBisEditService {
    constructor(public ds: DataService) {
        super(ds.lg);
    }
    createDefault() {
        return {};
    }
    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (!data.p_id) { reject(); }
            EntityStore.fromQuery(new EntityQuery('TransporterView').eq('p.id', data.p_id)).single().then((obj) => {
                if (!obj) {
                    reject();
                } else {
                    resolve(obj);
                }
            });
        });
    }
    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('TransporterView')).remove(id);
    }
    onSaveEv(): Promise<any> {
        const obj = this.model;
        const s = EntityStore.fromQuery(new EntityQuery('TransporterView'));
        return (this.model.id ? s.update(this.model.id, obj) : s.insert(obj));
    }
    reset(): void {
        this.model = this.createDefault();
    }
}
