import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';
import { EditPartyService } from './editParty.service';
import { EditPartyWorkCenterService } from './editPartyWorkCenter.service';
import { ActivatedRoute } from '@angular/router';
import { EditPartyLinkService } from './editPartyLink.service';

@Component({
    templateUrl: 'party.component.html',
    providers: [EditPartyWorkCenterService]
})
export class PartyComponent implements OnInit {
    dsBoolean: { id: any; name: any; }[];
    dsParty: CustomStore;
    dsContractType: CustomStore;

    display: any;
    partyType: any;
    allowEditContractor: boolean;

    parentId: any;
    onCustomizeColumnsEvent: any;
    onDeletePartyClick: any;
    onEditLinkClick: any;
    onEditPartyClick: any;
    onEditDetailsPartyClick: any;

    @ViewChild('gridWC') gridWC: DxDataGridComponent;
    @ViewChild('gridLink') gridLink: DxDataGridComponent;
    @ViewChild('gridParty') gridParty: DxDataGridComponent;

    constructor(public ds: DataService, public ui: UIService, public activeRoute: ActivatedRoute,
        public edit: EditPartyService, public editWC: EditPartyWorkCenterService, public editLK: EditPartyLinkService) {
        this.onEditLinkClick = this.onEditLink.bind(this);
        this.onEditPartyClick = this.onEditParty.bind(this);
        this.onDeletePartyClick = this.onDeleteParty.bind(this);
        this.onEditDetailsPartyClick = this.onEditDetailsParty.bind(this);

        this.activeRoute.queryParams.subscribe(params => {
            this.display = params['display'];
            this.partyType = params['partyType'];
            if (this.partyType !== null) {
                if (typeof this.partyType === 'object') {
                    const activity = [];
                    for (const t of this.partyType) {
                        activity.push(this.ds.static.activityTypeKeys[t]);
                    }
                    this.dsParty = this.ds.getPartyView('trt', false, activity);
                } else {
                    this.dsParty = this.ds.getPartyView('trt', false, this.ds.static.activityTypeKeys[this.partyType]);
                }
                this.dsContractType = this.ds.getEnumValue(this.ds.static.contractTypeKeys[this.partyType]);
            } else {
                this.dsParty = this.ds.getPartyView('trt', false);
                this.dsContractType = this.ds.getEnumValue('Contract');
            }
            this.onCustomizeColumnsEvent = (c) => { this.ui.onCustomizeColumns(c, this.display); };
        });
    }

    ngOnInit() {
        this.dsBoolean = this.ds.boolean();
        this.allowEditContractor = true;
    }

    showForGenerate = () => {
       return this.partyType.indexOf(0) > -1;
    }

    onEditLink(e: any) {
        e.event.preventDefault();
        this.editLK.showPopup(e.row.data.l_id, true).then(() => this.gridLink.instance.refresh());
    }

    onEditParty(e: any) {
        e.event.preventDefault();
        this.edit.showPopup(e.row.data.p_id, true).then(() => this.gridParty.instance.refresh());
    }

    onDeleteParty(e: any) {
        e.event.preventDefault();
        this.edit.remove(e.row.data.p_id).then(() => {
            notify('Success', 'success', 2000);
            this.gridParty.instance.refresh();
        });
    }

    partyToolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: this.ds.lg.format('ADD_PARTNER', null), icon: 'assets/img/grid_add.svg', onClick: () =>
                    this.edit.showPopup(null).then(() => this.gridParty.instance.refresh())
            },
            location: 'after'
        });
    }

    // master detail
    onWCInit(e: any, id: string) {
        if (e.component.option('dataSource') == null) {
            e.component.option('dataSource', this.ds.getWorkCenterByParty(id, true));
            this.parentId = id;
        }
    }

    onCTRInit(e: any, id: string) {
        if (e.component.option('dataSource') == null) {
            const ds = this.ds.getContractView(this.partyType === null ? null : this.ds.static.contractTypeKeys[this.partyType], id);
            e.component.option('dataSource', ds);
            this.parentId = id;
        }
    }

    onLKInit(e: any, id: string) {
        if (e.component.option('dataSource') == null) {
            e.component.option('dataSource', this.ds.getPartyLink(id));
            this.parentId = id;
        }
    }

    onEditDetailsParty(e: any) {
        e.event.preventDefault();
        this.editWC.showPopup(e.row.data.p_id, true).then(() => this.gridWC.instance.refresh());
    }

    wcToolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, false);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: this.ds.lg.format('ADD_WORKSTATION', null), icon: 'assets/img/grid_add.svg', onClick: () => {
                    const newWC = this.editWC.createDefault();
                    newWC.parentId = this.parentId;
                    this.editWC.showPopup(newWC).then(() => event.component.refresh());
                }
            },
            location: 'after'
        });
    }

    lkToolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, false);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: this.ds.lg.format('ADD', null), icon: 'assets/img/grid_add.svg', onClick: () => {
                    const newLK = this.editLK.createDefault();
                    newLK.senderId = this.parentId;
                    this.editLK.showPopup(newLK).then(() => event.component.refresh());
                }
            },
            location: 'after',
        });
    }

    onCarInit(event: any, id: string) {
        if (event.component.option('dataSource') == null) {
            const ds = this.ds.getCarByTransporterId(id);
            event.component.option('dataSource', ds);
        }
        this.parentId = id;
    }

    onNewCarInsert(event: any) {
        event.data.transporterId = this.parentId;
    }

    onDriverInit(event: any, id: string) {
        if (event.component.option('dataSource') == null) {
            const ds = this.ds.getDriverByTransporterId(id);
            event.component.option('dataSource', ds);
        }
        this.parentId = id;
    }

    onNewDriverInsert(event: any) {
        event.data.transporterId = this.parentId;
    }


}
