import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { DxSelectBoxComponent } from 'devextreme-angular';
import { EditPartyService } from '../../admin/party/editParty.service';
import { UIService } from '../../../service/ui.service';
import { EditTransportService } from './editTransport.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-edit-transport',
    templateUrl: 'editTransport.component.html'
})
export class EditTransportComponent implements OnInit {
    dsEntity: CustomStore;
    dsWorkCenter: CustomStore;
    dsTransportTypes: CustomStore;
    dsValidTransports: CustomStore;
    dsDestinationTypes: CustomStore;
    dsValidDest: DataSource;
    dsWasteType: DataSource;
    onWasteChange: any;
    onWorkCenterChange: any;
    wasteCodeInst: any;
    workCenterInst: any;
    onWorkCenterInit: any;
    onWasteCodeInit: any;
    onDestinationInit: any;

    wtDisable = true; wcDisable = true;
    transHelpText: string;
    wasteCodeHelpText: string;
    openPartyDestAdd: any;
    transportSelInstance: any;
    destinationInstance: any;
    dspEntities: CustomStore;
    dsTransportApproval: any;
    wasteType: any;

    constructor(public ds: DataService, public edit: EditTransportService, public editParty: EditPartyService, public ui: UIService,
        public activeRoute: ActivatedRoute) {
        this.dsEntity = this.ds.getParty('ent');
        this.dsTransportTypes = this.ds.getEnumValue('Transport');
        this.dsValidTransports = this.ds.validTransports();
        this.dsDestinationTypes = this.ds.getEnumValue('Destinatie');

        this.dsValidDest = this.ds.getPartyLinkViewPaginate([4], [1, 3, 4, 5]);
        this.dspEntities = this.ds.getPartyLinkView([2]);

        this.onWorkCenterInit = this.onWorkCenterInitEv.bind(this);
        this.onWasteCodeInit = this.onWasteCodeInitEv.bind(this);
        this.onDestinationInit = this.onDestinationInitEv.bind(this);

        this.onWasteChange = this.onWasteChangeEv.bind(this);
        this.onWorkCenterChange = this.onWorkCenterChangeEv.bind(this);
        this.openPartyDestAdd = this.openPartyAddEv.bind(this, 1);
        this.transHelpText = this.ds.lg.format('ADD_PARTNER_TRANSPORT', null);

        this.activeRoute.queryParams.subscribe(params => {
            this.wasteType = params['wasteType'];
        });
    }

    ngOnInit() {
        if (this.edit.model.wc_parentId && this.edit.model.wc_parentId.length) {
            this.dsWorkCenter = this.ds.getPartyMemberById(this.edit.model.wc_parentId);
            this.wtDisable = false;
        } else {
            this.dsWorkCenter = this.ds.getPartyMemberById(null);
        }
        if (this.edit.model.t_workCenterId) {
            this.dsWasteType = this.ds.setWasteCodeDataSourceForWorkCenter(this.edit.model.t_workCenterId, null, this.edit.wasteType ? this.edit.wasteType : null);
            if (this.edit.wasteType && this.edit.wasteType == '2') {
                this.edit.dsEEECategory = this.ds.getEnumValue('EEE');
            }
        }
    }

    onWorkCenterInitEv(event: any) {
        this.workCenterInst = event.component;
    }

    onWasteCodeInitEv(event: any) {
        this.wasteCodeInst = event.component;
    }

    openPartyAddEv(event: any, type: number) {
        switch (type) {
            case 0:
                this.editParty.showPopup(null).then(() => {
                    if (this.transportSelInstance) {
                        this.transportSelInstance.getDataSource().reload();
                    }
                });
                break;
            case 1:
                this.editParty.showPopup(null).then(() => {
                    if (this.destinationInstance) {
                        this.destinationInstance.getDataSource().reload();
                    }
                });
                break;
        }
        event.event.preventDefault();
        event.event.stopPropagation();
    }

    onTransporterInitEv(event: any) {
        this.transportSelInstance = event.component;
    }

    onDestinationInitEv(event: any) {
        this.destinationInstance = event.component;
    }

    onActionListChange(e: any, dropdown) {
        if (e.addedItems.length) {
            dropdown.instance.close();
        }
    }

    onWasteChangeEv(event: any) {
        if (event.event) {
            const items = this.wasteCodeInst.getDataSource().items();
            const item = items.filter(x => x.id === event.value);
            this.edit.dsMaterial = this.ds.getEnumValue(item[0].code);
            const hazard = item[0].hazard;
            this.setWasteInfo(hazard, item[0].name, item[0].uom);
            if (this.edit.model.t_wasteTypeId != undefined && this.edit.model.wt_hazard == 1 && this.edit.model.t_workCenterId != undefined &&
                this.edit.model.t_recipientId != undefined && this.edit.model.t_transporterId != undefined) {
                this.prepareFATDataSource(this.edit.model.t_workCenterId, this.edit.model.t_recipientId, this.edit.model.t_transporterId, this.edit.model.t_wasteTypeId);
            }
        }
    }

    onTransporterChangeEv(event: any) {
        if (event.event) {
            if (this.edit.model.t_wasteTypeId != undefined && this.edit.model.wt_hazard == 1 && this.edit.model.t_workCenterId != undefined &&
                this.edit.model.t_recipientId != undefined && this.edit.model.t_transporterId != undefined) {
                this.prepareFATDataSource(this.edit.model.t_workCenterId, this.edit.model.t_recipientId, this.edit.model.t_transporterId, this.edit.model.t_wasteTypeId);
            }
        }
    }

    onDestinationChangeEv(event: any) {
        if (event.event) {
            if (this.edit.model.t_wasteTypeId != undefined && this.edit.model.wt_hazard == 1 && this.edit.model.t_workCenterId != undefined &&
                this.edit.model.t_recipientId != undefined && this.edit.model.t_transporterId != undefined) {
                this.prepareFATDataSource(this.edit.model.t_workCenterId, this.edit.model.t_recipientId, this.edit.model.t_transporterId, this.edit.model.t_wasteTypeId);
            }
        }
    }

    setWasteInfo(hazard = null, wt_name = null, uom = null) {
        this.edit.model.wt_hazard = hazard;
        this.edit.model.wt_hazardName = DataService.getHarzardName(hazard);
        this.edit.model.uom = DataService.getUOM(uom);
        this.edit.model.wt_name = wt_name;
        this.edit.model.wt_uom = uom;
    }

    onWorkCenterChangeEv(event: any) {
        if (event.event) {
            if (this.wasteCodeInst && this.edit.wasteType !== '2') {
                if (this.wasteCodeInst.getDataSource().items() === 0) {
                    this.wasteCodeHelpText = this.ds.lg.format('WASTE_ASSOCIATED', null);
                }
                this.ds.resetValidation(this.wasteCodeInst);
            }
            if (this.edit.model.t_wasteTypeId != undefined && this.edit.model.wt_hazard == 1 && this.edit.model.t_workCenterId != undefined &&
                this.edit.model.t_recipientId != undefined && this.edit.model.t_transporterId != undefined) {
                this.prepareFATDataSource(this.edit.model.t_workCenterId, this.edit.model.t_recipientId, this.edit.model.t_transporterId, this.edit.model.t_wasteTypeId);
            }
        }
        if (event.value) {
            this.wtDisable = false;

            this.dsWasteType = this.ds.setWasteCodeDataSourceForWorkCenter(event.value, null, this.edit.wasteType ? this.edit.wasteType : null);
        }
    }

    customTransportApprovalDisplay(rowInfo: any) {
        if (rowInfo) {
            const str = 'Nr.: ' + rowInfo.ta_no
                + ' - Cant.: ' + (rowInfo.t_quantity ? rowInfo.ta_approvedQuantity - rowInfo.t_quantity : rowInfo.ta_approvedQuantity)
                + ' - Nr.tr.: ' + (rowInfo.t_no ? rowInfo.ta_approvedNo - rowInfo.t_no : rowInfo.ta_approvedNo);
            return (this && this.edit && this.edit.model.id) ? ('Nr.: ' + rowInfo.ta_no) : str;
        }
        return '';
    }


    prepareFATDataSource(generatorId: string, destinationId: string, transporterId: string, wasteTypeId: string, carId?: string, driverId?: string) {
        this.ds.getTransportApprovalView(generatorId, destinationId, transporterId, wasteTypeId, carId, driverId).load().then(data => {
            const ds = data.data;
            if (this.edit.model.id) {
                this.dsTransportApproval = ds;
            } else {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                for (let i = 0; i < ds.length; i++) {
                    const expireDate = new Date(ds[i].ta_expireDate);
                    if (ds[i].t_no && (ds[i].t_no <= 0 || ds[i].t_no >= ds[i].ta_approvedNo) ||
                        (ds[i].t_quantity && (ds[i].t_quantity <= 0 || ds[i].t_quantity >= ds[i].ta_approvedQuantity)) ||
                        (expireDate < today)) {
                        ds.splice(i, 1);
                        i--;
                    }
                }
                // if ((ds[i].c_no && (ds[i].c_no <= 0 || ds[i].c_no >= ds[i].ta_approvedNo)) ||
                // (ds[i].c_quantity && (ds[i].c_quantity <= 0 || ds[i].c_quantity >= ds[i].ta_approvedQuantity)) ||
                // (ds[i].ta_expireDate > today)
                this.dsTransportApproval = ds;
            }
        });
    }
}
