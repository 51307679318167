import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';
import { EditTreatmentService } from './editTreatment.service';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-treatment',
    templateUrl: './treatment.component.html'
})

export class TreatmentComponent implements OnInit {

    dsTreatmentOut: CustomStore;
    onEditTreatmentClick: any;
    onDeleteTreatmentClick: any;

    @ViewChild('gridTreatmentOut') gridTreatmentOut: DxDataGridComponent;
    dsTreatmentType: { id: number; value: string; }[];
    constructor(public ds: DataService, public ui: UIService, public edit: EditTreatmentService) {
        this.onEditTreatmentClick = this.onEditTreatmentClickEv.bind(this);
        this.onDeleteTreatmentClick = this.onDeleteTreatmentClickEv.bind(this);
    }

    ngOnInit() {

        this.dsTreatmentOut = this.ds.getTreatment(true);
        this.dsTreatmentType = this.ds.getTreatmentType('eliminare');
    }

    mainToolbarPreparing(ev: any) {
        this.ui.prepareToolbar(ev, true);
        ev.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: 'Adaugă tratare',
                icon: 'plus',
                onClick: (e: any) => this.edit.showPopup(null).
                    then(() => this.gridTreatmentOut.instance.refresh(), () => { })
            },
            location: 'after',
        });
    }

    onEditTreatmentClickEv(ev: any) {
        ev.event.preventDefault();
        this.edit.showPopup(ev.row.data, true).then(
            () => this.gridTreatmentOut.instance.refresh(),
            (error) => this.ds.serverError(error)
        );
    }

    onDeleteTreatmentClickEv(ev: any) {
        ev.event.preventDefault();
        confirm('Ești sigur că vrei să ștergi această înregistrare ?', 'Confirmare').then(val => {
            if (val) {
                this.edit.onRemove(ev.row.data.t_id).then(() => {
                    notify('Success', 'success', 2000);
                    this.gridTreatmentOut.instance.refresh();
                }, err => {
                    this.ds.serverError(err);
                });
            }
        });
    }
}
