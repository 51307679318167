import { Component, Input } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { EditGeographicareaService } from './edit-geographicarea.service';
import DataSource from 'devextreme/data/data_source';


@Component({
    selector: 'app-edit-garea',
    templateUrl: 'editGarea.component.html'
})
export class EditGareasComponent {

    dsParent: DataSource;
    onParentInit: any;

    constructor(public ds: DataService, public edit: EditGeographicareaService) {
        this.dsParent = new DataSource({ store: this.ds.geographicAreas(null), paginate: true, pageSize: 50});
        this.onParentInit = (ev) => { this.onParentInitEv(ev, this); };
    }

    onTypeChangeEv = (event) => {
        if (event.event) {
            switch (event.value) {
                case 1:
                    this.edit.model.parentId = null;
                    this.dsParent = new DataSource({ store: this.ds.geographicAreas(2), paginate: true, pageSize: 50});
                    break;
                case 2:
                    this.edit.model.parentId = null;
                    this.dsParent = new DataSource({ store: this.ds.geographicAreas(3), paginate: true, pageSize: 50});
                    break;
                case 3:
                    this.dsParent = null;
                    break;
            }
        }
    }

    onParentInitEv(event, that) {
        that.parentComponent = event;
    }
}
