import { Routes } from '@angular/router';

import { LoginComponent } from './view/login/login.component';
import { GenerateComponent } from './view/waste/generate/generate.component';
import { EliminateComponent } from './view/waste/eliminate/eliminate.component';
import { TaxComponent } from './view/document/tax/tax.component';
import { ControlComponent } from './view/waste/control/control.component';
import { AlertComponent } from './view/alert/alert.component';
import { ReportExtComponent } from './view/report/reportExt.component';
import { ReportIntComponent } from './view/report/reportInt.component';
import { AgencyComponent } from './view/admin/agency/agency.component';
import { PartyComponent } from './view/admin/party/party.component';
import { WastetypeComponent } from './view/admin/wastetype/wastetype.component';
import { UnitComponent } from './view/admin/unit/unit.component';
import { CapitalizationComponent } from './view/waste/capitalization/capitalization.component';
import { iBisAuthService, iBisConfirmInviteComponent, iBisResetPasswordComponent } from '@dohu/ibis-auth';
import { MonthlyComponent } from './view/waste/monthly/monthly.component';
import { UserComponent } from './view/admin/user/user.component';
import { GeographicareaComponent } from './view/admin/geographicarea/geographicarea.component';
import { ArchiveComponent } from './view/document/archive/archive.component';
import { PermitComponent } from './view/document/permit/permit.component';
import { InvoiceComponent } from './view/document/invoice/invoice.component';
import { WastefileComponent } from './view/document/wastefile/wastefile.component';
import { NomenclatureComponent } from './view/admin/nomenclature/nomenclature.component';
import { RegisterComponent } from './view/register/register.component';

import { AccountComponent } from './view/account/account.component';
import { TransferComponent } from './view/waste/transfer/transfer.component';
import { DashboardComponent } from './view/analize/dashboard/dashboard.component';
import { TaxAnalyzesComponent } from './view/analize/tax-analyzes/tax-analyzes.component';
import { ControlAnalyzesComponent } from './view/analize/control-analyzes/control-analyzes.component';
import { IncomeAnalyzesComponent } from './view/analize/income-analyzes/income-analyzes.component';
import { CostAnalyzesComponent } from './view/analize/cost-analyzes/cost-analyzes.component';
import { CapitalizationAnalyzesComponent } from './view/analize/capitalization-analyzes/capitalization-analyzes.component';
import { AnalizeComponent } from './view/analize/analize.component';
import { ContractComponent } from './view/document/contract/contract.component';
import { TreatmentComponent } from './view/waste/treatment/treatment.component';
import { TreatmentItemComponent } from './view/waste/treatment/treatmentItem.component';
import { ProjectComponent } from './view/document/project/project.component';
import { CollectComponent } from './view/waste/collect/collect.component';
import { TransportApprovalComponent } from './view/document/transportApproval/transportApproval.component';
import { IncinerateComponent } from './view/waste/incinerate/incinerate.component';
import { FullTransportComponent, TransportComponent } from './view/waste/transport/index';
import { AuditComponent } from './view/admin/audit/audit.component';
import { ActualStockComponent } from './view/waste/actualStock/actualStock.component';
import { GeneralReportComponent } from './view/report/generalReport.component';
import { ScaleComponent } from './view/waste/scale/scale.component';
import { InvoiceItemComponent } from './view/document/invoice-item/invoice-item.component';
import { MirrorStockComponent } from './view/waste/mirrorStock/mirrorStock.component';

export const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {
        path: 'analize', component: AnalizeComponent, canActivate: [iBisAuthService],
        children: [
            { path: 'dashboard', component: DashboardComponent, canActivate: [iBisAuthService] },
            { path: 'taxe', component: TaxAnalyzesComponent, canActivate: [iBisAuthService] },
            { path: 'controale', component: ControlAnalyzesComponent, canActivate: [iBisAuthService] },
            { path: 'venituri', component: IncomeAnalyzesComponent, canActivate: [iBisAuthService] },
            { path: 'costuri', component: CostAnalyzesComponent, canActivate: [iBisAuthService] },
            { path: 'valorificare', component: CapitalizationAnalyzesComponent, canActivate: [iBisAuthService] }
        ]
    },
    {
        path: 'waste',
        children: [
            { path: 'generate', component: GenerateComponent, canActivate: [iBisAuthService] },
            { path: 'transport', component: TransportComponent, canActivate: [iBisAuthService] },
            { path: 'fullTransport', component: FullTransportComponent, canActivate: [iBisAuthService] },
            { path: 'transfer', component: TransferComponent, canActivate: [iBisAuthService] },
            { path: 'eliminare', component: EliminateComponent, canActivate: [iBisAuthService] },
            { path: 'valorificare', component: CapitalizationComponent, canActivate: [iBisAuthService] },
            { path: 'treatmentOut', component: TreatmentComponent, canActivate: [iBisAuthService] },
            { path: 'treatmentIn', component: TreatmentItemComponent, canActivate: [iBisAuthService] },
            { path: 'collect', component: CollectComponent, canActivate: [iBisAuthService] },
            { path: 'incinerate', component: IncinerateComponent, canActivate: [iBisAuthService] },
            { path: 'actualStock', component: ActualStockComponent, canActivate: [iBisAuthService] },
            { path: 'scale', component: ScaleComponent, canActivate: [iBisAuthService] },
            { path: 'mirrorStock', component: MirrorStockComponent, canActivate: [iBisAuthService] }

        ]
    },
    {
        path: 'packing',
        children: [
            { path: 'generate', component: GenerateComponent, canActivate: [iBisAuthService] },
            { path: 'transport', component: TransportComponent, canActivate: [iBisAuthService] },
            { path: 'transfer', component: TransferComponent, canActivate: [iBisAuthService] },
            { path: 'eliminare', component: EliminateComponent, canActivate: [iBisAuthService] },
            { path: 'valorificare', component: CapitalizationComponent, canActivate: [iBisAuthService] },
        ]
    },
    {
        path: 'eee',
        children: [
            { path: 'generate', component: GenerateComponent, canActivate: [iBisAuthService] },
            { path: 'transport', component: TransportComponent, canActivate: [iBisAuthService] },
            { path: 'transfer', component: TransferComponent, canActivate: [iBisAuthService] },
            { path: 'eliminare', component: EliminateComponent, canActivate: [iBisAuthService] },
            { path: 'valorificare', component: CapitalizationComponent, canActivate: [iBisAuthService] },
        ]
    },
    {
        path: 'document',
        children: [
            { path: 'archive', component: ArchiveComponent, canActivate: [iBisAuthService] },
            { path: 'contract', component: ContractComponent, canActivate: [iBisAuthService] },
            { path: 'control', component: ControlComponent, canActivate: [iBisAuthService] },
            { path: 'permit', component: PermitComponent, canActivate: [iBisAuthService] },
            { path: 'wastefile', component: WastefileComponent, canActivate: [iBisAuthService] },
            { path: 'invoice', component: InvoiceComponent, canActivate: [iBisAuthService] },
            { path: 'invoice-item', component: InvoiceItemComponent, canActivate: [iBisAuthService] },
            { path: 'tax', component: TaxComponent, canActivate: [iBisAuthService] },
            { path: 'client', component: ContractComponent, canActivate: [iBisAuthService] },
            { path: 'supplier', component: ContractComponent, canActivate: [iBisAuthService] },
            { path: 'project', component: ProjectComponent, canActivate: [iBisAuthService] },
            { path: 'fat', component: TransportApprovalComponent, canActivate: [iBisAuthService] },
            { path: 'transporter', component: ContractComponent, canActivate: [iBisAuthService] }
        ]
    },
    {
        path: 'report',
        children: [
            {
                path: 'reportExt',
                children: [
                    { path: 'generic', component: ReportExtComponent, canActivate: [iBisAuthService] },
                    { path: 'electrics', component: ReportExtComponent, canActivate: [iBisAuthService] },
                    { path: 'batery', component: ReportExtComponent, canActivate: [iBisAuthService] },
                    // { path: 'extractive', component: ReportExtComponent },
                    { path: 'vehicles', component: ReportExtComponent, canActivate: [iBisAuthService] },
                    // { path: 'pcbpct', component: ReportExtComponent },
                    { path: 'oils', component: ReportExtComponent, canActivate: [iBisAuthService] },
                    // {  path: 'mud', component: ReportExtComponent }
                    { path: 'packing', component: ReportExtComponent, canActivate: [iBisAuthService] }
                ]
            },
            { path: 'reportInt', component: ReportIntComponent },
            { path: 'reportGen', component: GeneralReportComponent },
            { path: 'monthly', component: MonthlyComponent, canActivate: [iBisAuthService] }
        ]
    },
    {
        path: 'admin',
        children: [
            { path: 'agency', component: AgencyComponent, canActivate: [iBisAuthService] },
            { path: 'nomenclatures', component: NomenclatureComponent, canActivate: [iBisAuthService] },
            { path: 'party', component: PartyComponent, canActivate: [iBisAuthService] },
            { path: 'wastetype', component: WastetypeComponent, canActivate: [iBisAuthService] },
            { path: 'units', component: UnitComponent, canActivate: [iBisAuthService] },
            { path: 'users', component: UserComponent, canActivate: [iBisAuthService] },
            { path: 'geographicareas', component: GeographicareaComponent, canActivate: [iBisAuthService] },
            { path: 'audit', component: AuditComponent, canActivate: [iBisAuthService] }
        ]
    },
    { path: 'account', component: AccountComponent },
    { path: 'alert', component: AlertComponent },
    { path: 'login', component: LoginComponent, data: [{ returnUrl: '/waste/generate/general' }] },
    { path: 'register', component: RegisterComponent },
    {
        path: 'confirmInvite',
        component: iBisConfirmInviteComponent,
        data: [{ returnUrl: '/login' }]
    },
    {
        path: 'resetPassword',
        component: iBisResetPasswordComponent,
        data: [{ title: 'Resetează parola', isReset: true, returnUrl: '/login' }]
    }
];
