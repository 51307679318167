import { Component, EventEmitter, Input, NgModule, OnInit, Output, ViewChild } from '@angular/core';
import { EntityStore } from '@dohu/ibis-entity';
import { DxFormComponent, DxFormModule, DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { environment } from '../../../environments/environment';
import { DataService } from '../../service/data.service';
import { UIService } from '../../service/ui.service';

@Component({
    selector: 'app-create-ticket',
    templateUrl: './create-ticket.component.html'
})
export class CreateTicketComponent implements OnInit {
    @Input() popupVisible: boolean;
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onClosePopup = new EventEmitter<any>();
    onSendTicket: any;
    errorDetails: {};
    @ViewChild('formTicket') formTicket: DxFormComponent;
    constructor(public ui: UIService, public ds: DataService) {
        this.onSendTicket = this.onSendTicketEv.bind(this);
    }

    ngOnInit() {
    }

    onSendTicketEv(event: any) {
        const validation = event.validationGroup.validate();
        if (!validation.isValid) {
            notify(this.ds.lg.format('ALL_REQ_FIELDS', null), 'error', 3000);
            return;
        }
        EntityStore.execute('AddSupportItem', this.errorDetails, environment.saasServer).then((result) => {
            if (result === 'OK') {
                notify(this.ds.lg.format('PROBLEM_PICK_UP_SUPPORT', null), 'success', 5000);
                this.onHiddenPopup();
            }
        }, err => {
            this.ds.serverError(err);
        });
    }

    onHiddenPopup(e?: any) {
        this.errorDetails = {};
        this.formTicket.instance.resetValues();
        this.onClosePopup.emit(true);
    }
}
@NgModule({
    imports: [DxPopupModule, DxScrollViewModule, DxFormModule],
    declarations: [CreateTicketComponent],
    exports: [CreateTicketComponent]
})
export class CreateTicketModule { }
