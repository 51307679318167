import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { UIService } from '../../../service/ui.service';
import { AnalizeService } from '../analize.service';

@Component({
  selector: 'app-capitalization-analyzes',
  templateUrl: './capitalization-analyzes.component.html'
})
export class CapitalizationAnalyzesComponent implements OnInit {

  constructor(public ds: DataService, public ui: UIService, public analyze: AnalizeService) { }

  ngOnInit() { }

  tvPercent(row: any) {
    return (row.vt / row.quantity * 100).toFixed(2) + '%'
  }

  toolbarPreparing(event: any) {
    this.ui.prepareToolbar(event, true);
  }

}
