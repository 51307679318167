import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';
import CustomStore from 'devextreme/data/custom_store';
import {  EntityStoreOptions } from '@dohu/ibis-entity';
import { iBisFileService } from '@dohu/ibis-common';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'app-mirror-stock',
  templateUrl: './mirrorStock.component.html'
})
export class MirrorStockComponent implements OnInit {

  filterDate: Date;
  storeOptions: any;
  dsMirrorStockIn: CustomStore;
  dsMirrorStockOut: CustomStore;

  @ViewChild('gridMirrorStockIn') grid: DxDataGridComponent;
  constructor(public ds: DataService, public ui: UIService, public file: iBisFileService) {
    this.filterDate = new Date();
    this.storeOptions = this.ds.getMirrorStockInOptions();
    this.dsMirrorStockIn = this.storeOptions;
  }

  ngOnInit() {
  }

  onRowExpandingEv(event: any) {
    event.component.collapseAll(-1);
      this.dsMirrorStockOut = this.ds.getMirrorStockOut(event.key, this.filterDate);
  }

  onStockClick(e: any) {
    if (e.rowType === 'data') {
      if (e.cellElement.classList[0] === 'dx-command-expand' &&
        (e.data.lastDate == undefined && e.data.lastDate == null)) {
        e.component.collapseAll(-1);
      }
    }
  }

  onMirrorStockInCellPreparedEv(event: any) {
    if (event.rowType == 'data' && event.column && event.column.type == 'detailExpand' &&
      event.data && (event.data.lastDate == undefined && event.data.lastDate == null)) {
      event.cellElement.children[0].classList.remove('dx-datagrid-group-closed');
    }
  }

  onMirrorStockOutCellPreparedEv(event: any) {
    if (event.rowType == 'data' && event.row && event.row.data && event.row.data.typeId) {
      switch (event.row.data.typeId) {
        case 3: // incinerare
          event.cellElement.classList.add('dx-red');
          break;
        case 4: // predare la terti
          event.cellElement.classList.add('dx-yellow');
          break;
        case 5: // tratare/sortare Out
          event.cellElement.classList.add('dx-green');
          break;
      }
    }
  }

  getActions(type: number, actions: number) {
    if (type == 4) { // Full Transport
      const arr = this.ds.static.transportActions;
      const result = [];
      for (const i of arr) {
        // tslint:disable-next-line:no-bitwise
        if ((actions & i.id) === i.id) {
          result.push(i.value);
        }
      }
      return result.join(', ');
    } else if (type == 5) { // OUT treatment
      const dsTreatmentType = this.ds.getTreatmentType('eliminare');
      const result = dsTreatmentType.find(x => x.id = actions);
      if (result) {
        return result.value;
      }
    }
  }

  onToolBarPreparingEv(event: any) {
    this.ui.prepareToolbar(event);
    event.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'exportxlsx', hint: 'Export excel',
        onClick: (ev: any) => {
          if (ev) {
            ev.event.preventDefault();
            ev.event.stopPropagation();
          }
          var q = this.storeOptions.lastQuery();
          q.reportName = 'Oglinda';

          this.grid.instance.beginCustomLoading("Se descarca raportul");
          this.file.DownloadByPost(EntityStoreOptions.DefaultServer + 'DownloadMirrorStock', q).then(
            () => { notify(this.ds.lg.format('FILES_DOWNLOADED', null), 'success', 2000); },
            () => { notify(this.ds.lg.format('DOWNLOAD_ERROR', null), 'error', 3000); }).finally(() => this.grid.instance.endCustomLoading());
        }
      },
      location: 'after'
    });
    event.toolbarOptions.items.unshift({
      widget: 'dxDateBox',
      options: {
        value: this.filterDate,
        onValueChanged: (ev: any) => {
          if (ev.event) {
            this.filterDate = ev.value;
            this.storeOptions = this.ds.getMirrorStockInOptions(this.filterDate);
            this.dsMirrorStockIn = this.storeOptions;
            this.grid.instance.collapseAll(-1);
          }
        }
      },
      location: 'after'
    });
  }
}
