import { Component, OnInit, ViewChild } from '@angular/core';
import { UIService } from '../../../service/ui.service';
import { EditTreatmentService } from './editTreatment.service';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { EditActualStockService } from './editActualStock.service';
import { DxFormComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';

@Component({
    selector: 'app-edit-treatment',
    templateUrl: './editTreatment.component.html'
})
export class EditTreatmentComponent implements OnInit {
    dsEntities: CustomStore;
    dsWorkCenter: CustomStore;
    dsWasteType: any;
    onWorkCenterChange: any;
    onEntityChange: any;
    dsTreatmentType: { id: number; value: string; }[];
    onSaveClose: any;
    onSaveNew: any;

    @ViewChild('treatmentForm') treatmentForm: DxFormComponent;
    constructor(public ui: UIService, public edit: EditTreatmentService, public ds: DataService, public editS: EditActualStockService) {
        this.dsEntities = this.ds.getParty('ent');
        this.dsWorkCenter = this.ds.getPartyLinkView([2]);
        this.dsTreatmentType = this.ds.getTreatmentType('eliminare');

        this.onWorkCenterChange = this.onWorkCenterChangeEv.bind(this);
        this.onEntityChange = this.onEntityChangeEv.bind(this);
        this.onSaveClose = this.onSaveEv.bind(this, 2);
        this.onSaveNew = this.onSaveEv.bind(this, 1);
    }

    ngOnInit() {
        this.initComponent();
    }

    onShownTreatment(ev: any) {
        this.initComponent();
    }

    initComponent() {
        // if (this.edit.model.entityId && this.edit.model.entityId.length) {
        //     this.dsWorkCenter = this.ds.getPartyMemberById(this.edit.model.entityId);
        // }

        if (this.edit.model.workCenterId && this.edit.model.workCenterId.length) {
            this.dsWasteType = this.ds.getWasteTypeForWorkCenter(this.edit.model.workCenterId);
        }
    }

    setWasteTypeDetails(id: string) {
        this.ds.getWasteTypeById(id).then(dr => {
            if (dr) {
                this.edit.model.hazardName = DataService.getHarzardName(dr.hazard);
                this.edit.model.wasteCode = dr.code;
                this.edit.model.wasteName = dr.name;
                this.edit.model.physicalCondition = dr.physicalCondition;
                this.edit.model.uom = DataService.getUOM(dr.uom);
            }
        });
    }

    onEntityChangeEv(ev: any) {
        if (ev.value) {
            this.dsWorkCenter = this.ds.getPartyMemberById(ev.value);
            this.dsWorkCenter.load().then(dd => {
                if (dd.data.length === 1) {
                    this.edit.model.workcenterId = dd.data[0].id;
                }
            });
        }
    }

    onWorkCenterChangeEv(ev: any) {
        if (ev.value) {
            this.dsWasteType = this.ds.getWasteTypeForWorkCenter(ev.value);
            this.edit.model.wasteTypeId = undefined;
            this.edit.model.wasteCode = null;
            this.edit.model.quantity = 0;
            this.editS.dsStock = [];
        }
    }

    onWasteCodeChange = (ev: any) => {
        this.treatmentForm.instance.focus();
        // ev.value = ev.previousValue;
    }

    onOpenStockPopup = (ev: any) => {
        this.editS.showPopup({
            edit: this.edit.model.id ? true : false,
            treatmentId: this.edit.model.id,
            wasteTypeId: this.edit.model.wasteTypeId,
            workCenterId: this.edit.model.workCenterId,
            dsSelected: this.edit.dsTreatmentSource
        }, true).then(data => {
            if (data && data.isSaving) {
                this.edit.model.quantity = data.totalQuantity;
                this.edit.model.wasteTypeId = data.wasteTypeId;
                this.edit.dsTreatmentSource = data.dsSource;
                this.setWasteTypeDetails(this.edit.model.wasteTypeId);
            }
        });
    }

    onItemToolbarPreparing(ev: any) {
        ev.toolbarOptions.items.unshift({
            text: 'Rezultat',
            location: 'before'
        },
            {
                template: 'abracadabra',
                location: 'after'
            }
        );
    }

    onItemInserted(ev: any) {
        this.edit.model.totalQty = Math.round((this.edit.model.totalQty + ev.data.quantity) * Math.pow(10, 6)) / Math.pow(10, 6);
    }

    onItemRemoving(ev: any) {
        if (ev.data) {
            if (this.edit.model.id) {
                this.edit.onRemoveItem(ev.data.id);
            } else {
                const indx = this.edit.dsTreatmentItem.indexOf(ev.data);
                this.edit.dsTreatmentItem.splice(indx, 1);
            }
            this.edit.model.totalQty = Math.round((this.edit.model.totalQty - ev.data.quantity) * Math.pow(10, 6)) / Math.pow(10, 6);
        }
    }

    onQuantityUpdating(ev: any) {
        if (ev.newData.quantity < 0) {
            ev.cancel = true;
            return;
        }

        this.edit.model.totalQty = Math.round((this.edit.model.totalQty - ev.oldData.quantity + ev.newData.quantity)
            * Math.pow(10, 6)) / Math.pow(10, 6);
    }

    onSaveEv(type: number, ev: any) {
        switch (this.edit.model.typeId) {
            case 3:
                if (this.edit.model.quantity > this.edit.model.totalQty) {
                    notify('Cantitatea totală trebuie să fie mai mare sau egală cu cea a codului de deșeu!', 'error', 3000);
                    return;
                }
                break;
            case 2:
                if (this.edit.model.quantity !== this.edit.model.totalQty) {
                    notify('Cantitatea totală trebuie să fie egală cu cea a codului de deșeu!', 'error', 3000);
                    return;
                }
                break;
            default:
                return;
        }
        type === 2 ? this.edit.onSaveClose(ev) : this.edit.onSaveNew(ev);
    }
}
