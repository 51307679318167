import { Component, OnInit, ViewChild, Input } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';
import { UIService } from '../../../service/ui.service';
import { confirm } from 'devextreme/ui/dialog';
import { DataService } from '../../../service/data.service';
import { EditPermitService } from './editPermit.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-permit',
    templateUrl: 'permit.component.html',
    providers: [EditPermitService]
})
export class PermitComponent implements OnInit {
    dsPermit: CustomStore;
    pWithActiv: CustomStore;
    dsPermitsType: any;
    dsBoolean: any;

    dsReportType: any;
    onEditPermitsClick: any;
    onDeletePermitsClick: any;
    popupTitle: string;
    isSubtab: boolean;

    @Input() entityId: string;
    @ViewChild('filesGrid') filesGrid: DxDataGridComponent;
    @ViewChild('permitGrid') permitGrid: DxDataGridComponent;
    onSavePermit: any;
    qhsePermitKey: string;

    constructor(public ds: DataService, public ui: UIService, public edit: EditPermitService) {
        this.onEditPermitsClick = this.onEditPermits.bind(this);
        this.onDeletePermitsClick = this.onDeletePermits.bind(this);
        this.qhsePermitKey = 'qhsePermit';
        this.dsPermit = this.ds.getPermit();
        this.pWithActiv = this.ds.getPartyLinkView([2, 4]);

        setTimeout(() => {
            if (this.entityId) {
                this.dsPermit = this.ds.getPermit(this.entityId);
            }
        }, 0);
    }

    ngOnInit() {
        this.dsPermitsType = this.ds.static.documentType;
        this.dsBoolean = this.ds.boolean('bool');
    }

    onFilesGridInit(e: any, data: any) {
        if (e.component.option('dataSource') == null) {
            e.component.option('dataSource', this.ds.getFileInfo(data.data.id));
        }
    }

    onEditPermits(e: any) {
        e.event.preventDefault();
        e.row.data.isEntityHidden = this.isSubtab;
        e.row.data.entityId = this.entityId;
        this.edit.showPopup(e.row.data, true).then(() => { this.permitGrid.instance.refresh(); });
    }

    onDeletePermits(e: any) {
        e.event.preventDefault();
        confirm(this.ds.lg.format('DELETE_RECORD', null), this.ds.lg.format('CONFIRM', null)).then(val => {
            if (val) {
                this.edit.onRemove(e.row.data.id).then(() => { this.permitGrid.instance.refresh(); });
            }
        });
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                icon: 'assets/img/grid_add.svg', hint: this.ds.lg.format('ADD_ROW', null), onClick: (e: any) => {
                    this.edit.showPopup({ isEntityHidden: this.isSubtab, entityId: this.entityId ? this.entityId : undefined, isValid: true }).then(
                        () => {
                            this.permitGrid.instance.refresh();
                        });
                }
            },
            location: 'after'
        });
    }
}
