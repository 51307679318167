import { Component, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';
import { EditUnitWasteService } from '../../admin/unit/editUnitWaste.service';
import { EditGenerateService } from './editGenerate.service';
import DataSource from 'devextreme/data/data_source';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-edit-generate',
    templateUrl: 'editGenerate.component.html'
})
export class EditGenerateComponent implements OnInit {

    @ViewChild('generateForm') generateForm: DxFormComponent;

    dsStorageType: CustomStore;
    dspWorkCenter: CustomStore;
    dspEntities: CustomStore;
    dsWasteType: DataSource;
    wasteCodeInst: any;
    workCenterInst: any;
    onEntityChange: any;
    onWorkCenterInit: any;
    onWorkCenterChange: any;
    dsCustomSource: any[];
    dsSource: any;
    dsElimination: CustomStore;
    dsCapitalization: CustomStore;
    formInstance: any;
    dsEEECategory: CustomStore;

    constructor(public ds: DataService, public edit: EditGenerateService, public editU: EditUnitWasteService,
        public ui: UIService, public activeRoute: ActivatedRoute) {
        this.dspEntities = this.ds.getPartyLinkView([2]);
        this.dsStorageType = this.ds.getEnumValue('Stocare');
        this.dsElimination = this.ds.getEnumValue('Eliminare');
        this.dsCapitalization = this.ds.getEnumValue('Valorificare');

        this.onEntityChange = this.onEntityChangeEv.bind(this);
        this.onWorkCenterInit = this.onWorkCenterInitEv.bind(this);
        this.onWorkCenterChange = this.onWorkCenterChangeEv.bind(this);

        this.activeRoute.queryParams.subscribe(params => {
            const displayKey = params['display'];
            const wasteType = params['wasteType'];
            const customObj = this.ui.display[displayKey];
            if (displayKey && customObj && customObj.customSource) {
                this.dsSource = this.ds.static.source.filter(x => x.id === 1);
            } else {
                this.dsSource = this.ds.static.source;
            }
            if (wasteType == '2') {
                this.dsEEECategory = this.ds.getEnumValue('EEE');
            }
        });
    }

    ngOnInit() {
        if (this.edit.model.entityId && this.edit.model.entityId.length) {
            this.dspWorkCenter = this.ds.getPartyMemberById(this.edit.model.entityId);
            // this.wcDisable = false;
        }

        if (this.edit.model.workCenterId && this.edit.model.workCenterId.length) {
            this.dsWasteType = this.ds.setWasteCodeDataSourceForWorkCenter(this.edit.model.workCenterId, null, this.edit.wasteType ? this.edit.wasteType : null);
        }
        this.dsCustomSource = [];
    }

    onFormInit(event) {
        this.formInstance = event.component;
    }

    onWasteCodeInitEv(event: any) {
        this.wasteCodeInst = event.component;
    }
    onWorkCenterInitEv(event: any) {
        this.workCenterInst = event.component;
    }

    openWasteTypeAddEv(event: any) {
        const newWT = this.editU.createDefault();
        newWT.workCenterId = this.edit.model.workCenterId;
        this.editU.showPopup(newWT).then(() => this.wasteCodeInst.getDataSource().reload());
        event.event.preventDefault();
        event.event.stopPropagation();
    }

    onEntityChangeEv(event: any) {
        if (event.event) {
            this.ds.resetValidation(this.workCenterInst);
            this.ds.resetValidation(this.wasteCodeInst);
        }

        if (event.value) {
            this.dspWorkCenter = this.ds.getPartyMemberById(event.value);
        }
    }

    onWorkCenterChangeEv(event: any) {
        if (event.event) {
            this.ds.resetValidation(this.wasteCodeInst);
        }

        if (event.value) {
            this.dsWasteType = this.ds.setWasteCodeDataSourceForWorkCenter(event.value, null, this.edit.wasteType ? this.edit.wasteType : null);
        }
    }

    setWasteInfo(hazardName = null, wt_name = null, uom = null, storageTypeId = null, physicalCondition = null) {
        this.edit.model.wt_hazardName = hazardName;
        this.edit.model.wt_name = wt_name;
        this.edit.model.uom = DataService.getUOM(uom);
        this.edit.model.wt_uom = uom;
        this.edit.model.wt_storageTypeId = storageTypeId;
        this.edit.model.physicalCondition = physicalCondition;
    }

    onWasteChangeEv(event: any) {
        if (event.event) {
            const items = this.wasteCodeInst.getDataSource().items();
            const item = items.find((x: any) => x.id === event.value);
            if (item) {
                this.ds.getWasteTypeParty(event.value).then((result) => {
                    if (result) {
                        const hazard = DataService.getHarzardName(item.hazard);
                        this.setWasteInfo(hazard, item.name, item.uom, result.storageTypeId, result.physicalCondition);
                    }
                }, (error) => { this.ds.serverError(error); });
                this.edit.dsMaterial = this.ds.getEnumValue(item.code);
            }
        }
    }

    onAddHidden(e: any) {
        this.ui.onPopupHidden(e);
    }

    quantityCallBack = (options: any) => {
        if (options.value <= 0) {
            return false;
        }
        return true;
    }

    vCodeValidationCallback = (options) => {
        const elimination = this.formInstance.getEditor('eliminationId').option('value');
        const capital = this.formInstance.getEditor('capitalizationId').option('value');
        return (elimination || capital) ? true : false;
    }
}
