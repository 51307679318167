import { Component } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { EditPartyLinkService } from './editPartyLink.service';

@Component({
    selector: 'app-edit-party-link',
    templateUrl: 'editPartyLink.component.html'
})
export class EditPartyLinkComponent {

    dsWasteType: CustomStore;
    dsWorkCenter: CustomStore;
    onWasteTypeChange: any;

    constructor(public ds: DataService, public edit: EditPartyLinkService) {
        this.dsWorkCenter = this.ds.getParty('pc', true);
        this.dsWasteType = this.ds.getUnitWasteTypes();
        this.onWasteTypeChange = this.onWasteTypeChangeEv.bind(this);
    }

    onWasteTypeChangeEv(ev) {
        this.edit.updateWasteInfo(ev.value);
    }
}
