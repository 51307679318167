import { Injectable } from '@angular/core';
import { iBisEditService } from '@dohu/ibis-common';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { DataService } from '../../../service/data.service';

@Injectable({
    providedIn: 'root'
})
export class EditIncinerateService extends iBisEditService {
    dsTreatmentSource: any;

    constructor(public ds: DataService) {
        super(ds.lg);
        this.dsTreatmentSource = [];
        this.validation = 'incinerateValidation';
    }

    createDefault() {
        const result = { isValid: true, date: new Date(), deviceId: null, entityId: null, workCenterId: null };
        this.dsTreatmentSource = [];
        if (this.ds) {
            this.ds.getEnumValue('Eliminare').load().then(data => {
                const ent = data.data.find(x => x.code === 'D10');
                if (ent) {
                    this.model.eliminationId = ent.id;
                }
            });
        }
        if (this.ds && this.ds.defaultParty) {
            result.deviceId = this.ds.static.deviceLine[0];
            result.entityId = this.ds.defaultParty.ent;
            result.workCenterId = this.ds.defaultParty.wc;
        }
        if (this.ds && this.ds.static.workCenterIncinerateId) {
            result.workCenterId = this.ds.static.workCenterIncinerateId;
        }
        return result;
    }

    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            EntityStore.fromQuery(new EntityQuery('Elimination').eq('id', data.e_id)).single().then((obj) => {
                if (!obj) {
                    reject();
                } else {
                    this.dsTreatmentSource = [];
                    obj.entityId = data.wc_parentId;
                    obj.uom = DataService.getUOM(data.wt_uom);
                    obj.wasteCode = data.wt_code;
                    obj.wasteName = data.wt_name;
                    obj.hazardName = DataService.getHarzardName(data.wt_hazard);
                    resolve(obj);
                }
            });
        });
    }

    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Elimination')).remove(id);
    }
    onSaveEv(): Promise<any> {
        const obj = this.model;
        this.model.sources = this.dsTreatmentSource;
        const s = EntityStore.fromQuery(new EntityQuery('Elimination'));
        return (obj.id ? s.update(obj.id, obj) : s.insert(obj));
    }
    reset(): void {
        this.model = this.createDefault();
    }
}
