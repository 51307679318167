import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { EntityStoreOptions, EntityStore } from '@dohu/ibis-entity';
import { Router, NavigationStart } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { iBisFileService } from '@dohu/ibis-common';

@Component({
    templateUrl: 'reportExt.component.html'
})
export class ReportExtComponent implements OnInit {

    now: Date;
    dsMonth: any;
    dsSemester: any;

    dsBu: CustomStore;
    dsEntities: CustomStore;
    reportMonth: number;
    reportYear: number;
    dsWorkCenter: CustomStore;
    reportObj: any;

    wcVisible: boolean;
    groupVisible: boolean;
    yearVisible: boolean;
    monthVisible: boolean;
    semVisible: boolean;
    thruDateVisible: boolean;
    fromDateVisible: boolean;

    disableEntity: boolean;
    disableWc: boolean;

    onBuChanged: any;
    dsReportType: any;

    @ViewChild('reportExt') reportExt: DxFormComponent;
    constructor(public ds: DataService, public router: Router, public file: iBisFileService) { }

    ngOnInit() {
        this.onBuChanged = this.onBuChangedEv.bind(this);

        this.reportMonth = new Date().getMonth() === 0 ? 12 : new Date().getMonth();
        this.reportYear = this.reportMonth === 12 ? new Date().getFullYear() - 1 : new Date().getFullYear();
        this.dsMonth = this.ds.static.months;
        this.dsSemester = this.ds.getSemester();
        this.dsBu = this.ds.getParty('bu');
        this.dsEntities = this.ds.getParty('ent');
        this.reportObj = {
            bu: null, entity: null, workCenter: null,
            year: this.reportYear, sem: null, month: this.reportMonth,
            fromDate: this.ds.firstMonthDay, thruDate: this.ds.lastMonthDay
        };
        this.checkForSingleParty();

        const report = this.router.url.split('/');
        if (report[3]) {
            this.setReportType(report[3]);
        }

        this.router.events.forEach((event) => {
            if (event instanceof (NavigationStart)) {
                const reportLink = event.url.split('/');
                if (reportLink[3]) {
                    this.setReportType(reportLink[3]);
                }
            }
        });
    }

    checkForSingleParty() {
        this.ds.lookingForSingleParty().then(data => {
            this.reportObj.bu = data.bu;
            this.reportObj.entity = data.ent;
        });
    }

    onBuChangedEv(ev: any) {
        if (ev.value) {
            this.dsEntities = this.ds.getEntityByParentId(ev.value);
            this.setFieldsDisable(!this.reportObj.bu, !this.reportObj.entity);
        }
        if (ev.event) {
            this.reportObj.entity = null;
            this.reportObj.workCenter = null;
        }
    }

    setReportType(param: string) {
        switch (param) {
            case 'generic':
                this.dsReportType = [{ id: 1, value: 'Anexa 1' }];
                this.setFieldsVisible(true, true, true, true, false, false);
                this.setFieldsDisable(!this.reportObj.bu, !this.reportObj.entity);
                break;
            case 'electrics':
                this.setFieldsVisible(true, true, true, false, false, false, false);
                this.setFieldsDisable();
                this.dsReportType = [{ id: 4, value: 'Anexa 4' },
                { id: 5, value: 'Anexa 5' },
                { id: 6, value: 'Anexa 6' }];
                break;
            case 'batery':
                this.setFieldsVisible(true, true, true, false, false, false, false);
                this.setFieldsDisable();
                this.dsReportType = [{ id: 2, value: 'Anexa 2' },
                { id: 3, value: 'Anexa 3' }];
                break;
            case 'vehicles':
                this.setFieldsVisible(true, true, true, false, false, false, false);
                this.setFieldsDisable();
                this.dsReportType = [{ id: 7, value: this.ds.lg.format('VSU_REPORT', null) }];
                break;
            case 'packing':
                this.setFieldsVisible(true, true, true, false, false, false, false);
                this.setFieldsDisable();
                this.dsReportType = [{ id: 13, value: 'Anexa 1' },
                { id: 14, value: 'Anexa 2A' },
                { id: 15, value: 'Anexa 2B' }];
                break;
            case 'oils':
                // TODO Luca globalization for value
                this.setFieldsVisible(true, false, true, false, false, false, true);
                this.setFieldsDisable();
                this.dsReportType = [{ id: 8, value: 'Importatori' },
                { id: 9, value: 'Generatori' },
                { id: 10, value: 'Service / PFA' },
                { id: 11, value: 'Colectori' },
                { id: 12, value: 'Stații de distribuție' }];
                break;
        }
        if (this.reportExt && this.reportExt.instance) {
            this.reportExt.instance.option('dataSource', this.dsReportType);
        }
    }

    onReportFieldChange(event) {
        const val = event.value;
        if (event.dataField === 'reportType' && val) {
            switch (val) {
                case 1:
                    this.setFieldsVisible(true, true, true, true, false, false);
                    this.setFieldsDisable(!this.reportObj.bu, !this.reportObj.entity);
                    break;
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    this.setFieldsVisible(true, true, true, false, false, false, false);
                    this.setFieldsDisable();
                    break;
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                    this.setFieldsVisible(true, false, true, false, false, false, true);
                    this.setFieldsDisable();
            }
        } else if (event.dataField === 'reportType' && !val) {
            this.reportObj.reportType = this.dsReportType[0].id;
        }

        if (event.dataField === 'entity') {
            this.reportObj.workCenter = null;
            this.dsWorkCenter = this.ds.getPartyMemberById(val);
            this.disableWc = !this.reportObj.entity;
        }
    }

    setFieldsVisible(group: boolean, wc: boolean, year: boolean, month: boolean, thruDate: boolean, fromDate: boolean, sem = false) {
        this.groupVisible = group;
        this.wcVisible = wc;
        this.yearVisible = year;
        this.monthVisible = month;
        this.thruDateVisible = thruDate;
        this.fromDateVisible = fromDate;
        this.semVisible = sem;
    }

    setFieldsDisable(entity: boolean = false, wc: boolean = false) {
        this.disableEntity = entity;
        this.disableWc = wc;
    }

    onClickArchive(event, reportNo: number) {
        const obj = {
            typeId: reportNo,
            year: this.reportObj.year,
            semester: this.reportObj.sem,
            month: this.reportObj.month,
            buId: this.reportObj.bu,
            entityId: this.reportObj.entity, workCenterId: this.reportObj.workCenter
        };
        EntityStore.execute('PrepareForArchive', obj).then(res => {
            if (res) {
                notify(this.ds.lg.format('REPORT_ARCHIVED', null), 'success', 3000);
            }
        }, err => {
            notify(this.ds.lg.format('S100', null), 'error', 3000);
        });
    }


    onClickGenerateReport(e: any, reportNo: number) {
        const validation = e.validationGroup.validate();
        if (!validation.isValid) {
            notify(this.ds.lg.format('FIELDS_NOT_VALID', null), 'error', 3000);
            return;
        }
        let url;
        switch (reportNo) {
            // trebuie vazut daca sunt necesari toti parametri
            case 1:
                url = EntityStoreOptions.DefaultServer + '/DownloadAnex1/' +
                    this.reportObj.year + '_' + this.reportObj.month + '_' +
                    this.reportObj.bu + '_' + this.reportObj.entity + '_' + this.reportObj.workCenter;
                break;
            case 2:
                url = EntityStoreOptions.DefaultServer + '/DownloadBatteriesAnex2/' +
                    this.reportObj.year + '_' + this.reportObj.month + '_' +
                    this.reportObj.bu + '_' + this.reportObj.entity + '_' + this.reportObj.workCenter;
                break;
            case 3:
                url = EntityStoreOptions.DefaultServer + '/DownloadBatteriesAnex3/' +
                    this.reportObj.year + '_' + this.reportObj.month + '_' +
                    this.reportObj.bu + '_' + this.reportObj.entity + '_' + this.reportObj.workCenter;
                break;
            case 4:
                url = EntityStoreOptions.DefaultServer + '/DownloadEEEAnex4/' +
                    this.reportObj.year + '_' +
                    this.reportObj.bu + '_' + this.reportObj.entity + '_' + this.reportObj.workCenter;
                break;
            case 5:
                url = EntityStoreOptions.DefaultServer + '/DownloadEEEAnex5/' +
                    this.reportObj.year + '_' +
                    this.reportObj.bu + '_' + this.reportObj.entity + '_' + this.reportObj.workCenter;
                break;
            case 6:
                url = EntityStoreOptions.DefaultServer + '/DownloadEEEAnex6/' +
                    this.reportObj.year + '_' +
                    this.reportObj.bu + '_' + this.reportObj.entity + '_' + this.reportObj.workCenter;
                break;
            case 7:
                url = EntityStoreOptions.DefaultServer + '/DownloadVehiclesReport/' +
                    this.reportObj.year + '_' +
                    this.reportObj.bu + '_' + this.reportObj.entity + '_' + this.reportObj.workCenter;
                break;
            case 8:
                url = EntityStoreOptions.DefaultServer + '/DownloadOilImporter/' +
                    this.reportObj.year + '_' + this.reportObj.sem + '_' + this.reportObj.entity;
                break;
            case 9:
                url = EntityStoreOptions.DefaultServer + '/DownloadOilGenerator/' +
                    this.reportObj.year + '_' + this.reportObj.sem + '_' + this.reportObj.entity;
                break;
            case 10:
                url = EntityStoreOptions.DefaultServer + '/DownloadOilService/' +
                    this.reportObj.year + '_' + this.reportObj.sem + '_' + this.reportObj.entity;
                break;
            case 11:
                url = EntityStoreOptions.DefaultServer + '/DownloadOilCollector/' +
                    this.reportObj.year + '_' + this.reportObj.sem + '_' + this.reportObj.entity;
                break;
            case 12:
                url = EntityStoreOptions.DefaultServer + '/DownloadOilStations/' +
                    this.reportObj.year + '_' + this.reportObj.sem + '_' + this.reportObj.entity;
                break;
            case 13:
                url = EntityStoreOptions.DefaultServer + '/DownloadPackingAnex1/' +
                    this.reportObj.year + '_' + this.reportObj.workCenter + '_' + this.reportObj.entity;
                break;
            case 14:
                url = EntityStoreOptions.DefaultServer + '/DownloadPackingAnex2A/' +
                    this.reportObj.year + '_' + this.reportObj.workCenter + '_' + this.reportObj.entity;
                break;
            case 15:
                url = EntityStoreOptions.DefaultServer + '/DownloadPackingAnex2B/' +
                    this.reportObj.year + '_' + this.reportObj.workCenter + '_' + this.reportObj.entity;
                break;
            default:
                break;
            // this.dsReportType = [{ id: 8, value: 'Importatori' },
            // { id: 9, value: 'Generatori' },
            // { id: 10, value: 'Service / PFA' },
            // { id: 11, value: 'Colectori' },
            // { id: 12, value: 'Stații de distribuție' }];
        }
        if (url) {
            this.file.DownloadByUrl(url);
        } else {
            notify(this.ds.lg.format('REPORT_NOT_FOUND', null), 'warning', 3000);
        }
    }
}
