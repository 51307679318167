import { Component } from '@angular/core';
import { DataService } from '../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { UIService } from '../../service/ui.service';

@Component({
    templateUrl: 'alert.component.html'
})
export class AlertComponent {

    dsaPermit: CustomStore;
    dsaPermitsInt: CustomStore;
    dsaContract: CustomStore;
    dsaTransport: CustomStore;
    dsaControlMeasures: CustomStore;
    dspCtr: CustomStore;

    constructor(public ds: DataService, public ui: UIService) {
        this.dsaPermit = ds.aPermits();
        this.dsaPermitsInt = ds.aPermitsInt();
        this.dsaContract = ds.aContracts();
        this.dsaTransport = ds.aTransports();
        this.dsaControlMeasures = ds.aControlMeasures();
        this.dspCtr = this.ds.getParty('cr');
     }
}
