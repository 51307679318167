import { Component, Input } from '@angular/core';
import { DataService } from '../../service/data.service';
import { UIService } from '../../service/ui.service';

@Component({
    selector: 'app-graph-line',
    templateUrl: 'graph.component.html'
})

export class GraphComponent {
    @Input() txt: any;
    @Input() typeValue: any[];
    @Input() typeOp: any[];
    @Input() uom: number;
    @Input() intervalDate: string;
    constructor(public ds: DataService, public ui: UIService) {
    }

    onGraphInit(event) {
        event.component.render();
    }
}
