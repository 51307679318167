import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';

import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { DataService } from '../service/data.service';
import { DxMultiViewComponent, DxPopupComponent } from 'devextreme-angular';
import { EditUnitService } from '../view/admin/unit/editUnit.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { EditUnitWasteService } from '../view/admin/unit/editUnitWaste.service';

@Component({
    selector: 'app-init-app',
    templateUrl: './init-app.component.html',
    providers: [EditUnitService, EditUnitWasteService]
})
export class InitAppComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() isInitAppVisible: boolean;
    @Input() initOptions: any;
    @Output() closePopup = new EventEmitter<any>();

    wizardState: number;
    goToNext: any;
    multiViewItems: any;
    onFinish: any;

    @ViewChild('multiview') multiview: DxMultiViewComponent;
    @ViewChild('popupInitApp') popupInitApp: DxPopupComponent;
    unitInstance: any;
    workCenterInserted: string;

    wizardValidationArr: string[];
    wizardValidation: string;
    manualUrl: string;
    entityInserted: any;

    constructor(public ds: DataService, public editU: EditUnitService, public editUD: EditUnitWasteService) {
        this.goToNext = this.gotoNextEv.bind(this);
        this.onFinish = this.onFinishEv.bind(this);
    }

    ngOnInit() {
        this.manualUrl = environment.manualUrl;
        this.editU.edit(null);
    }

    ngAfterViewInit() {
        this.wizardValidationArr = [undefined, 'unitValidation', 'unitValidation', 'partyWasteValidation'];
        setTimeout(() => {
            this.initMultiView();
        }, 0);
    }

    ngOnChanges(changes: any) {
        if (this.isInitAppVisible) {
            this.initMultiView();
        }
    }

    initFormUnit(e: any) {
        this.unitInstance = e;
    }

    gotoNextEv(event: any) {
        event.event.preventDefault();
        this.popupInitApp.instance.option('height', '100%');
        if (event.validationGroup) {
            const validation = event.validationGroup.validate();
            if (!validation.isValid) {
                notify(this.ds.lg.format('ALL_REQ_FIELDS', null), 'error', 3000);
                return;
            }
        }
        if (this.wizardState === 1 || this.wizardState === 2) {
            this.editU.model.isSubcontractor = false;
            this.ds.addEditParty(this.editU.model, this.editU.model.id).then((result: any) => {
                if (result.typeId === 2) {
                    this.workCenterInserted = result.id;
                    this.editUD.model = { isActiv: true, workCenterId: this.workCenterInserted };
                }
                if (result.typeId === 1) {
                    this.entityInserted = result;
                    this.entityInserted.countyId = this.editU.model.countyId;
                    this.ds.addDefaultGroup(this.entityInserted);
                }
                setTimeout(() => {
                    this.resetSlide();
                }, 0);
            }, err => {
                this.ds.serverError(err);
            });
        } else if (this.wizardState === 3) {
            this.editUD.onSave(event);
            setTimeout(() => {
                this.resetSlide();
            }, 0);
        } else {
            this.resetSlide();
        }
    }

    resetSlide() {
        const current = this.multiview.instance.option('selectedIndex');
        this.wizardState = current + 1;
        this.multiview.instance.option('selectedIndex', current + 1);
        this.wizardValidation = this.wizardValidationArr[this.wizardState];
        // reset values
        if (this.unitInstance) {
            this.unitInstance.resetValues();
            this.unitInstance.repaint();
        }
        this.editU.model = { isActiv: true, isSubcontractor: false };
        switch (this.wizardState) {
            case 1:
                this.editU.model.typeId = 1;
                if (this.initOptions && (this.initOptions.company || this.initOptions.cif)) {
                    if (this.initOptions.company) {
                        this.editU.model.name = this.initOptions.company;
                    }
                    if (this.initOptions.cif) {
                        this.editU.model.cif = this.initOptions.cif;
                    }
                }
                break;
            case 2:
                setTimeout(() => {
                    const obj = {
                        typeId: 2,
                        parentId: this.entityInserted.id,
                        name: this.entityInserted.name,
                        address: this.entityInserted.address,
                        countyId: this.entityInserted.countyId,
                        localityId: this.entityInserted.localityId,
                        isSubcontractor: false,
                        isActiv: true
                    };
                    this.editU.edit(obj).then(() => this.resetSlide());
                }, 100);
                break;
            case 3:
                this.editUD.edit({ isActiv: true, workCenterId: this.workCenterInserted }).then(() => this.resetSlide());
                break;
        }
    }

    onFinishEv(event: any) {
        event.event.preventDefault();
        this.closeWizard(event);
    }

    closeWizard(event: any) {
        this.isInitAppVisible = false;
        this.wizardState = 0;
        this.closePopup.emit(this.isInitAppVisible);
    }

    initMultiView() {
        if (this.initOptions && this.initOptions.workCenterId) {
            this.wizardState = 3;
            this.editUD.edit({ isActiv: true, workCenterId: this.initOptions.workCenterId });
        } else if (this.initOptions && this.initOptions.entityId) {
            this.wizardState = 2;
            this.ds.getPartyById(this.initOptions.entityId).then(result => {
                this.editU.model = {
                    typeId: 2,
                    parentId: result.id,
                    name: result.name,
                    address: result.address,
                    localityId: result.localityId,
                    isActiv: true,
                    isSubcontractor: false
                };
                this.ds.getCountId(result.localityId).then(res => {
                    this.editU.model.countyId = res.parentId;
                });
            });
        } else {
            this.wizardState = 0;
        }
        setTimeout(() => {
            if (this.multiview) {
                this.multiview.instance.option('selectedIndex', this.wizardState);
            }
        }, 0);
        this.wizardValidation = this.wizardValidationArr[this.wizardState];

        this.multiViewItems = new DataSource({
            store: [{
                title: this.ds.lg.format('INFO', null)
            }, {
                title: this.ds.lg.format('ADD_ENTITY', null),
            }, {
                title: this.ds.lg.format('ADD_WORKSTATION', null),
            }, {
                title: this.ds.lg.format('ASSOC_WASTE_CODE', null)
            }, {
                title: this.ds.lg.format('FHINISHING', null)
            }]
        });
    }
}
