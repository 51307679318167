import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TaxAnalyzesComponent } from './tax-analyzes/tax-analyzes.component';
import { ControlAnalyzesComponent } from './control-analyzes/control-analyzes.component';
import { CapitalizationAnalyzesComponent } from './capitalization-analyzes/capitalization-analyzes.component';
import { CostAnalyzesComponent } from './cost-analyzes/cost-analyzes.component';
import { IncomeAnalyzesComponent } from './income-analyzes/income-analyzes.component';

import {
    DxDataGridModule, DxButtonModule, DxPopupModule, DxFormModule, DxScrollViewModule, DxSelectBoxModule, DxValidatorModule,
    DxDateBoxModule, DxValidationSummaryModule, DxValidationGroupModule, DxChartModule, DxPieChartModule, DxRangeSelectorModule,
    DxMultiViewModule, DxTextBoxModule, DxDropDownBoxModule, DxListModule
} from 'devextreme-angular';
import { GraphComponent } from '../../common/graph/graph.component';
import { InitAppComponent } from '../../wizard/init-app.component';
import { GenerareTransportComponent } from '../../wizard/generareTransport.component';
import { AnalizeComponent } from './analize.component';
import { EditUnitComponent, EditUnitWasteComponent } from '../admin/unit';
import { EditGenerateComponent } from '../waste/generate';
import { EditTransportComponent } from '../waste/transport/editTransport.component';
import { FinishTransportComponent } from '../waste/transport/finishTransport/finishTransport.component';
import { DashboardBoxesComponent } from '../../common/dashboard-boxes/dashboard-boxes.component';


@NgModule({
    declarations: [
        DashboardComponent,
        TaxAnalyzesComponent,
        ControlAnalyzesComponent,
        CapitalizationAnalyzesComponent,
        CostAnalyzesComponent,
        IncomeAnalyzesComponent,
        GraphComponent,
        InitAppComponent,
        GenerareTransportComponent,
        AnalizeComponent,
        EditUnitComponent,
        EditUnitWasteComponent,
        EditTransportComponent,
        FinishTransportComponent,
        EditGenerateComponent,
        DashboardBoxesComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule,
        DxDataGridModule,
        DxPopupModule,
        DxFormModule,
        DxScrollViewModule,
        DxButtonModule,
        DxSelectBoxModule,
        DxValidatorModule,
        DxValidationSummaryModule,
        DxValidationGroupModule,
        DxDateBoxModule,
        DxChartModule,
        DxPieChartModule,
        DxRangeSelectorModule,
        DxMultiViewModule,
        DxTextBoxModule,
        DxDropDownBoxModule,
        DxListModule,
    ],
    exports: [
        DashboardComponent,
        TaxAnalyzesComponent,
        ControlAnalyzesComponent,
        CapitalizationAnalyzesComponent,
        CostAnalyzesComponent,
        IncomeAnalyzesComponent,
        GraphComponent,
        InitAppComponent,
        GenerareTransportComponent,
        AnalizeComponent,
        EditUnitComponent,
        EditUnitWasteComponent,
        EditTransportComponent,
        FinishTransportComponent,
        EditGenerateComponent,
        DashboardBoxesComponent
    ]
})
export class AnalizeModule { }