import { Component, Input } from '@angular/core';
import { DataService } from '../../../service/data.service';

@Component({
    selector: 'app-edit-wastetype',
    templateUrl: 'editWastetype.component.html'
})
export class EditWasteTypeComponent {
    @Input() wastetypeData: any;
    dsParent: any;
    constructor(public ds: DataService) {
        this.dsParent = this.ds.getWasteTypeParent();
    }

}
