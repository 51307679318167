import { Injectable } from '@angular/core';
import { DataService } from '../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { NavigationStart, Router } from '@angular/router';

@Injectable()
export class AnalizeService {

    dsBu: CustomStore;
    dsEntities: CustomStore;
    dsWasteType: CustomStore;
    dsPartyWc: CustomStore;
    dsContractors: CustomStore;

    dsCaps: any;
    dsTax: any;
    dsCosts: any;
    dsIncomes; any;
    dsControls: any;

    currentSubTab: string;
    visibleWaste: boolean;
    visibleSupplier: boolean;

    filter: any;
    total: any;

    onWasteTypeChanged: any;
    onBuChanged: any;
    onEntityChanged: any;
    onWcChanged: any;
    onDateChanged: any;
    onSupplierChanged: any;

    dsGVal: any[];
    dsTVal: any[];
    dsEVal: any[];
    dsVVal: any[];
    dsTaxVal: any[];
    dsCostVal: any[];
    dsIncomeVal: any[];
    dsControlVal: any[];

    constructor(public ds: DataService, public router: Router) {
        this.getCurrentSubTab(this.router.url);
        this.setVisibleToFields();
        this.router.events.forEach((event) => {
            if (event instanceof (NavigationStart)) {
                if (event.url) {
                    this.getCurrentSubTab(event.url);
                    this.setVisibleToFields();
                    this.initComponent();
                }
            }
        });

        this.onWasteTypeChanged = this.onWasteTypeChangedEv.bind(this);
        this.onBuChanged = this.onBuChangedEv.bind(this);
        this.onEntityChanged = this.onEntityChangedEv.bind(this);
        this.onWcChanged = this.onWcChangedEv.bind(this);
        this.onDateChanged = this.onDateChangedEv.bind(this);
        this.onSupplierChanged = this.onSupplierChangedEv.bind(this);

        this.dsPartyWc = this.ds.getParty('wc');
        this.dsEntities = this.ds.getParty('ent');
        this.dsBu = this.ds.getParty('bu');
        this.dsContractors = this.ds.getParty('ct');
        this.dsWasteType = this.ds.getActiveWasteTypes();
        this.filter = {
            bu: null, entity: null, wasteUom: null, supplier: null, fromDate: this.ds.firstMonthDay, thruDate: this.ds.lastMonthDay,
            intervalPeriod: this.ds.lg.format('INTERVAL', null) + DataService.formatDate(this.ds.firstMonthDay) + ' - ' + DataService.formatDate(this.ds.lastMonthDay)
        };
        this.checkForExistingFilter();
        this.total = {
            totalG: this.ds.lg.format('TOTAL_QUANTITY', null) + ': 0',
            totalE: this.ds.lg.format('TOTAL_QUANTITY', null) + ': 0',
            totalT: this.ds.lg.format('TOTAL_QUANTITY', null) + ': 0',
            totalV: this.ds.lg.format('TOTAL_QUANTITY', null) + ': 0',
            totalControl: this.ds.lg.format('TOTAL_VALUE', null) + ': 0',
            totalCapital: this.ds.lg.format('TOTAL_QUANTITY', null) + ': 0',
            totalTax: this.ds.lg.format('TOTAL_VALUE', null) + ': 0',
            totalCost: this.ds.lg.format('TOTAL_VALUE', null) + ': 0',
            totalIncome: this.ds.lg.format('TOTAL_VALUE', null) + ': 0',

        };
    }

    getCurrentSubTab(url: string) {
        this.currentSubTab = url.split('/')[2];
    }

    setVisibleToFields() {
        this.visibleWaste = this.currentSubTab === this.ds.lg.format('DASHBOARD', null) || this.currentSubTab === this.ds.lg.format('VALORIZATION', null);
        this.visibleSupplier = this.currentSubTab === this.ds.lg.format('INCOME', null) || this.currentSubTab === this.ds.lg.format('COSTS', null);
    }


    checkForExistingFilter() {
        const eFilter = JSON.parse(sessionStorage.getItem('analizeQHSE'));
        this.ds.lookingForSingleParty().then(data => {
            this.ds.defaultParty = data;
            if (eFilter) {
                Object.assign(this.filter, eFilter);
            }
            this.filter.bu = this.filter.bu || this.ds.defaultParty.bu;
            this.filter.entity = this.filter.entity || this.ds.defaultParty.ent;
            this.filter.workCenter = this.filter.workCenter || this.ds.defaultParty.wc;

            // if ( eFilter && !eFilter.bu && !eFilter.entity && !eFilter.workCenter) {
            //     this.initComponent();
            // }
            if (eFilter && eFilter.bu) {
                this.dsEntities = this.ds.getEntityByParentId(eFilter.bu);
            }
            if (eFilter && eFilter.entity) {
                this.dsPartyWc = this.ds.getPartyMemberById(eFilter.entity);
                // tslint:disable-next-line:max-line-length
                this.dsWasteType = eFilter.workCenter ? this.ds.getWasteTypeForWorkCenter(eFilter.workCenter) : this.ds.getWasteTypeByEntity(eFilter.entity);
            }
            this.initComponent();
        });
    }

    onFilterChanged(event: any) {
        const type = event.dataField;
        switch (type) {
            case 'bu':
                if (this.ds.defaultParty && this.ds.defaultParty.ent && this.filter.entity !== this.ds.defaultParty.ent) {
                    this.filter.entity = null;
                }
                if (this.ds.defaultParty && this.ds.defaultParty.wc && this.filter.workCenter !== this.ds.defaultParty.wc) {
                    this.filter.workCenter = null;
                }
                break;
            case 'entity':
                if (this.ds.defaultParty && this.ds.defaultParty.wc && this.filter.workCenter !== this.ds.defaultParty.wc) {
                    this.filter.workCenter = null;
                }
                break;
            case 'workCenter':
            case 'wasteType':
                // pe functia: onWasteTypeSelChEv
                break;
            case 'fromDate':
            case 'thruDate':
                this.filter.intervalPeriod = 'Interval: ' + DataService.formatDate(this.filter.fromDate) + ' - '
                    + DataService.formatDate(this.filter.thruDate);
                break;
        }
        sessionStorage.setItem('analizeQHSE', JSON.stringify(this.filter));
    }

    onBuChangedEv(event) {
        if (event.value) {
            this.dsEntities = this.ds.getEntityByParentId(event.value);
            this.dsWasteType = this.ds.getWasteTypeByEntity(null, event.value);
        } else {
            this.dsWasteType = this.ds.getActiveWasteTypes();
        }
        if (event.event) {
            this.filter.entity = null;
            if (!this.visibleSupplier) { this.filter.workCenter = null; }
            if (this.visibleWaste) { this.filter.wasteType = null; }
            this.initComponent();
        }
    }

    onEntityChangedEv(event) {
        if (event.value) {
            this.dsPartyWc = this.ds.getPartyMemberById(event.value);
            this.dsWasteType = this.ds.getWasteTypeByEntity(event.value);
        } else if (this.filter.bu) {
            this.dsWasteType = this.ds.getWasteTypeByEntity(null, this.filter.bu);
        }
        if (event.event) {
            if (!this.visibleSupplier) { this.filter.workCenter = null; }
            if (this.visibleWaste) { this.filter.wasteType = null; }
            this.initComponent();
        }
    }

    onWcChangedEv(event) {
        if (event.value) {
            this.dsWasteType = this.ds.getWasteTypeForWorkCenter(event.value);
        } else if (this.filter.entity) {
            this.dsWasteType = this.ds.getWasteTypeByEntity(this.filter.entity);
        }
        if (event.event) {
            if (this.visibleWaste) { this.filter.wasteType = null; }
            this.initComponent();
        }
    }

    onDateChangedEv(event) {
        if (event.event) {
            this.initComponent();
        }
    }

    onSupplierChangedEv(event) {
        if (event.event) {
            this.initComponent();
        }
    }

    initComponent() {
        switch (this.currentSubTab) {
            case 'dashboard':
                if (this.filter.wasteType) {
                    this.ds.getGraphData(this.filter).then((result) => {
                        this.initDashboard(result);
                    });
                }
                break;
            case 'valorificare':
                this.initCapitalization(this.filter.wasteType);
                break;
            case 'taxe':
                this.initTax(this.filter.workCenter);
                break;
            case 'costuri':
                this.initInvoice(this.filter.entity, this.filter.supplier, true);
                break;
            case 'venituri':
                this.initInvoice(this.filter.entity, this.filter.supplier);
                break;
            case 'controale':
                this.initControls();
        }
    }

    onWasteTypeChangedEv(event: any) {
        if (event.selectedItem) {
            this.filter.wasteUom = event.selectedItem.uom;
            this.filter.wasteType = event.selectedItem.id;
            this.initComponent();
        } else {
            switch (this.currentSubTab) {
                case 'dashboard':
                    this.initDashboard(true);
                    break;
                case 'valorificare':
                    this.initCapitalization(null);
                    break;
            }
        }
    }

    initDashboard(data: any) {
        this.dsGVal = this.getValues(data.generateData, 'gen');
        this.total.totalG = this.totalQuantityTxt(this.dsGVal, 'gen');

        this.dsEVal = this.getValues(data.eliminateData, 'el');
        this.total.totalE = this.totalQuantityTxt(this.dsEVal, 'el');

        this.dsTVal = this.getValues(data.transportData, 'tr');
        this.total.totalT = this.totalQuantityTxt(this.dsTVal, 'tr');

        this.dsVVal = this.getValues(data.valorifData, 'vl');
        this.total.totalV = this.totalQuantityTxt(this.dsVVal, 'vl');
    }

    initTax(wc) {
        this.ds.getTaxByDate(wc, this.filter.fromDate, this.filter.thruDate).then((res) => {
            const graphData = [];
            const gridData = [];

            res.data.forEach(element => {
                const dd = res.data.filter(x => new Date(x.t_payDate).getMonth() + 1 === new Date(element.t_payDate).getMonth() + 1);
                const itExists = gridData.find(x => x.month === new Date(element.t_payDate).getMonth() + 1);
                if (dd && dd.length === 1) {
                    gridData.push({
                        t_payDate: element.t_payDate,
                        month: new Date(element.t_payDate).getMonth() + 1,
                        year: new Date(element.t_payDate).getFullYear(),
                        value: element.t_value,
                        wc_name: element.wc_name
                    });
                } else if (dd.length > 1 && !itExists) {
                    gridData.push({
                        t_payDate: element.t_payDate,
                        month: new Date(element.t_payDate).getMonth() + 1,
                        year: new Date(element.t_payDate).getFullYear(),
                        value: this.getSum(dd, 't_value'),
                        wc_name: element.wc_name
                    });
                }
                graphData.push({ day: element.t_payDate, value: element.t_value });
            });
            this.filter.wasteUom = 2;
            this.dsTax = gridData;
            this.dsTaxVal = this.getValues(graphData, 'value');
            this.total.totalTax = this.totalValueTxt(this.dsTaxVal, 'value');
        });
    }

    initInvoice(entity, supplier, isSales = false) {
        const obj = { entity: entity, supplier: supplier, fromDate: this.filter.fromDate, thruDate: this.filter.thruDate };
        this.ds.getInvoicesByDate(obj, isSales).then((res) => {
            const graphData = [];
            const gridData = [];

            res.data.forEach(element => {
                const dd = res.data.filter(x => new Date(x.date).getMonth() + 1 === new Date(element.date).getMonth() + 1);
                const itExists = gridData.find(x => x.month === new Date(element.date).getMonth() + 1);
                if (dd && dd.length === 1) {
                    gridData.push({
                        date: element.date,
                        month: new Date(element.date).getMonth() + 1,
                        year: new Date(element.date).getFullYear(),
                        amount: element.amount,
                        entityId: element.entityId,
                        supplierId: element.supplierId
                    });
                } else if (dd.length > 1 && !itExists) {
                    gridData.push({
                        date: element.date,
                        month: new Date(element.date).getMonth() + 1,
                        year: new Date(element.date).getFullYear(),
                        amount: this.getSum(dd, 'amount'),
                        entityId: element.entityId,
                        supplierId: element.supplierId
                    });
                }
                graphData.push({ day: element.date, amount: element.amount });
            });
            this.filter.wasteUom = 2;
            if (isSales) {
                this.dsCosts = gridData;
                this.dsCostVal = this.getValues(graphData, 'amount');
                this.total.totalCost = this.totalValueTxt(this.dsCostVal, 'amount');
            } else {
                this.dsIncomes = gridData;
                this.dsIncomeVal = this.getValues(graphData, 'amount');
                this.total.totalIncome = this.totalValueTxt(this.dsIncomeVal, 'amount');
            }
        });
    }

    initCapitalization(wasteType: string) {
        this.ds.getCapsByDate(wasteType, this.filter.fromDate, this.filter.thruDate).then((res) => {
            const graphData = [];
            const gridData = [];

            res.data.forEach(element => {
                const dd = res.data.filter(x => new Date(x.t_sendDate).getMonth() + 1 === new Date(element.t_sendDate).getMonth() + 1);
                const itExists = gridData.find(x => x.month === new Date(element.t_sendDate).getMonth() + 1);
                if (dd && dd.length === 1) {
                    gridData.push({
                        t_sendDate: element.t_sendDate,
                        month: new Date(element.t_sendDate).getMonth() + 1,
                        year: new Date(element.t_sendDate).getFullYear(),
                        vt: element.t_valuedQuantity,
                        quantity: element.t_quantity,
                        wt_code: element.wt_code
                    });
                } else if (dd.length > 1 && !itExists) {
                    gridData.push({
                        t_sendDate: element.t_sendDate,
                        month: new Date(element.t_sendDate).getMonth() + 1,
                        year: new Date(element.t_sendDate).getFullYear(),
                        vt: this.getSum(dd, 't_valuedQuantity'),
                        quantity: this.getSum(dd, 't_quantity'),
                        wt_code: element.wt_code
                    });
                }

                graphData.push({ day: element.t_sendDate, vl: element.t_valuedQuantity, qt: element.t_quantity });
            });
            this.dsCaps = gridData;
            this.dsVVal = this.getValues(graphData, 'vl');
            this.total.totalCapital = this.totalQuantityTxt(this.dsVVal, 'vl');
        });
    }

    initControls() {
        this.ds.getControlsByDate(this.filter.entity, this.filter.workCenter, this.filter.fromDate, this.filter.thruDate).then((res) => {
            const graphData = [];
            const gridData = [];

            res.data.forEach(element => {
                const dd = res.data.filter(x => new Date(x.c_date).getMonth() + 1 === new Date(element.c_date).getMonth() + 1);
                const itExists = gridData.find(x => x.month === new Date(element.c_date).getMonth() + 1);
                if (dd && dd.length === 1) {
                    gridData.push({
                        c_date: element.c_date,
                        month: new Date(element.c_date).getMonth() + 1,
                        year: new Date(element.c_date).getFullYear(),
                        c_cost: element.c_cost,
                        c_authority: element.c_authority,
                        wc_name: element.wc_name,
                        ent_name: element.ent_name
                    });
                } else if (dd.length > 1 && !itExists) {
                    gridData.push({
                        c_date: element.c_date,
                        month: new Date(element.c_date).getMonth() + 1,
                        year: new Date(element.c_date).getFullYear(),
                        c_cost: this.getSum(dd, 'c_cost'),
                        c_authority: element.c_authority,
                        wc_name: element.wc_name,
                        ent_name: element.ent_name
                    });
                }

                graphData.push({ day: element.c_date, c_cost: element.c_cost });
            });
            this.dsControls = gridData;
            this.dsControlVal = this.getValues(graphData, 'c_cost');
            this.filter.wasteUom = 2;
            this.total.totalControl = this.totalValueTxt(this.dsControlVal, 'c_cost');
        });
    }

totalQuantityTxt(ds: any, key: string) {
    return this.ds.lg.format('TOTAL_QUANTITY', null) + this.getSum(ds, key) + ' ' + (this.filter.wasteUom == 1 ? this.ds.lg.format('TONES', null) : this.ds.lg.format('MC', null));
}

totalValueTxt(ds: any, key: string) {
    return this.ds.lg.format('TOTAL_VALUE', null) + this.getSum(ds, key) + ' ' + this.ds.lg.format('CURRENCY', null);
}

    getValues(data: any, key: string) {
        const arr = [];
        data = data || [];
        const diffTime = Math.abs(new Date(this.filter.thruDate).getTime() - new Date(this.filter.fromDate).getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        for (let j = 0; j < diffDays; j++) {
            const fd = new Date(this.filter.fromDate);
            fd.setHours(0, 0, 0, 0);
            const currentFD = DataService.formatDate(new Date(fd.setDate(fd.getDate() + j)));
            const dd = data.filter(x => DataService.formatDate(new Date(x.day)) === currentFD);
            const obj = { day: dd.length ? DataService.formatDate(dd[0].day) : currentFD };
            obj[key] = dd.length ? this.getSum(dd, key) : 0;
            arr.push(obj);
        }
        return arr;
    }

    getSum(arr: any[], field: string) {
        let total = 0;
        for (const item of arr) {
            total += item[field];
        }
        return Math.floor(total * 100) / 100;
    }

}
