import { Injectable } from '@angular/core';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import { DataService } from '../../../service/data.service';
import { iBisEditService } from '@dohu/ibis-common';
import CustomStore from 'devextreme/data/custom_store';

@Injectable()
export class EditGenerateService extends iBisEditService {

    wasteType: string;
    dsMaterial: CustomStore;
    customValidation: string;
    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'gdValidation';
        this.customValidation = 'customTValidation';
    }

    public get title() {
        if (!this.model || !this.ds) {
            return '';
        }
        return this.ds.lg.format("WASTE_GENERATION");
    }

    getViewById(id: string) {
        return EntityStore.fromQuery(new EntityQuery('GenerateView').eq('g_id', id)).single();
    }

    createDefault() {
        const result: any = { source: 1, estimated: true, date: new Date() };
        if (this.wasteType == '1') {
            result.estimated = false;
        }
        return result;
    }

    getById(id: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.getViewById(id).then((obj) => {
                if (obj === null) {
                    reject();
                } else {
                    this.dsMaterial = this.ds.getEnumValue(obj.wt_code);
                    resolve({
                        id: obj.g_id,
                        entityId: obj.wc_parentId,
                        workCenterId: obj.g_workCenterId,
                        materialId: obj.g_materialId,
                        materialTypeId: obj.g_materialTypeId,
                        wasteTypeId: obj.g_wasteTypeId,
                        quantity: obj.g_quantity,
                        unitWeight: obj.g_unitWeight,
                        date: obj.g_date,
                        source: obj.g_source,
                        estimated: obj.g_estimated,
                        wt_name: obj.wt_name,
                        uom: DataService.getUOM(obj.wt_uom),
                        wt_hazardName: DataService.getHarzardName(obj.wt_hazard),
                        wt_storageTypeId: obj.wtp_storageTypeId,
                        physicalCondition: obj.g_physicalCondition,
                        observations: obj.g_observations
                    });
                }
            }, (e) => reject(e));
        });
    }

    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Generate')).remove(id);
    }

    onSaveEv(): Promise<any> {
        this.model.estimated = this.model.estimated === 1 ? true : false;
        const s = EntityStore.fromQuery(new EntityQuery('Generate'));
        return (this.model.id ? s.update(this.model.id, this.model) : s.insert(this.model));
    }

    reset(): void {
        this.model = this.createDefault();
    }
}
