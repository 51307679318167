import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html'
})
export class AuditComponent implements OnInit {
  dsAudit: any;

  constructor(public ds: DataService, public ui: UIService) { }

  ngOnInit() {
    this.dsAudit = this.ds.getAudit();
  }

}
