import { Component, OnInit, ViewChild, NgModule } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { UIService } from '../../../service/ui.service';
import { iBisAuthService, iBisEditUserService } from '@dohu/ibis-auth';
import { alert } from 'devextreme/ui/dialog';

@Component({
    templateUrl: 'user.component.html'
})
export class UserComponent implements OnInit {
    dsUsers: CustomStore;
    dsEnt: CustomStore;

    getFilteredWc: any;

    @ViewChild('gridUsers') gridUsers: DxDataGridComponent;
    @ViewChild('gridWorkstation') gridWorkstation: DxDataGridComponent;

    onEditUserClick: any;
    licenseCert: any;
    maxUsers: any;

    constructor(public ds: DataService, public ui: UIService, public auth: iBisAuthService, public edit: iBisEditUserService) {
        this.onEditUserClick = this.onEditUserClickEv.bind(this);
        this.getFilteredWc = this.getFilteredWcEv.bind(this);
    }

    ngOnInit() {
        this.dsUsers = this.ds.securityUsers();
        this.dsEnt = this.ds.getParty('ent');
        this.licenseCert = JSON.parse(sessionStorage.getItem('licenseCertQHSE'));
        this.maxUsers = this.ds.static.subscriptionData[this.licenseCert.type-1].users + this.licenseCert.extra['users'];
    }

    onEditUserClickEv(ev: any) {
        ev.event.preventDefault();
        this.edit.authServer = this.ds.environment.authServer;
        this.edit.showPopup(ev.row.data, true, this.ds.environment.saasServer).then(
            () => this.gridUsers.instance.refresh(),
            (error) => this.ds.serverError(error)
        );
    }

    getFilteredWcEv(options) {
        return {
            store: this.ds.getParty('wc'),
            filter: options.data ? ['parentId', '=', options.data.entityId] : null
        };
    }

    // master detail grid
    onWcContentReady(event: any, data: any) {
        if (event.component.option('dataSource') == null) {
            event.component.option('dataSource', this.ds.userLoginParty(data.u_id));
        }
    }

    onZoneContentReady(event: any, data: any) {
        if (event.component.option('dataSource') == null) {
            event.component.option('dataSource', this.ds.userLoginZone(data.u_id));
        }
    }

    onSetCellValueEntity(rowData: any, value: any): void {
        rowData.workCenterId = null;
        (<any>this).defaultSetCellValue(rowData, value);
    }

    onRowInserting(event: any, user) {
        event.data.userId = user.u_id;
    }
    toolbarPreparing(event: any, userGrid = false) {
        if (userGrid) {
            this.ui.prepareToolbar(event, true);
            event.toolbarOptions.items.unshift({
                widget: 'dxButton',
                options: {
                    hint: this.ds.lg.format('ADD_USER', null),
                    icon: 'assets/img/grid_add.svg',
                    onClick: (e: any) => {
                        this.edit.authServer = this.ds.environment.authServer;
                        this.ds.countUsers().then(res => {
                            if (res && res.reduce((a, b) => a.f1 + b.f1, 0) < this.maxUsers) {
                                this.edit.showPopup(null).then(() => this.gridUsers.instance.refresh(), () => { });
                            } else {
                                alert(this.ds.lg.format('USERS_EXCEEDED', null), this.ds.lg.format('LICENSE_RESTRICIONS'));
                            }
                        });
                }},
                location: 'after'
            });
        } else {
            this.ui.prepareToolbar(event, false);
        }
    }

    onCellPrepared(e: any) {
        if (e.rowType === 'data' && e.column.command === 'expand') {
            if (e.data.roles === 'admin') {
                e.cellElement.children[0].classList.remove('dx-datagrid-group-closed');
            }
        }
    }

    onCellClick(e: any) {
        if (e.rowType === 'data') {
            if (e.cellElement.classList[0] === 'dx-command-expand' && e.data.roles === 'admin') {
                e.component.collapseAll(-1);
            }
        }
    }
}
