import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';
import { EditTransferService } from './editTransfer.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-transfer',
    templateUrl: './transfer.component.html'
})
export class TransferComponent implements OnInit {
    dsTransfer: CustomStore;
    onEditTransferClick: any;
    onDeleteTransferClick: any;

    @ViewChild('gridTransfer') gridTransfer: DxDataGridComponent;

    constructor(public ds: DataService, public ui: UIService, public edit: EditTransferService,
        public activeRoute: ActivatedRoute) {
        this.onEditTransferClick = this.onEditTransfer.bind(this);
        this.onDeleteTransferClick = this.onDeleteTransfer.bind(this);

        this.activeRoute.queryParams.subscribe(params => {
            this.edit.wasteTypeId = params['wasteType'];

            this.dsTransfer = this.ds.getTransferView(this.edit.wasteTypeId);
        });
    }

    ngOnInit() {
    }

    onEditTransfer(e: any) {
        e.event.preventDefault();
        this.edit.showPopup(e.row.data.t_id, true).then(() => this.gridTransfer.instance.refresh());
    }

    onDeleteTransfer(e: any) {
        e.event.preventDefault();
        this.edit.remove(e.row.data.t_id);
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);

        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                icon: 'assets/img/grid_add.svg', hint: this.ds.lg.format("ADD_ROW", null), onClick: () =>
                    this.edit.showPopup(null).then(() => event.component.refresh())
            },
            location: 'after'
        });
    }
}
