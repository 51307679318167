import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { UIService } from '../../../service/ui.service';
import { AnalizeService } from '../analize.service';

@Component({
  selector: 'app-cost-analyzes',
  templateUrl: './cost-analyzes.component.html'
})
export class CostAnalyzesComponent implements OnInit {

  dsEntities: CustomStore;
  dsContractors: CustomStore;

  constructor(public ds: DataService, public ui: UIService, public analyze: AnalizeService) { }

  ngOnInit() {
    this.dsContractors = this.ds.getParty('ct');
    this.dsEntities = this.ds.getParty('ent');
  }

  toolbarPreparing(event: any) {
    this.ui.prepareToolbar(event, true);
  }

}
