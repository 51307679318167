import { Component, OnInit } from '@angular/core';
import { DataService } from '../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';

@Component({
    templateUrl: 'reportInt.component.html'
})
export class ReportIntComponent implements OnInit {

    dsBu: CustomStore;
    dsEntities: CustomStore;
    dsContractors: CustomStore;
    dsWasteType: CustomStore;

    currentYear: any;
    dsMonth: any;
    dsSemester: any;
    reportObj: any;

    constructor(public ds: DataService) { }

    ngOnInit() {
        this.currentYear = 2018;
        this.dsMonth = this.ds.static.months;
        this.dsSemester = this.ds.getSemester();
        this.dsBu = this.ds.getParty('bu');
        this.dsEntities = this.ds.getParty('ent');
        this.dsContractors = this.ds.getParty('ct');
        this.dsWasteType = this.ds.wasteType();


        this.reportObj = {
            bu: null, entity: null, workCenter: null, sem: null, fromDate: this.ds.firstMonthDay, thruDate: this.ds.lastMonthDay
        };
    }
}
