import { Injectable } from '@angular/core';
import { EditTransportService } from '../editTransport.service';
import { DataService } from '../../../../service/data.service';

@Injectable()
export class FinishTransportService extends EditTransportService {

    constructor(public ds: DataService) {
        super(ds);
        this.validation = 'finishValidation';
    }
}
