import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import { environment } from '../../../environments/environment';
import notify from 'devextreme/ui/notify';
import { DxFormComponent } from 'devextreme-angular';
import { DataService } from '../../service/data.service';
import { UIService } from '../../service/ui.service';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html'
})
export class AddCompanyComponent implements OnInit {

  newCompanyData: any;
  @Input() popupVisible: boolean;
  @Output() onClosePopup = new EventEmitter<any>();

  @ViewChild('addCompanyForm') addCompanyForm: DxFormComponent;
  onSaveCompany: any;
  constructor(private auth: iBisAuthService, public ds: DataService, public ui: UIService) { }

  ngOnInit() {
    this.newCompanyData = {};
    this.onSaveCompany = this.onSaveCompanyEv.bind(this);
  }

  onSaveCompanyEv(e: any) {
    this.auth.getFullUser().then(result => {
      const fields = ['_Companie_', '_CIF_', '_Telefon_'];
      const values = [this.newCompanyData.name, this.newCompanyData.cif, null];
      const obj = {
        registerType: 'QHSE',
        fullName: result.fullName,
        emailAddress: result.emailAddress,
        deployId: environment.deployId,
        fields: fields,
        values: values
      };
      this.auth.register(obj).then(() => {
        notify(this.ds.lg.format('REQUEST_PROCESS', null), 'success', 3000);
        this.newCompanyData = {};
        this.popupVisible = false;
        this.onClosePopup.emit(false);
        this.addCompanyForm.instance.resetValues();
      });
    }, err => {
      this.ds.serverError(err);
    });
  }

  onAddCompanyHidden(e: any) {
    this.ui.onPopupHidden(e);
    this.newCompanyData = {};
    this.addCompanyForm.instance.resetValues();
    this.onClosePopup.emit(false);
  }
}
