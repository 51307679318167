import * as nav from '../assets/navigation.json';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { routes } from './app.route';

import {
  DxDataGridModule, DxMenuModule, DxButtonModule, DxTextBoxModule, DxDateBoxModule, DxSelectBoxModule, DxPopupModule, DxTextAreaModule,
  DxFileUploaderModule, DxLoadPanelModule, DxValidatorModule, DxTabPanelModule, DxFormModule, DxToolbarModule, DxAutocompleteModule,
  DxTagBoxModule, DxLookupModule, DxCheckBoxModule, DxPieChartModule, DxChartModule, DxRangeSelectorModule, DxListModule, DxBoxModule,
  DxTabsModule, DxMultiViewModule, DxPopoverModule, DxValidationGroupModule, DxScrollViewModule, DxTreeListModule,
  DxCalendarModule, DxValidationSummaryModule, DxNumberBoxModule, DxDropDownBoxModule, DxSwitchModule
} from 'devextreme-angular';

import { AppComponent } from './app.component';
import { AddCompanyComponent } from './common/add-company/add-company.component';
import { LoginComponent } from './view/login/login.component';
import { LoginInfoComponent } from './view/login-info/login-info.component';
import { EliminateComponent } from './view/waste/eliminate/eliminate.component';
import { TransportComponent } from './view/waste/transport/transport.component';
import { TaxComponent } from './view/document/tax/tax.component';
import { ControlComponent } from './view/waste/control/control.component';
import { AlertComponent } from './view/alert/alert.component';
import { ReportExtComponent } from './view/report/reportExt.component';
import { ReportIntComponent } from './view/report/reportInt.component';
import { AgencyComponent } from './view/admin/agency/agency.component';
import { PartyComponent } from './view/admin/party/party.component';
import { EditPartyComponent } from './view/admin/party/editParty.component';
import { WastetypeComponent } from './view/admin/wastetype/wastetype.component';
import { CapitalizationComponent } from './view/waste/capitalization/capitalization.component';
import { MonthlyComponent } from './view/waste/monthly/monthly.component';
import { GeographicareaComponent } from './view/admin/geographicarea/geographicarea.component';
import { WastefileComponent } from './view/document/wastefile/wastefile.component';
import { EditControlComponent } from './view/waste/control/editControl.component';
import { EditActionComponent } from './view/waste/control/editAction.component';
import { EditSanctionComponent } from './view/waste/control/editSanction.component';
import { EditMonthlyComponent } from './view/waste/monthly/editMonthly.component';
import { EditTaxComponent } from './view/document/tax/editTax.component';
import { ArchiveComponent } from './view/document/archive/archive.component';
import { EditArchiveComponent } from './view/document/archive/editArchive.component';
import { PermitComponent } from './view/document/permit/permit.component';
import { EditPermitComponent } from './view/document/permit/editPermit.component';
import { EditPermitService } from './view/document/permit/editPermit.service';
import { InvoiceComponent } from './view/document/invoice/invoice.component';
import { EditInvoiceComponent } from './view/document/invoice/editInvoice.component';
import { EditAgencyComponent } from './view/admin/agency/editAgency.component';
import { EditGareasComponent } from './view/admin/geographicarea/editGarea.component';

import { UserComponent } from './view/admin/user/user.component';

import { EditPopupComponent } from './common/edit-popup/edit-popup.component';

import { EditWasteTypeComponent } from './view/admin/wastetype/editWastetype.component';
import { EditWastetypeDetailComponent } from './view/admin/wastetype/editWastetypeDetail.component';
import { NomenclatureComponent } from './view/admin/nomenclature/nomenclature.component';
import { EditNomenclatureComponent } from './view/admin/nomenclature/editNomenclature.component';
import { EditPartyLinkComponent } from './view/admin/party/editPartyLink.component';
import { EditPartyWorkCenterComponent } from './view/admin/party/editPartyWorkCenter.component';
import { RegisterComponent } from './view/register/register.component';
import { FileUploadComponent } from './common/file-upload/file-upload.component';
import { AccountComponent } from './view/account/account.component';
import { iBisAuthService, iBisEditUserService, iBisAuthModule } from '@dohu/ibis-auth';
import { iBisCommonModule, iBisGridConfigService, iBisLanguageService, iBisFileService } from '@dohu/ibis-common';
import { iBisNavigationModule } from '@dohu/ibis-navigation';
import { ScreenService } from './service/screen.service';
import { SingleCardModule } from './common/single-card/single-card.component';
import { TransferComponent } from './view/waste/transfer/transfer.component';
import { EditTransferComponent } from './view/waste/transfer/editTransfer.component';
import { CreateTicketModule } from './common/create-ticket/create-ticket.component';
import { EditGenerateService } from './view/waste/generate/editGenerate.service';
import { GenerateComponent } from './view/waste/generate/generate.component';
import { UnitComponent } from './view/admin/unit/unit.component';
import { EditUnitService } from './view/admin/unit/editUnit.service';
import { EditUnitWasteService } from './view/admin/unit/editUnitWaste.service';
import { EditPartyService } from './view/admin/party/editParty.service';
import { EditPartyLinkService } from './view/admin/party/editPartyLink.service';
import { EditTransportService, FinishTransportService, EditFullTransportComponent, FullTransportComponent
} from './view/waste/transport/index';
import { EditTransferService } from './view/waste/transfer/editTransfer.service';
import { ProxyService } from './service/proxy.service';
import { AnalizeModule } from './view/analize/analize.module';
import { AnalizeService } from './view/analize/analize.service';
import { TransporterComponent } from './view/admin/transporter/transporter.component';
import { EditTransporterComponent } from './view/admin/transporter/editTransporter.component';
import { EditTransporterService } from './view/admin/transporter/editTransporter.service';
import { ContractComponent } from './view/document/contract/contract.component';
import { EditContractComponent, EditContractService } from './view/document/contract/index';
import { TreatmentItemComponent, TreatmentComponent,
    EditTreatmentComponent, EditTreatmentService, EditActualStockService, EditActualStockComponent
} from './view/waste/treatment/index';
import { ProjectComponent, EditProjectComponent, EditProjectService } from './view/document/project/index';
import { EditCollectService, CollectComponent, EditCollectComponent } from './view/waste/collect/index';
import {
  TransportApprovalComponent, EditTransportApprovalService,
  EditTransportApprovalComponent
} from './view/document/transportApproval/index';
import { IncinerateComponent } from './view/waste/incinerate/incinerate.component';
import { EditIncinerateComponent } from './view/waste/incinerate/editIncinerate.component';
import { EditIncinerateService } from './view/waste/incinerate/editIncinerate.service';
import { AuditComponent } from './view/admin/audit/audit.component';
import { ActualStockComponent } from './view/waste/actualStock/actualStock.component';
import { GeneralReportComponent } from './view/report/generalReport.component';
import { ScaleComponent } from './view/waste/scale/scale.component';
import { BillingComponent } from './view/account/billing/billing.component';
import { HistoryComponent } from './view/account/history/history.component';
import { InvoiceItemComponent } from './view/document/invoice-item/invoice-item.component';
import { EditInvoiceItemComponent } from './view/document/invoice-item/editInvoiceItem.component';

import { DataService } from './service/data.service';
import { UIService } from './service/ui.service';
import { MirrorStockComponent } from './view/waste/mirrorStock/mirrorStock.component';

@NgModule({
  declarations: [
    AppComponent,
    AddCompanyComponent,
    EditPopupComponent,
    LoginComponent,
    LoginInfoComponent,
    GenerateComponent,
    EliminateComponent,
    TransportComponent,
    EditPartyComponent,
    TaxComponent,
    ControlComponent,
    PermitComponent,
    WastefileComponent,
    InvoiceComponent,
    AlertComponent,
    ReportExtComponent,
    ReportIntComponent,
    AgencyComponent,
    PartyComponent,
    UnitComponent,
    WastetypeComponent,
    CapitalizationComponent,
    MonthlyComponent,
    ArchiveComponent,
    GeographicareaComponent,
    NomenclatureComponent,
    UserComponent,
    ScaleComponent,
    FileUploadComponent,
    TransporterComponent,
    TreatmentComponent,
    EditControlComponent,
    EditActionComponent,
    EditSanctionComponent,
    EditMonthlyComponent,
    EditTaxComponent,
    EditArchiveComponent,
    EditInvoiceComponent,
    EditInvoiceItemComponent,
    EditAgencyComponent,
    EditGareasComponent,
    EditWasteTypeComponent,
    EditWastetypeDetailComponent,
    EditNomenclatureComponent,
    EditPartyLinkComponent,
    EditPartyWorkCenterComponent,
    EditTransporterComponent,
    EditTransferComponent,
    EditProjectComponent,
    EditActualStockComponent,
    EditContractComponent,
    EditPermitComponent,
    // EditTransportComponent,
    // FinishTransportComponent,
    // GenerareTransportComponent,
    RegisterComponent,
    FileUploadComponent,
    AccountComponent,
    BillingComponent,
    HistoryComponent,
    TransferComponent,
    ContractComponent,
    ProjectComponent,
    CollectComponent,
    EditCollectComponent,
    TransportApprovalComponent,
    EditTransportApprovalComponent,
    EditTreatmentComponent,
    IncinerateComponent,
    EditIncinerateComponent,
    EditFullTransportComponent,
    FullTransportComponent,
    TreatmentItemComponent,
    AuditComponent,
    ActualStockComponent,
    GeneralReportComponent,
    InvoiceItemComponent,
    MirrorStockComponent
  ],
  imports: [
    BrowserModule,
    iBisNavigationModule.forRoot({ navigation: nav.default, showCompanies: true }),
    SingleCardModule,
    AnalizeModule,

    HttpClientModule,
    DxMenuModule,
    DxButtonModule,
    DxDataGridModule,
    DxMenuModule,
    DxTagBoxModule,
    DxButtonModule,
    DxTextBoxModule,
    DxNumberBoxModule,
    DxDateBoxModule,
    DxSelectBoxModule,
    DxPopupModule,
    DxTextAreaModule,
    DxFileUploaderModule,
    DxValidationGroupModule,
    DxValidationSummaryModule,
    DxLoadPanelModule,
    DxListModule,
    DxPopoverModule,
    DxBoxModule,
    DxValidatorModule,
    DxCalendarModule,
    DxTabPanelModule,
    DxFormModule,
    DxTabsModule,
    DxToolbarModule,
    DxAutocompleteModule,
    DxLookupModule,
    DxCheckBoxModule,
    DxPieChartModule,
    DxChartModule,
    DxRangeSelectorModule,
    DxMultiViewModule,
    DxScrollViewModule,
    DxDropDownBoxModule,
    DxSwitchModule,
    DxTreeListModule,
    iBisAuthModule,
    iBisCommonModule,
    CreateTicketModule,
    RouterModule.forRoot(routes, { useHash: true, enableTracing: false })
  ],
  providers: [
    DataService,
    ProxyService,
    UIService,
    EditGenerateService,
    EditUnitService,
    EditUnitWasteService,
    EditPartyService,
    EditPartyLinkService,
    EditTransportService,
    EditTransporterService,
    FinishTransportService,
    EditTransferService,
    EditProjectService,
    EditContractService,
    EditTreatmentService,
    EditCollectService,
    EditActualStockService,
    EditTransportApprovalService,
    EditIncinerateService,
    EditPermitService,
    AnalizeService,
    ScreenService,
    iBisAuthService,
    iBisGridConfigService,
    iBisEditUserService,
    HttpClient,
    iBisLanguageService,
    iBisFileService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
