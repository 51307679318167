
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { UIService } from '../../../service/ui.service';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    templateUrl: 'monthly.component.html'
})
export class MonthlyComponent implements OnInit {
    dsMonthly: CustomStore;
    dspEntities: CustomStore;
    dspWorkCenter: CustomStore;

    onWasteChange: any;
    onWcChange: any;
    onWcInit: any;
    onWasteCodeInit: any;
    WasteCode: any;
    monthly: any;
    recheckData: any;
    dsMonths: any;

    isMonthlyVisible: boolean;
    isRecheckMonthlyVisible: boolean;

    @ViewChild('gridMonthly') gridMonthly: DxDataGridComponent;
    onEditMonthlyClick: any;
    onDeleteMonthlyClick: any;
    onRecheckMonthlyClick: any;

    onSaveGenerateAdj: any;
    onSaveRecheck: any;
    year: { min: number; current: number; };

    constructor(public ds: DataService, public ui: UIService) {
        this.onEditMonthlyClick = this.onEditMonthly.bind(this);
        this.onDeleteMonthlyClick = this.onDeleteMonthly.bind(this);
        this.onRecheckMonthlyClick = this.onRecheckMonthly.bind(this);

        this.onSaveGenerateAdj = this.onSaveGenerateAdjEv.bind(this);
        this.onSaveRecheck = this.onSaveRecheckEv.bind(this);
        this.year = { min: new Date().getFullYear() - 15, current: new Date().getFullYear() };
    }

    ngOnInit() {
        this.dsMonthly = this.ds.getMonthly();
        this.dsMonths = this.ds.static.months;
        this.isMonthlyVisible = false;
        this.isRecheckMonthlyVisible = false;
        this.monthly = {};
    }

    addNewRow() {
        this.isMonthlyVisible = true;
        this.monthly = { id: undefined };

    }

    onRecheckMonthly(e: any) {
        e.event.preventDefault();
        this.recheckData = {};
        this.isRecheckMonthlyVisible = true;
    }

    onSaveRecheckEv(e: any) {
        e.event.preventDefault();
        const validation = e.validationGroup.validate();
        if (!validation.isValid) {
            notify(this.ds.lg.format('ALL_REQ_FIELDS', null), 'error', 3000);
            return;
        }
        this.ds.recheckMonthlyStock(this.recheckData.month, this.recheckData.year).then((result) => {
            this.gridMonthly.instance.refresh();
        }, err => notify(err, 'error', 3000));
    }

    onEditMonthly(e: any) {
        e.event.preventDefault();
        this.ds.getMonthlyById(e.row.data.id).then((result: any) => {
            this.ds.getWasteTypeById(e.row.data.wasteTypeId).then((waste) => {
                Object.assign(this.monthly, result);
                this.monthly.hazardName = DataService.getHarzardName(waste.hazard);
                this.monthly.wt_name = waste.name;
                this.isMonthlyVisible = true;
            });
        }, err => {
            this.ds.serverError(err);
        });
    }
    onDeleteMonthly(e: any) {
        e.event.preventDefault();
        confirm(this.ds.lg.format("DELETE_RECORD", null), this.ds.lg.format("CONFIRM", null)).then(val => {
            if (val) {
                this.ds.removeAdjustment(e.row.data.id).then(() => {
                    e.component.refresh();
                    notify(this.ds.lg.format("DELETE_SUCCESS", null), 'success', 3000);
                }, err => {
                    this.ds.serverError(err);
                });
            }
        });
    }

    onSaveGenerateAdjEv(event: any) {
        event.event.preventDefault();
        const validation = event.validationGroup.validate();
        if (!validation.isValid) {
            notify(this.ds.lg.format("ALL_REQ_FIELDS", null), 'error', 3000);
            return;
        }

        const obj = {
            wasteTypeId: this.monthly.wasteTypeId,
            workCenterId: this.monthly.workCenterId,
            adjustment: this.monthly.adjustment,
            month: this.monthly.month,
            year: this.monthly.year
        };

        this.ds.checkForAdjustment(obj).then((result) => {
            if (result && !this.monthly.id) {
                notify(this.ds.lg.format("REQUEST_EXIST", null), 'error', 3000);
                return;
            }
            this.ds.addEditAdjustment(obj, this.monthly.id).then(() => {
                this.whenDone();
            }, err => { this.ds.serverError(err); });
        });
    }

    whenDone() {
        this.isMonthlyVisible = false;
        this.monthly = {};
        notify('Success', 'success', 2000);
        this.gridMonthly.instance.refresh();
    }

    onRowPrepared(rowInfo: any) {
        if (rowInfo.rowType === 'data' && rowInfo.data.stock < 0) {
            rowInfo.rowElement.className = rowInfo.rowElement.className + ' stockAlert';
        }
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                icon: 'assets/img/grid_add.svg', hint: this.ds.lg.format("ADD_ROW", null), onClick: (e: any) => this.addNewRow()
            },
            location: 'after'
        });
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                icon: 'assets/img/recheck.svg', hint: this.ds.lg.format("UPDATE", null), onClick: (e: any) => this.onRecheckMonthly(e)
            },
            location: 'after'
        });
    }
}
