import { Component, OnInit, ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { DxDataGridComponent } from 'devextreme-angular';
import { UIService } from '../../../service/ui.service';
import { DataService } from '../../../service/data.service';
import { iBisFileService } from '@dohu/ibis-common';

@Component({
    templateUrl: 'archive.component.html'
})
export class ArchiveComponent implements OnInit {


    dsFileInfo: CustomStore;
    dsArchive: CustomStore;
    pWithActiv: CustomStore;
    isArchivePopupVisible: boolean;
    onEditArchiveClick: any;
    onDeleteArchiveClick: any;
    dsReportType: any;
    archive: any;

    @ViewChild('gridArchive') gridArchive: DxDataGridComponent;
    onSaveArchive: any;
    onDownloadFileClick: any;

    constructor(public ds: DataService, public ui: UIService, private file: iBisFileService) {
        this.onEditArchiveClick = this.onEditArchive.bind(this);
        this.onDeleteArchiveClick = this.onDeleteArchive.bind(this);
        this.onDownloadFileClick = this.onDownloadClickEv.bind(this);

        this.onSaveArchive = this.onSaveArchiveEv.bind(this);
    }

    ngOnInit() {
        this.dsArchive = this.ds.getArchive();
        this.pWithActiv = this.ds.getParty();
        this.dsReportType = this.ds.getReportType();
        this.isArchivePopupVisible = false;
        this.archive = {};
    }

    addNewRow(e) {
        this.isArchivePopupVisible = true;
        this.archive = {};
    }

    onDownloadClickEv(e: any) {
        this.file.Download(e.row.data.fileId).then(
            () => { notify(this.ds.lg.format('FILES_DOWNLOADED', null), 'success', 2000); },
            () => { notify(this.ds.lg.format('DOWNLOAD_ERROR', null), 'error', 3000); });
    }

    getReportPeriod = (rowData) => {
        let period = '';
        if (rowData.typeId === 'Anexa1') {
            period += rowData.month === -1 || rowData.month === null ? '' : this.ds.static.months[rowData.month - 1].name + ' ';
        }
        if (rowData.typeId.includes('Uleiuri')) {
            const sem = this.ds.getSemester().find(x => x.id === rowData.semester);
            period += rowData.semester === 0 || rowData.semester === null ? '' :
                    sem.name + ' ';
        }
        if (rowData.year ) {
            period += rowData.year.toString();
        }
        return period;
    }

    onSaveArchiveEv(event: any) {
        event.event.preventDefault();
        const validation = event.validationGroup.validate();
        if (!validation.isValid) {
            notify(this.ds.lg.format('ALL_REQ_FIELDS', null), 'error', 3000);
            return;
        }
        const obj = {
            typeId: this.archive.typeId,
            partyId: this.archive.partyId,
            date: this.archive.date,
            comments: this.archive.comments,
        };

        this.ds.addEditArchive(obj, this.archive.id).then((result: any) => {
            this.whenDone();
        }, error => {
            this.ds.serverError(error);
        });
    }

    onEditArchive(e: any) {
        e.event.preventDefault();
        this.isArchivePopupVisible = true;
        this.archive = e.row.data;
    }

    onDeleteArchive(e: any) {
        e.event.preventDefault();
        confirm(this.ds.lg.format('DELETE_RECORD', null), this.ds.lg.format('CONFIRM', null)).then(val => {
            if (val) {
                this.ds.removeArchive(e.row.data.id).then(() => {
                    notify('Success', 'success', 2000);
                    this.gridArchive.instance.refresh();
                }, err => {
                    this.ds.serverError(err);
                });
            }
        });
    }

    whenDone() {
        this.isArchivePopupVisible = false;
        this.archive = {};
        notify('Success', 'success', 2000);
        this.gridArchive.instance.refresh();
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
        // event.toolbarOptions.items.unshift({
        //     widget: 'dxButton',
        //     options: {
        //         icon: 'assets/img/grid_add.svg', hint: 'Adaugă rând', onClick: (e: any) => {
        //             this.addNewRow(null);
        //         }
        //     },
        //     location: 'after'
        // });
    }

}
