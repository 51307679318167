import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { Router } from '@angular/router';

@Component({
  selector: 'app-single-card',
  templateUrl: './single-card.component.html',
  styleUrls: ['./single-card.component.scss']
})
export class SingleCardComponent {
  constructor(public router: Router) {
    setTimeout(() => {
      if (this.router.url === '/') {
        this.router.navigateByUrl('/login');
      }
    }, 0);
  }

  checkUrlForDxCard() {
    return (this.router.url.indexOf('/confirmInvite') > -1) || (this.router.url.indexOf('/resetPassword') > -1);
  }
}

@NgModule({
  imports: [CommonModule, DxScrollViewModule],
  exports: [SingleCardComponent],
  declarations: [SingleCardComponent]
})
export class SingleCardModule { }
