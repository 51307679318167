import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';
import { EditIncinerateService } from './editIncinerate.service';
import CustomStore from 'devextreme/data/custom_store';
import { EditActualStockService } from '../treatment/editActualStock.service';
import { DxFormComponent } from 'devextreme-angular';
@Component({
    selector: 'app-edit-incinerate',
    templateUrl: './editIncinerate.component.html'
})
export class EditIncinerateComponent implements OnInit {

    dsWorkCenter: CustomStore;
    dsDevice: any;
    dsElimination: CustomStore;

    @ViewChild('incinerateForm') incinerateForm: DxFormComponent;
    constructor(public ds: DataService, public ui: UIService, public edit: EditIncinerateService, public editS: EditActualStockService) {
    }

    ngOnInit() {
        this.dsWorkCenter = this.ds.getPartyLinkView([2]);
        this.dsElimination = this.ds.getEnumValue('Eliminare');
    }

    onShownIncinerate(ev: any) {

        if (this.edit.model.wasteTypeId) {
            this.setWasteDetails(this.edit.model.wasteTypeId);
        }
    }
    onWasteCodeChange = (ev: any) => {
        this.incinerateForm.instance.focus();
    }

    onOpenStockPopup = (ev: any) => {
        this.editS.showPopup({
            edit: this.edit.model.id ? true : false,
            eliminationId: this.edit.model.id,
            wasteTypeId: this.edit.model.wasteTypeId,
            workCenterId: this.edit.model.workCenterId,
            dsSelected: this.edit.dsTreatmentSource
        }, true).then(data => {
            if (data && data.isSaving) {
                this.edit.model.quantity = data.totalQuantity;
                this.edit.model.wasteTypeId = data.wasteTypeId;
                this.edit.dsTreatmentSource = data.dsSource;
                if (this.edit.model.wasteTypeId) {
                    this.setWasteDetails(this.edit.model.wasteTypeId);
                }
            }
        });
    }

    setWasteDetails(id: string) {
        this.ds.getWasteTypeById(id).then(dr => {
            if (dr) {
                this.edit.model.hazardName = DataService.getHarzardName(dr.hazard);
                this.edit.model.wasteCode = dr.code;
                this.edit.model.wasteName = dr.name;
                this.edit.model.uom = DataService.getUOM(dr.uom);
                this.edit.model.physicalCondition = dr.physicalCondition;
            }
        });
    }
}
