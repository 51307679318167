import { Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../service/data.service';
import { UIService } from '../../service/ui.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-general-report',
  templateUrl: './generalReport.component.html'
})
export class GeneralReportComponent implements OnInit {

  dsGeneralReport: CustomStore;
  constructor(public ds: DataService, public ui: UIService) { }

  ngOnInit() {
    this.dsGeneralReport = this.ds.getGeneralReport();
  }

  getActions(type: number, actions: number) {
    if (type == 4) { // Full Transport
      const arr = this.ds.static.transportActions;
      const result = [];
      for (const i of arr) {
        // tslint:disable-next-line:no-bitwise
        if ((actions & i.id) === i.id) {
          result.push(i.value);
        }
      }
      return result.join(', ');
    } else if (type == 5) { // OUT treatment
      const dsTreatmentType = this.ds.getTreatmentType('eliminare');
      const result = dsTreatmentType.find(x => x.id = actions);
      if (result) {
        return result.value;
      }
    }
  }
  onToolBarPreparingEv(event: any) {
    this.ui.prepareToolbar(event);
    event.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'exportxlsx', hint: 'Export',
        onClick: (e: any) => {
          notify('Downloading in progress...', 'info', 3000);
        }
      },
      location: 'after'
    });
  }
}
