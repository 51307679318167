import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import { EditTransportApprovalService } from './editTransportApproval.service';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-transport-approval',
  templateUrl: './transportApproval.component.html'
})
export class TransportApprovalComponent implements OnInit {

  dsTransportApproval: CustomStore;
  onEditTransportApprovalClick: any;
  onDeleteTransportApprovalClick: any;

  @ViewChild('gridTransportApproval') gridTransportApproval: DxDataGridComponent;

  constructor(public ds: DataService, public ui: UIService, public edit: EditTransportApprovalService) {
    this.onEditTransportApprovalClick = this.onEditTransportApprovalClickEv.bind(this);
    this.onDeleteTransportApprovalClick = this.onDeleteTransportApprovalClickEv.bind(this);
  }

  ngOnInit() {
    this.dsTransportApproval = this.ds.getTransportApprovalView();
  }

  mainToolbarPreparing(ev: any) {
    this.ui.prepareToolbar(ev, true);
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        hint: 'Adaugă fisă',
        icon: 'plus',
        onClick: (e: any) => this.edit.showPopup(null).then(
          () => this.gridTransportApproval.instance.refresh(), () => { })
      },
      location: 'after'
    });
  }

  onEditTransportApprovalClickEv(ev: any) {
    ev.event.preventDefault();
    this.edit.showPopup(ev.row.data, true).then(
      () => this.gridTransportApproval.instance.refresh(),
      (error) => this.ds.serverError(error)
    );
  }

  onDeleteTransportApprovalClickEv(ev: any) {
    ev.event.preventDefault();
    confirm('Ești sigur că vrei să ștergi această înregistrare ?', 'Confirmare').then(val => {
      if (val) {
        this.ds.removeTransportApproval(ev.row.data.ta_id).then(() => {
          notify('Success', 'success', 2000);
          this.gridTransportApproval.instance.refresh();
        }, err => {
          this.ds.serverError(err);
        });
      }
    });
  }

  calculateSortTransValue(rowInfo: any) {
    return this.calculateTransLeft(rowInfo);
  }

  calculateSortQtyValue(rowInfo: any) {
    return this.calculateQtyLeft(rowInfo);
  }

  calculateTransLeft(rowInfo: any) {
    return (rowInfo.ta_approvedNo ? rowInfo.ta_approvedNo : 0) -
      (rowInfo.c_no ? rowInfo.c_no : 0) - (rowInfo.t_no ? rowInfo.t_no : 0);
  }

  calculateQtyLeft(rowInfo: any) {
    return (rowInfo.ta_approvedQuantity ? rowInfo.ta_approvedQuantity : 0) -
      (rowInfo.c_quantity ? rowInfo.c_quantity : 0) - (rowInfo.t_quantity ? rowInfo.t_quantity : 0);
  }

  calculateLeft(noAproved?: number, numberUsed?: number, tqn?: number): number {
    return (noAproved ? noAproved : 0) - (numberUsed ? numberUsed : 0) - (tqn ? tqn : 0);
  }

  onRowPrepared(rowInfo: any) {
    if (rowInfo.rowType === 'data') {
      if ((((rowInfo.data.c_quantity ? rowInfo.data.ta_approvedQuantity - rowInfo.data.c_quantity : rowInfo.data.ta_approvedQuantity) <= 0) ||
        ((rowInfo.data.c_no ? rowInfo.data.ta_approvedNo - rowInfo.data.c_no : rowInfo.data.ta_approvedNo) <= 0))) {
        rowInfo.rowElement.className = rowInfo.rowElement.className + ' bold';
      }
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (new Date(rowInfo.data.ta_expireDate) < today) {
        rowInfo.rowElement.className = rowInfo.rowElement.className + ' fatExpired';
      }
    }
  }

  calculateCustom(options) {
    if (options.name === 'transLeft') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        const data = (options.value.ta_approvedNo ? options.value.ta_approvedNo : 0) -
          (options.value.c_no ? options.value.c_no : 0) - (options.value.t_no ? options.value.t_no : 0);
        options.totalValue = Math.round((options.totalValue + data) * 1000) / 1000;
      }
    } else if (options.name === 'quantLeft') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        const data = (options.value.ta_approvedQuantity ? options.value.ta_approvedQuantity : 0) -
          (options.value.c_quantity ? options.value.c_quantity : 0) - (options.value.t_quantity ? options.value.t_quantity : 0);
        options.totalValue = Math.round((options.totalValue + data) * 1000) / 1000;
      }
    }
  }

}
