import { Injectable } from '@angular/core';
import { iBisEditService } from '@dohu/ibis-common';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { DataService } from '../../../service/data.service';
import * as st from '../../../../assets/static.json';
import notify from 'devextreme/ui/notify';

@Injectable()
export class EditTransportService extends iBisEditService {

    public validation: string;
    public customValidation: string;
    public transportActions: any;
    wasteType: string;
    dsTransportSource: any;
    dsMaterial: any;
    dsEEECategory: any;

    constructor(public ds: DataService) {
        super(ds.lg);
        this.dsTransportSource = [];
        this.validation = 'editValidation';
        this.customValidation = 'customTValidation';
        this.transportActions = st.default.transportActions;
        this.dsEEECategory = this.ds.getEnumValue('EEE');
    }

    public get title() {
        if (!this.model || !this.ds) {
            return '';
        }
        return this.model.t_id ? this.ds.lg.format('EDIT_TRANSPORT') : this.ds.lg.format('ADD_TRANSPORT')
    }

    protected setTransportDataSource(actionNo: number) {
        if (!actionNo) { return []; }
        const result = [];
        for (let index = this.transportActions.length - 1; index >= 0; index--) {
            if (this.transportActions[index].id <= actionNo) {
                result.push(this.transportActions[index].id);
                actionNo -= this.transportActions[index].id;
            }
            if (actionNo == 0) {
                break;
            }
        }
        return result;
    }

    createDefault(): any {
        const result = { t_weighing: 1, t_sendDate: new Date(), selectedActions: [], wc_parentId: null, t_workCenterId: null, uom: null };
        if (this && this.ds && this.ds.defaultParty) {
            result.wc_parentId = this.ds.defaultParty.ent;
            result.t_workCenterId = this.ds.defaultParty.wc;
        }
        this.dsTransportSource = [];
        return result;
    }

    getById(id: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            EntityStore.fromQuery(new EntityQuery('TransportView').eq('t_id', id)).single().
                then((obj) => {
                    if (obj === null) {
                        reject();
                    } else {
                        this.dsTransportSource = [];
                        obj.selectedActions = this.setTransportDataSource(obj.t_actions);
                        obj.wt_hazardName = DataService.getHarzardName(obj.wt_hazard);
                        obj.wt_uom = DataService.getUOM(obj.wt_uom);
                        if (this.wasteType == '1') {
                            this.dsMaterial = this.ds.getEnumValue(obj.wt_code);
                        }
                        resolve(obj);
                    }
                }, (e) => reject(e));
        });
    }

    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Transport')).remove(id);
    }

    onSaveEv(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let obj: any = {};
            if (this.model.t_finishDate) {

                // finalizare transport
                obj = {
                    valuedQuantity: this.model.t_valuedQuantity,
                    eliminatedQuantity: this.model.t_eliminatedQuantity,
                    recycledQuantity: this.model.t_recycledQuantity,
                    reusedQuantity: this.model.t_reusedQuantity,
                    exportedQuantity: this.model.t_exportedQuantity,
                    tempStockDate: this.model.t_tempStockDate,
                    shippingCost: this.model.t_shippingCost,
                    receptionDate: this.model.t_receptionDate,
                    finishDate: this.model.t_finishDate,
                    capitalizationId: this.model.t_capitalizationId,
                    eliminationId: this.model.t_eliminationId,
                    recycleId: this.model.t_recycleId,
                    treatmentTypeId: this.model.t_treatmentTypeId,
                    approvalId: this.model.t_approvalId,
                    fet: this.model.t_fet,
                    physicalCondition: this.model.t_physicalCondition,
                    observations: this.model.t_observations
                };
            } else {
                obj = {
                    wasteTypeId: this.model.t_wasteTypeId,
                    workCenterId: this.model.t_workCenterId,
                    no: this.model.t_no,
                    ladingBill: this.model.t_ladingBill,
                    quantity: this.model.t_quantity,
                    weighing: this.model.t_weighing,
                    sendDate: this.model.t_sendDate,
                    carNumber: this.model.t_carNumber,
                    carId: this.model.t_carId,
                    driverName: this.model.t_driverName,
                    driverId: this.model.t_driverId,
                    driverTableId: this.model.t_driverTableId,
                    transportTypeId: this.model.t_transportTypeId,
                    transporterId: this.model.t_transporterId,
                    destinationTypeId: this.model.t_destinationTypeId,
                    recipientId: this.model.t_recipientId,
                    capitalizationId: this.model.t_capitalizationId,
                    eliminationId: this.model.t_eliminationId,
                    approvalId: this.model.t_approvalId,
                    fet: this.model.t_fet,
                    physicalCondition: this.model.t_physicalCondition,
                    actions: this.getTransportVal(this.model.selectedActions),
                    invoiceDate: this.model.t_invoiceDate,
                    invoiceNo: this.model.t_invoiceNo,
                    observations: this.model.t_observations,
                    scaleId: this.model.scaleId,
                    materialId: this.model.t_materialId,
                    unitWeight: this.model.t_unitWeight
                };
            }
            if (this.dsTransportSource && this.dsTransportSource.length > 0) {
                obj.sources = this.dsTransportSource;
            }
            const q = new EntityQuery('Transport').eq('fet', obj.fet.trim());
            if (this.model.t_id) {
                q.neq('id', this.model.t_id);
            }
            EntityStore.fromQuery(q).single().then(result => {
                if (result) {
                    notify('Acest F.E.T. / F.I.D. exista deja!', 'error', 3000);
                    reject('ERROR');
                } else {
                    const s = EntityStore.fromQuery(new EntityQuery('Transport'));
                    if (this.model.t_id) {
                        s.update(this.model.t_id, obj).then(() => resolve('OK'), error => reject('ERROR'));
                    } else {
                        s.insert(obj).then((response) => {
                            resolve(response);
                        }, error => {
                            notify('Eroare la adaugare, asigurati-va ca toate datele sunt corecte!', 'error', 3000);
                        });
                    }
                }
            });
        });
    }

    reset(): void {
        this.model = this.createDefault();
    }

    private getTransportVal(actionsArr: number[]) {
        if (actionsArr && actionsArr.length > 0) {
            let result = 0;
            for (const act of actionsArr) {
                result += act;
            }
            return result;
        }
        return 0;
    }
}
