import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { UIService } from '../../../service/ui.service';
import { EditTransportService } from '../transport/editTransport.service';
import { EntityStoreOptions } from '@dohu/ibis-entity';
import { DxDataGridComponent } from 'devextreme-angular';
import { iBisFileService } from '@dohu/ibis-common';

@Component({
    templateUrl: 'scale.component.html',
})
export class ScaleComponent implements OnInit {
    dsScale: CustomStore;
    onEditTransportClick: any;

    @ViewChild('gridScale') gridScale: DxDataGridComponent;
    constructor(public ds: DataService, public ui: UIService, public edit: EditTransportService, 
        public file: iBisFileService) {

        this.onEditTransportClick = (e: any) => this.onEdit(this, e);
    }

    ngOnInit() {
        this.dsScale = this.ds.getScale();
    }

    onRaportTransport(e: any, type: number) {
        const url = EntityStoreOptions.DefaultServer + 'DownloadAnex' + (type === 2 ? 2 : '') + '/' + e.row.data.transportId;
        this.file.DownloadByUrl(url);
    }

    onNotesClick(e: any) {
        const url = EntityStoreOptions.DefaultServer + 'DownloadNotes/' + e.row.data.transportId;
        this.file.DownloadByUrl(url);
    }

    onEdit(that: any, e: any) {
        e.event.preventDefault();
        if (e.row.data.transportId) {
            that.edit.showPopup(e.row.data.transportId, true);
        }

        const tr = {
            t_weighing: 1, t_sendDate: e.row.data.scaleDate,
            selectedActions: [1], wc_parentId: null, t_workCenterId: null,
            t_transporterId: e.row.data.transporterId,
            t_recipientId: e.row.data.recipientId,
            t_carNumber: e.row.data.carNumber,
            t_driverName: e.row.data.driverName,
            t_wasteTypeId: e.row.data.wasteTypeId,
            t_quantity: e.row.data.quantity,
            t_destinationTypeId: e.row.data.destinationTypeId,
            t_transportTypeId: e.row.data.transportTypeId,
            scaleId: e.row.data.id
        };

        if (that && that.ds && this.ds.defaultParty) {
            tr.wc_parentId = this.ds.defaultParty.ent;
            tr.t_workCenterId = this.ds.defaultParty.wc;
        }

        that.edit.showPopup(tr, false).then(() => that.gridScale.instance.refresh());
    }
}
