import { Component, Input } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'app-edit-invoice',
    templateUrl: 'editInvoice.component.html'
})
export class EditInvoiceComponent {
    @Input() invoicesData: any;
    dspEntities: CustomStore;
    dspContractors: CustomStore;

    constructor(public ds: DataService) {
        this.dspEntities = this.ds.getParty('en');
        this.dspContractors = this.ds.getParty('ct');
    }
}
