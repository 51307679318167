import { Component, NgModule } from '@angular/core';
import { DxFormModule, DxScrollViewModule } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { DataService } from '../../../service/data.service';
import { EditPartyService } from './editParty.service';


@Component({
    selector: 'app-edit-party',
    templateUrl: 'editParty.component.html'
})
export class EditPartyComponent {

    onLocalityInit: any;
    onOptionChanged: any;
    onCountyChanged: any;
    localityComponent: any;

    dsBoolean: { id: any; name: any; }[];

    dsLocality: any;
    dsCounty: CustomStore;
    dsContractor: CustomStore;

    constructor(public ds: DataService, public edit: EditPartyService) {
        this.dsCounty = this.ds.geographicAreas(2);
        this.dsContractor = this.ds.getParty('ct', false);
        this.dsBoolean = this.ds.boolean();

        this.onOptionChanged = (data) => { this.onOptionChangedEv(data, this); };
        this.onCountyChanged = (data) => { this.onCountyChangedEv(data, this); };
        this.onLocalityInit = (data) => { this.onLocalityInitEv(data, this); };

        setTimeout(() => {
            if (this.edit.model.countyId) {
                this.dsLocality = this.ds.getLocalitiesByCountyId(this.edit.model.countyId);
            } else {
                this.setLocalityDataSource();
            }
        }, 0);
    }

    onOptionChangedEv(event, that) {
        if (!event.value) {
            that.edit.model.parentId = null;
        }
    }

    onCountyChangedEv(event, that) {
        if (event.event) {
            that.ds.resetValidation(that.localityComponent.component);
        }
        if (event.value) {
            that.localityComponent.component.option('dataSource', that.ds.getLocalitiesByCountyId(event.value));
        } else if (event.value === null) {  // vine null doar la a doua deschidere de popup
            that.setLocalityDataSource();
        }
    }

    onLocalityInitEv(event, that) {
        that.localityComponent = event;
    }

    setLocalityDataSource() {
        this.dsLocality = new DataSource({
            store: this.ds.geographicAreas(1),
            paginate: true,
            pageSize: 50
        });
    }
}
