import { Component, Input } from '@angular/core';
import { DataService } from '../../../service/data.service';


@Component({
    selector: 'app-edit-sanction',
    template: `
    <dx-scroll-view height='100%'>
        <dx-form [colCount]="1" [formData]='sanctionForm' [showValidationSummary]="false" validationGroup="sanctionValidation">
            <dxi-item itemType="group" [colSpan]="1" [colCount]='2'>
                <dxi-item [label]="{text: ds.lg.format('SANCTION_DESCRIPTION')}" dataField="sanctionDescription" editorType="dxTextBox"
                    [editorOptions]="{ }">
                </dxi-item>
                <dxi-item [label]="{text: ds.lg.format('STATUS')}" dataField="sanctionStatus" editorType="dxSelectBox"
                    [editorOptions]="{dataSource: ds.static.sanctionStatus, displayExpr: 'value', valueExpr: 'id' }"></dxi-item>
            </dxi-item>
            <dxi-item itemType="group" [colSpan]="1" [colCount]='2'>
                <dxi-item [label]="{text: ds.lg.format('SANCTION_TERM')}" dataField="termSanction" editorType="dxDateBox"
                    [editorOptions]="{ displayFormat: 'dd.MM.yyyy', width: '100%'}"></dxi-item>
                <dxi-item [label]="{text: ds.lg.format('OBSERVATIONS')}" dataField="notifications" editorType="dxTextBox"
                    [editorOptions]="{ }">
                </dxi-item>
            </dxi-item>

        </dx-form>
    </dx-scroll-view>`
})
export class EditSanctionComponent {
    @Input() sanctionForm: any;
    constructor(public ds: DataService) {
    }
}
