import { Injectable } from '@angular/core';
import { iBisEditService } from '@dohu/ibis-common';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { DataService } from '../../../service/data.service';

@Injectable()
export class EditContractService extends iBisEditService {
    public role: string;

    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'contractValidation';
    }
    createDefault() {
        if (this && this.ds) {
            return {
                isValid: true,
                entityId: this.ds.defaultParty.ent,
                startDate: new Date(),
                role: this.role
            };
        } else {
            return {
                isValid: true,
                startDate: new Date()
            };
        }
    }
    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (!data.c_id) { reject(); }
            EntityStore.fromQuery(new EntityQuery('Contract').eq('id', data.c_id)).single().then((obj) => {
                if (!obj) {
                    reject();
                } else {
                    obj.role = data.role;
                    resolve(obj);
                }
            });
        });
    }
    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Contract')).remove(id);
    }
    onSaveEv(): Promise<any> {
        if (this.model.role) {
            EntityStore.execute('AddActivity', { id: this.model.partyId, role: this.model.role });
        }
        const s = EntityStore.fromQuery(new EntityQuery('Contract'));
        return (this.model.id ? s.update(this.model.id, this.model) : s.insert(this.model));
    }
    reset(): void {
        this.model = this.createDefault();
    }
}
