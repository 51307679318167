import { Component, ViewChild, OnInit } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import { UIService } from '../../../service/ui.service';
import { EditUnitService } from './editUnit.service';
import { EditUnitWasteService } from './editUnitWaste.service';
import { ActivatedRoute } from '@angular/router';
import { iBisAuthService } from '@dohu/ibis-auth';
import { alert } from 'devextreme/ui/dialog';

@Component({
    templateUrl: 'unit.component.html'
})
export class UnitComponent implements OnInit {
    dsUnit: CustomStore;
    dsPartyWaste: CustomStore;
    dsStorageType: CustomStore;
    dsCapitalization: CustomStore;
    dsElimination: CustomStore;

    onEditUnitsClick: any;
    onEditUnitDetailsClick: any;

    currentWC: string;

    @ViewChild('gridUnit') gridUnit: DxDataGridComponent;
    @ViewChild('gridPartyWaste') gridPartyWaste: DxDataGridComponent;
    onCustomizeColumnsEvent: (c: any) => void;
    licenseCert: any;
    maxWorkCenters: any;
    maxWasteCodes: any;

    constructor(public ds: DataService, public ui: UIService, public auth: iBisAuthService,
        public edit: EditUnitService, public editD: EditUnitWasteService, public activeRoute: ActivatedRoute) {
        this.onEditUnitsClick = this.onEditUnits.bind(this);
        this.onEditUnitDetailsClick = this.onEditUnitDetails.bind(this);
        this.activeRoute.queryParams.subscribe(params => {
            this.onCustomizeColumnsEvent = (c) => { this.ui.onCustomizeColumns(c, params['display']); };
        });
        this.licenseCert = JSON.parse(sessionStorage.getItem('licenseCertQHSE'));
        if (this.licenseCert) {
            this.maxWorkCenters = this.ds.static.subscriptionData[this.licenseCert.type - 1].workCenters + this.licenseCert.extra['workCenters'];
            this.maxWasteCodes = this.ds.static.subscriptionData[this.licenseCert.type - 1].wasteCodes + this.licenseCert.extra['wasteCodes'];
        }
    }

    ngOnInit() {
        this.dsUnit = this.ds.getPartyView('un', false);
    }

    onEditUnits(e: any) {
        e.event.preventDefault();
        this.edit.showPopup(e.row.data.p_id, true).then(() => this.gridUnit.instance.refresh());
    }

    onWasteExpand(event) {
        event.component.collapseAll(-1);
    }

    onCellPrepared(e: any) {
        if (e.rowType === 'data' && e.column.command === 'expand') {
            if (e.data.p_typeId !== 2) {
                e.cellElement.children[0].classList.remove('dx-datagrid-group-closed');
            }
        }
    }

    onCellClick(e: any) {
        if (e.rowType === 'data') {
            if (e.cellElement.classList[0] === 'dx-command-expand' && e.data.p_typeId !== 2) {
                e.component.collapseAll(-1);
            }
        }
    }

    // subgrid
    onContentReady(e: any, pw: any) {
        this.currentWC = pw.p_id;
        if (e.component.option('dataSource') == null) {
            e.component.option('dataSource', this.ds.getPartyWasteViewByWc(pw.p_id));
            this.dsStorageType = this.ds.getEnumValue('Stocare');
            this.dsCapitalization = this.ds.getEnumValue('Valorificare');
            this.dsElimination = this.ds.getEnumValue('Eliminare');
        }
    }

    onEditUnitDetails(e: any) {
        e.event.preventDefault();
        this.editD.showPopup(e.row.data.wtp_id, true).then(() => this.gridPartyWaste.instance.refresh());
    }

    toolbarPreparing(event: any, type: any, data?: any) {
        if (type === 'first') {
            this.ui.prepareToolbar(event, true);
        } else {
            this.ui.prepareToolbar(event, false);
        }
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: this.ds.lg.format('ADD', null),
                icon: 'assets/img/grid_add.svg', onClick: (e: any) => {
                    if (type === 'first') {
                        this.ds.countWorkCenters().then(res => {
                            if (res && res[0].f1 < this.maxWorkCenters) {
                                this.edit.showPopup(null).then(() => this.gridUnit.instance.refresh());
                            } else {
                                alert(this.ds.lg.format('WORK_CENTERS_EXCEEDED', null), this.ds.lg.format('LICENSE_RESTRICIONS'));
                            }
                        });
                    } else {
                        if (this.maxWasteCodes < 1000) {
                            this.ds.countPartyWaste().then(res => {
                                if (res && res[0].f1 < this.maxWasteCodes) {
                                    const newObj = this.editD.createDefault();
                                    newObj.workCenterId = this.currentWC;
                                    this.editD.showPopup(newObj).then(() => this.gridPartyWaste.instance.refresh());
                                } else {
                                    alert(this.ds.lg.format('WASTE_CODES_EXCEEDED', null), this.ds.lg.format('LICENSE_RESTRICIONS'));
                                }
                            });
                        } else {
                            const newObj = this.editD.createDefault();
                            newObj.workCenterId = this.currentWC;
                            this.editD.showPopup(newObj).then(() => this.gridPartyWaste.instance.refresh());
                        }
                    }
                }
            },
            location: 'after'
        });
    }
}
