import { Component } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { iBisAuthService } from '@dohu/ibis-auth';
import { DataService } from '../../service/data.service';
import { Router } from '@angular/router';
import { iBisNavigationService } from '@dohu/ibis-navigation';
declare var require: any;

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent {
    year: number;
    userLogin: any;
    appVersion: string;

    constructor(public auth: iBisAuthService, public ds: DataService, public menu: iBisNavigationService, public router: Router) {
        this.userLogin = {};
        if (this.router.url === '/login') {
            this.ds.isLoginModalVisible = false;
            if (this.auth.isAuth) {
                this.menu.redirectToTheFirstRoute();
            }
        }
        this.year = new Date().getFullYear();
        // setTimeout(() => {
        //     if(document.getElementById('app_waste_manag')){
        //         document.getElementById('app_waste_manag').innerHTML = ds.lg.format('APP_WASTE_MANAG');
        //     }
        // }, 0);
    }

    onLogin(e: any) {
        if (e.validationGroup) {
            const validation = e.validationGroup.validate();
            if (validation && !validation.isValid) {
                notify('Trebuie să completați toate câmpurile obligatorii.', 'error', 3000);
                return;
            }
        }
        this.auth.login(this.userLogin).then((data) => {
            // this.ds.checkForTheme();
            this.ds.initDefaultParty();
            this.auth.user.isAuth = true;
            notify(this.ds.lg.format('SUCCESS_AUTH', null), 'success', 3000);
            if (this.router.url === '/login') {
                this.menu.redirectToTheFirstRoute();
            } else {
                this.ds.isLoginModalVisible = false;
                // this.ds.initStatistics();
            }
        }, (error) => {
            if (typeof error === 'object') {
                notify(error.err, 'error', 3000);
            } else if (typeof error === 'string') {
                this.ds.serverError(error);
            }
        });
    }

    onEditorEnterKey(e: any) {
        document.getElementById('loginBtn').focus();
        document.getElementById('loginBtn').click();
    }
 
    onContentFormReady(e: any) {
        e.component.getEditor('userName').focus();
    }

    goToRegister(fp: boolean) {
        this.router.navigate(['/register'], { queryParams: { forgotPassword: fp } });
        this.ds.isLoginModalVisible = false;
        return false;
    }
}
