
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../service/data.service';
import { UIService } from '../../service/ui.service';
import { AnalizeService } from './analize.service';

@Component({
    selector: 'app-analyzes',
    templateUrl: './analize.component.html'
})
export class AnalizeComponent implements OnInit {

    constructor(public ds: DataService, public ui: UIService, public analyze: AnalizeService) { }

    ngOnInit() { }
}
