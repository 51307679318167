import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import { EditProjectService } from './editProject.service';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html'
})

export class ProjectComponent implements OnInit {

  dsProject: CustomStore;
  dsParty: CustomStore;
  onEditProjectClick: any;
  onDeleteProjectClick: any;

  @ViewChild('gridProject') gridProject: DxDataGridComponent;
  dsBoolean: { id: any; name: any; }[];

  constructor(public ds: DataService, public ui: UIService, public edit: EditProjectService) {
    this.onEditProjectClick = this.onEditProjectClickEv.bind(this);
    this.onDeleteProjectClick = this.onDeleteProjectClickEv.bind(this);
  }

  ngOnInit() {
    this.dsProject = this.ds.getProject();
    this.dsParty = this.ds.getParty('cr');
    this.dsBoolean = this.ds.boolean('bool');
  }

  mainToolbarPreparing(ev: any) {
    this.ui.prepareToolbar(ev, true);
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        hint: 'Adaugă proiect',
        icon: 'plus',
        onClick: (e: any) => this.edit.showPopup({ isValid: true }).then(() => this.gridProject.instance.refresh(), () => { })
      },
      location: 'after'
    });
  }

  onEditProjectClickEv(ev: any) {
    ev.event.preventDefault();
    this.edit.showPopup(ev.row.data, true).then(
      () => this.gridProject.instance.refresh(),
      (error) => this.ds.serverError(error)
    );
  }

  onDeleteProjectClickEv(e: any) {
    e.event.preventDefault();
    confirm('Ești sigur că vrei să ștergi această înregistrare ?', 'Confirmare').then(val => {
      if (val) {
        this.edit.onRemove(e.row.data.id).then(() => {
          notify('Success', 'success', 2000);
          this.gridProject.instance.refresh();
        }, err => {
          this.ds.serverError(err);
        });
      }
    });
  }
}

