import { Injectable } from '@angular/core';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';

@Injectable({
  providedIn: 'root'
})
export class EditAgencyService extends iBisEditService {

  constructor(public ds: DataService) {
    super(ds.lg);
    this.validation = 'AgencyValidation';
  }
  createDefault() {
    return {};
  }

  getById(id: string, serverUrl?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      EntityStore.single(new EntityQuery('Agency').eq('id', id)).then(result => {
        if (!result) {
          reject();
        } else {
          EntityStore.single(new EntityQuery('GeographicArea').eq('id', result.localityId)).then(locality => {
            result.countyId = locality.parentId;
            resolve(result);
          }, (error) => {
            this.ds.serverError(error);
          });
        }
      }, (error) => {
        this.ds.serverError(error);
      });
    });
  }
  onRemove(id: string): Promise<void> {
    return EntityStore.fromQuery(new EntityQuery('Agency')).remove(id);
  }
  onSaveEv(serverUrl?: string): Promise<any> {
    const aQ = EntityStore.fromQuery(new EntityQuery('Agency'));
    return (this.model.id ? aQ.update(this.model.id, this.model) : aQ.insert(this.model));
  }
  reset(): void {
    this.model = this.createDefault();
  }

}
