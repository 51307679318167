import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../service/data.service';
import { Router } from '@angular/router';
import { AnalizeService } from '../analize.service';

@Component({
    templateUrl: 'dashboard.component.html'
})
export class DashboardComponent implements OnInit {
    isWizardVisible: boolean;
    isInitAppVisible: boolean;

    initOptions: any;
    constructor(public ds: DataService, private router: Router, public analyze: AnalizeService) { }

    ngOnInit() {
        this.isWizardVisible = false;
        this.isInitAppVisible = false;

        if (!this.ds.auth.isAuth) {
            return;
        }
        this.ds.checkInitApp().then((result: any) => {
            if (result.status === 'valid') {
                this.router.navigateByUrl('/analize/dashboard');
                return;
            }
            if (result.entityId) {
                // avem entitate.
                this.initOptions = { entityId: result.entityId };
            } else {
                this.initOptions = { company: result.company, cif: result.cif };
                // pe result.company e denumirea companiei, si pe result.cif e cif
            }

            if (result.wcId) {
                this.initOptions = { workCenterId: result.wcId };
                // avem punct de lucru, trebuie adaugate deseuri.
            }
            this.isInitAppVisible = true;
        });
    }
}
