import { Component, OnInit, ViewChild } from '@angular/core';
import { UIService } from '../../../service/ui.service';
import { DataService } from '../../../service/data.service';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { DxDataGridComponent } from 'devextreme-angular';
import { EntityStore } from '@dohu/ibis-entity';

@Component({
  selector: 'app-invoice-item',
  templateUrl: './invoice-item.component.html'
})
export class InvoiceItemComponent implements OnInit {
  dsInvoiceItems: any;
  dsWasteType: any;
  isInvoiceItemPopupVisible: boolean;
  invoiceItem: any;
  @ViewChild('gridInvoiceItems') gridInvoiceItems: DxDataGridComponent;
  onSaveInvoiceItem: any;
  invoiceItemId: any;
  dsItemWaste: any;
  onEditInvoiceItemsClick: any;
  onDeleteInvoiceItemsClick: any;
  onGenerateClick: any;

  constructor(public ds: DataService, public ui: UIService) { }

  ngOnInit() {
    this.dsInvoiceItems = this.ds.getInvoiceItems();
    this.dsWasteType = this.ds.getWasteTypeChildren();

    this.onSaveInvoiceItem = this.onSaveInvoiceItemEv.bind(this);
    this.onEditInvoiceItemsClick = this.onEditInvoiceItems.bind(this);
    this.onDeleteInvoiceItemsClick = this.onDeleteInvoiceItems.bind(this);
    this.onGenerateClick = this.onGenerateClickEv.bind(this);
  }

  onRowExpanding(e) {
    this.invoiceItemId = e.key;
    this.dsItemWaste = this.ds.getItemWaste(this.invoiceItemId);
  }

  onRowInserting(event: any, item) {
    event.data.invoiceItemId = this.invoiceItemId;
  }

  toolbarPreparing(event: any, main?: any) {
    this.ui.prepareToolbar(event, true);
    if (!main) {
      event.toolbarOptions.items.unshift({
        widget: 'dxButton',
        options: {
          icon: 'assets/img/grid_add.svg', hint: this.ds.lg.format('ADD_ROW', null), onClick: (e: any) => {
            this.addNewRow(null);
          }
        },
        location: 'after'
      });
    }
  }

  addNewRow(e: any) {
    this.invoiceItem = {};
    this.isInvoiceItemPopupVisible = true;
  }

  onSaveInvoiceItemEv(event: any) {
    event.event.preventDefault();
    const validation = event.validationGroup.validate();
    if (!validation.isValid) {
      notify(this.ds.lg.format('ALL_REQ_FIELDS', null), 'error', 3000);
      return;
    }
    this.ds.addEditInvoiceItem(this.invoiceItem, this.invoiceItem.id).then((result: any) => {
      this.whenDone();
    }, error => {
      this.ds.serverError(error);
    });
  }

  onEditInvoiceItems(e: any) {
    e.event.preventDefault();
    this.invoiceItem = {
      id: e.row.data.ii_id, partNumber: e.row.data.ii_partNumber,
      productName: e.row.data.ii_productName, quantity: e.row.data.ii_quantity,
      uom: e.row.data.ii_uom, unitCost: e.row.data.ii_unitCost,
      invoiceId: e.row.data.ii_invoiceId
    };
    this.isInvoiceItemPopupVisible = true;
  }

  onDeleteInvoiceItems(e: any) {
    e.event.preventDefault();
    confirm(this.ds.lg.format('DELETE_RECORD', null), this.ds.lg.format('CONFIRM', null)).then(val => {
      if (val) {
        this.ds.removeInvoiceItem(e.row.data.ii_id).then(() => {
          notify('Success', 'success', 2000);
          this.gridInvoiceItems.instance.refresh();
        }, err => {
          this.ds.serverError(err);
        });
      }
    });
  }

  onGenerateClickEv(e: any) {
    EntityStore.execute('InvoiceItemGeneration', { id: e.row.data.ii_id }).then(res => {
      if (res === 'OK') {
        this.gridInvoiceItems.instance.refresh();
      }
    }, err => {
      this.ds.serverError(err);
    });
  }

  whenDone() {
    this.isInvoiceItemPopupVisible = false;
    this.invoiceItem = {};
    notify('Success', 'success', 2000);
    this.gridInvoiceItems.instance.refresh();
  }

}
