import { Component } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { EditUnitWasteService } from './editUnitWaste.service';
import DataSource from 'devextreme/data/data_source';

@Component({
    selector: 'app-edit-unit-waste',
    templateUrl: './editUnitWaste.component.html'
})

export class EditUnitWasteComponent {
    onWasteTypeChange: any;
    dsWasteType: DataSource;
    dsStorageType: CustomStore;
    dsCapitalization: CustomStore;
    dsElimination: CustomStore;

    constructor(public ds: DataService, public edit: EditUnitWasteService) {
        this.dsWasteType = this.ds.getUnitWasteTypesPaginate();
        this.dsStorageType = this.ds.getEnumValue('Stocare');
        this.dsCapitalization = this.ds.getEnumValue('Valorificare');
        this.dsElimination = this.ds.getEnumValue('Eliminare');
        this.onWasteTypeChange = (e) => { this.onWasteTypeChangeEv(e, this); };
    }

    onWasteTypeChangeEv(ev, that) {
        that.edit.updateWasteInfo(ev.value);
    }
}
