import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { DxDataGridComponent } from 'devextreme-angular';
import { UIService } from '../../../service/ui.service';
import { EditTaxService } from './editTax.service';

@Component({
    templateUrl: 'tax.component.html'
})
export class TaxComponent implements OnInit {
    dsTax: CustomStore;
    dsBoolean: any;

    isTaxVisible: boolean;
    tax: any;


    @ViewChild('gridTax') gridTax: DxDataGridComponent;
    onEditTaxClick: any;
    onDeleteTaxClick: any;
    onTaxSave: any;

    constructor(public ds: DataService, public ui: UIService) {
        this.onEditTaxClick = this.onEditTax.bind(this);
        this.onDeleteTaxClick = this.onDeleteTax.bind(this);
        this.onTaxSave = this.onTaxSaveEv.bind(this);
    }

    ngOnInit() {
        this.dsTax = this.ds.getTax();
        this.isTaxVisible = false;
        this.tax = {};
    }


    onEditTax(e: any) {
        e.event.preventDefault();
        this.tax = e.row.data;
        this.isTaxVisible = true;
        this.ds.getPartById(e.row.data.t_workCenterId).then((result: any) => {
            this.tax.t_entityId = result.id;
        });
    }

    onDeleteTax(e: any) {
        e.event.preventDefault();
        confirm(this.ds.lg.format("DELETE_RECORD", null), this.ds.lg.format("CONFIRM", null)).then(val => {
            if (val) {
                this.ds.removeTax(this.tax.t_id).then(() => {
                    notify('Success', 'success', 2000);
                    this.gridTax.instance.refresh();
                }, (err) => {
                    this.ds.serverError(err);
                });
            }
        });
    }

    onTaxSaveEv(event: any) {
        event.event.preventDefault();
        const validation = event.validationGroup.validate();
        if (!validation.isValid) {
            notify(this.ds.lg.format("ALL_REQ_FIELDS", null), 'error', 3000);
            return;
        }
        const obj = {
            transportId: this.tax.t_transportId,
            no: this.tax.t_no,
            workCenterId: this.tax.t_workCenterId,
            value: this.tax.t_value,
            payDate: this.tax.t_payDate,
            sendDate: this.tax.t_sendDate,
            observation: this.tax.t_observation,
            perTransport: this.tax.t_perTransport,
            agencyId: this.tax.t_agencyId,
            costIncluded: this.tax.t_costIncluded,

        };

        EditTaxService.onSave(this.ds.addEditTax(obj, this.tax.t_id)).then((result) => {
            this.isTaxVisible = false;
            this.tax = {};
            notify('Succes', 'success', 2000);
            this.gridTax.instance.refresh();
        });
    }


    addNewRow() {
        this.isTaxVisible = true;
        this.tax = { t_sendDate: new Date(), t_perTransport: false };
    }

    toolbarPreparing(event) {
        this.ui.prepareToolbar(event, true);

        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                icon: 'assets/img/grid_add.svg', hint: this.ds.lg.format("ADD_ROW", null), onClick: (e: any) => this.addNewRow()
            },
            location: 'after'
        });
    }
}

