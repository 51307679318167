import { Component, OnInit } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import notify from 'devextreme/ui/notify';
import { alert } from 'devextreme/ui/dialog';
import { DataService } from '../../service/data.service';
import { EntityStoreOptions, EntityStore } from '@dohu/ibis-entity';
import { HttpClient } from '@angular/common/http';
import { iBisLanguageService } from '@dohu/ibis-common';
import * as st from '../../../assets/static.json';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

    accountData: any = {};
    storeAccountData: any = {};
    passwordComparison: any;
    currentPasswordValidation: any;
    // onThemeChange: any;
    onSwitchInit: any;
    isReadOnly: boolean;
    checkCompact: boolean;
    onLanguageChange: (e: any) => void;
    globalVar: any;

    staticFile: typeof st;

    constructor(public ds: DataService, public auth: iBisAuthService, http: HttpClient, public lg: iBisLanguageService) {
        this.onLanguageChange = this.onLanguageChangeEv.bind(this);
        EntityStoreOptions.Http = http;
        this.staticFile = st;
        this.globalVar = JSON.parse(localStorage.getItem('globalVarQHSE')) || {};
    }

    ngOnInit() {
        this.auth.getFullUser().then(result => {
            this.accountData = result || {};
            // const theme = localStorage.getItem('localTheme');
            this.accountData.switchCompact = true; // theme === 'material.light.compact';
            this.accountData.lang = this.globalVar.lang || 'ro';
        });

        this.passwordComparison = (e) => {
            return this.accountData.newPassword;
        };
        this.isReadOnly = true;
        // this.onThemeChange = (data) => { this.onThemeChangeEv(data, this); };
    }

    onLanguageChangeEv(e: any) {
        if (e.event) {
            this.globalVar.lang = e.value;
            localStorage.setItem('globalVarQHSE', JSON.stringify(this.globalVar));
            window.location.reload();
        }
    }

    // onThemeChangeEv(event, that) {
    //     if (event.event) {
    //         let theme;
    //         if (event.value === true) {
    //             theme = 'material.light.compact';
    //         } else {
    //             theme = 'material.light';
    //         }

    //         localStorage.setItem('localTheme', theme);
    //         location.reload();
    //     }
    // }

    startEditing() {
        this.isReadOnly = false;
        Object.assign(this.storeAccountData, this.accountData);
    }

    onSaveEdit(event) {
        const validation = event.instance.validate();
        if (!validation.isValid) {
            notify(this.ds.lg.format('FIELDS_NOT_VALID', null), 'error', 3000);
            return;
        } else if (this.accountData.newPassword === '12345') {
            notify(this.ds.lg.format('COMMON_PASSWORD', null), 'warning', 3000);
            return;
        }

        const obj: any = {
            fullName: this.accountData.fullName,
            emailAddress: this.accountData.emailAddress,
            phone: this.accountData.phone,
        };

        if (this.accountData.newPassword.length) {
            obj.correctPassword = this.accountData.newPassword.trim();
        }

        this.ds.updateUserLogin(obj).then(() => {
            notify(this.ds.lg.format('DATA_CHANGED', null), 'success', 3000);
            this.isReadOnly = true;
        });
    }

    onSaveClose() {
        this.isReadOnly = true;
        Object.assign(this.accountData, this.storeAccountData);
    }
}
