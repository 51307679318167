import { Injectable } from '@angular/core';
import { EditUnitService } from '../unit';
import { iBisLanguageService } from '@dohu/ibis-common';

@Injectable()
export class EditPartyService extends EditUnitService {

    validation: string;

    constructor(lg: iBisLanguageService) {
        super(lg);
        this.validation = 'partyValidation';
    }

    createDefault() {
        return { isActiv: true, isSubcontractor: false, typeId: 3 };
    }

    public get title() {
        if (!this.model || !this.lg) {
            return '';
        }
        return this.model.id ? this.lg.format("EDIT_PARTNER") : this.lg.format("ADD_PARTNER");
    }

    getById(id: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            super.getById(id).then((obj) => {
                if (obj.activitiesType) {
                    obj.activitiesTypeSplit = obj.activitiesType.split(', ');
                }
                resolve(obj);
            }, (e) => reject(e));
        });
    }

    onSaveEv(): Promise<any> {
        this.model.activitiesType = (this.model.activitiesTypeSplit || []).join(', ');
        return super.onSaveEv();
    }
}
