import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../service/data.service';
import { UIService } from '../../../service/ui.service';
import { EditIncinerateService } from './editIncinerate.service';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';

@Component({
    selector: 'app-incinerate',
    templateUrl: './incinerate.component.html'
})
export class IncinerateComponent implements OnInit {

    dsIncinerate: CustomStore;
    dsElim: CustomStore;

    @ViewChild('gridIncinerate') gridIncinerate: DxDataGridComponent;
    onEditIncinerateClick: any;
    onDeleteIncinerateClick: any;

    constructor(public ds: DataService, public ui: UIService, public edit: EditIncinerateService) {
        this.dsIncinerate = this.ds.getEliminatonView();
        this.dsElim = this.ds.getEnumValue('Eliminare');
        this.onEditIncinerateClick = this.onEditIncinerateClickEv.bind(this);
        this.onDeleteIncinerateClick = this.onDeleteIncinerateClickEv.bind(this);
    }

    ngOnInit() {
    }

    mainToolbarPreparing(ev: any) {
        this.ui.prepareToolbar(ev, true);
        ev.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: 'Adaugă rând',
                icon: 'plus',
                onClick: (e: any) => this.edit.showPopup(null).then(
                    () => this.gridIncinerate.instance.refresh(), () => { })
            },
            location: 'after'
        });
    }

    onEditIncinerateClickEv(ev: any) {
        ev.event.preventDefault();
        this.edit.showPopup(ev.row.data, true).then(
            () => this.gridIncinerate.instance.refresh(),
            (error) => this.ds.serverError(error)
        );
    }

    onDeleteIncinerateClickEv(ev: any) {
        ev.event.preventDefault();
        confirm('Ești sigur că vrei să ștergi această înregistrare ?', 'Confirmare').then(val => {
            if (val) {
                this.edit.onRemove(ev.row.data.e_id).then(() => {
                    notify('Success', 'success', 2000);
                    this.gridIncinerate.instance.refresh();
                }, err => {
                    this.ds.serverError(err);
                });
            }
        });

    }
}
