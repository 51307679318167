import { Component, Input } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { EditAgencyService } from './edit-agency.service';

@Component({
    selector: 'app-edit-agency',
    templateUrl: 'editAgency.component.html'
})
export class EditAgencyComponent {
    dsCounty: CustomStore;
    dsLocality: any;
    localityComponent: any;

    constructor(public ds: DataService, public edit: EditAgencyService) {
        this.dsCounty = this.ds.geographicAreas(2);
        this.setLocalityDataSource();
    }

    onCountyChangeEv = (event) => {
        if (event.event || event.value) { // for edit, popup will show with value
            this.dsLocality = this.ds.getLocalitiesByCountyId(event.value);
        } else if (event.value === null) {
            this.setLocalityDataSource();
        }
    }

    onLocalityInit = (ev) => { // first time if it is edit
        if (this.edit.model.countyId) {
            this.dsLocality = this.ds.getLocalitiesByCountyId(this.edit.model.countyId);
            console.log('a intrat');
        }
    }

    setLocalityDataSource() {
        this.dsLocality = new DataSource({
            store: this.ds.geographicAreas(1),
            paginate: true,
            pageSize: 50
        });
    }
}
