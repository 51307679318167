import { Injectable } from '@angular/core';
import { iBisEditService } from '@dohu/ibis-common';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { DataService } from '../../../service/data.service';

@Injectable({
  providedIn: 'root'
})
export class EditProjectService extends iBisEditService {

  constructor(public ds: DataService) {
    super(ds.lg);
    this.validation = 'projectValidation';
  }

  createDefault() {
    return {};
  }
  getById(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      EntityStore.fromQuery(new EntityQuery('Project').eq('id', data.id)).single().then((obj) => {
        if (!obj) {
          reject();
        } else {
          resolve(obj);
        }
      });
    });
  }
  onRemove(id: string): Promise<void> {
    return EntityStore.fromQuery(new EntityQuery('Project')).remove(id);
  }
  onSaveEv(): Promise<any> {
    const obj = this.model;
    const s = EntityStore.fromQuery(new EntityQuery('Project'));
    return (this.model.id ? s.update(this.model.id, obj) : s.insert(obj));
  }
  reset(): void {
    this.model = this.createDefault();
  }
}
