import { Injectable } from '@angular/core';
import { EditGenerateService } from '../view/waste/generate/editGenerate.service';
import { EditUnitWasteService } from '../view/admin/unit';
import { EditPartyService } from '../view/admin/party/editParty.service';
import { EditTransportService } from '../view/waste/transport/editTransport.service';
import { EditTransferService } from '../view/waste/transfer/editTransfer.service';
import { EditPermitService } from '../view/document/permit/editPermit.service';
import { EditContractService } from '../view/document/contract';
import { iBisNavigationService } from '@dohu/ibis-navigation';

@Injectable()
export class ProxyService {

    constructor(public nav: iBisNavigationService, public editGenerate: EditGenerateService, public editParty: EditPartyService, 
        public editContract: EditContractService, public editUnitWaste: EditUnitWasteService, public editTransport: EditTransportService,
        public editTransfer: EditTransferService, public editPermit: EditPermitService) {

            nav.onShowPopup.subscribe((item) => {
                switch (item) {
                    case 'generate':
                        this.editGenerate.showPopup(null);
                        break;
                    case 'transport':
                        this.editTransport.showPopup(null);
                        break;
                    case 'contract':
                        this.editContract.showPopup(null);
                        break;
                    case 'permit':
                        this.editPermit.showPopup(null);
                        break;
                    case 'party':
                        this.editParty.showPopup(null);
                        break;
                }
            })
        }
}
