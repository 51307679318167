import { OnInit, Component, ViewChild } from '@angular/core';
import { UIService } from '../../../service/ui.service';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { EditTransporterService } from './editTransporter.service';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-transporter',
    templateUrl: 'transporter.component.html'
})

export class TransporterComponent implements OnInit {

    dsTransporter: CustomStore;
    dsCar: CustomStore;
    dsDriver: CustomStore;
    onEditTransporterClick: any;

    @ViewChild('gridTransporter') gridTransporter: DxDataGridComponent;

    constructor(public ds: DataService, public ui: UIService, public edit: EditTransporterService) {
        this.onEditTransporterClick = this.onEditTransporterClickEv.bind(this);
    }

    ngOnInit() {
        this.dsTransporter = this.ds.getTransporter();
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: this.ds.lg.format('ADD_CARRIER', null),
                icon: 'plus',
            },
            location: 'after'
        });
    }

    onTransporterExpand(event: any) {
        this.dsCar = this.ds.getCarByTransporterId(event.key);
        this.dsDriver = this.ds.getDriverByTransporterId(event.key);
    }

    onEditTransporterClickEv(e: any) {
        e.event.preventDefault();
        this.edit.showPopup(e.row.data, true).then(
            () => this.gridTransporter.instance.refresh(),
            (error) => this.ds.serverError(error)
        );
    }

    onInitNewRow(ev: any, detail: any) {
        ev.data.transporterId = detail.data.p_id;
    }

}
