import { Injectable } from '@angular/core';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import { DataService } from '../../../service/data.service';
import { iBisEditService } from '@dohu/ibis-common';
import notify from 'devextreme/ui/notify';
import { iBisLanguageService } from '@dohu/ibis-common';

@Injectable()
export class EditUnitWasteService extends iBisEditService {

    validation: string;
    constructor(public ds: DataService, lg: iBisLanguageService) {
        super(lg);
        this.validation = 'partyWasteValidation';
    }

    updateWasteInfo(id: string) {
        const q = new EntityQuery('WasteType').eq('id', id);
        q.fields.push('name', 'hazard');
        EntityStore.fromQuery(q).single().then((obj) => {
            if (obj) {
                this.model.wt_name = obj.name;
                this.model.wt_hazard = obj.hazard;
            }
        });
    }

    createDefault(): any {
        return { isActiv: true };
    }

    getById(id: string): Promise<any> {
        return new Promise<any>( (resolve, reject) => {
            const q = new EntityQuery('WasteTypePartyView').eq('wtp.id', id);
            q.fields.push('wtp.id', 'wtp.workCenterId', 'wtp.wasteTypeId', 'wtp.storageTypeId', 'wtp.eliminationId',
                'wtp.capitalizationId', 'wtp.isActiv', 'wt.name', 'wt.hazard');
            EntityStore.fromQuery(q).single().then((obj) => {
                    if (obj === null) {
                        reject();
                    } else {
                        resolve({
                            id: obj.wtp_id, workCenterId: obj.wtp_workCenterId, wasteTypeId: obj.wtp_wasteTypeId,
                            storageTypeId: obj.wtp_storageTypeId, eliminationId: obj.wtp_eliminationId,
                            capitalizationId: obj.wtp_capitalizationId, isActiv: obj.wtp_isActiv,
                            wt_name: obj.wt_name, wt_hazard: obj.wt_hazard
                        });
                    }
                }, (e) => reject(e));
        });
    }

    onRemove(id: string): Promise<void> {
        return new Promise<void>((resolve, reject) => reject());
    }

    onSaveEv(): Promise<any> {
        const s = EntityStore.fromQuery(new EntityQuery('WasteTypeParty'));
        if (this.model.id) {
            return s.update(this.model.id, this.model);
        } else {
            return new Promise<any>((resolve, reject) => {
                s.insert(this.model).then(value => {
                    if (!value || value === '') {
                        notify(this.ds.lg.format('CODE_ALREADY_ASSIGNED', null), 'error', 3000);
                        reject();
                    } else {
                        resolve(value);
                    }
                }, (e) => {
                    reject(e);
                });
            });
        }
    }

    reset(): void {
        this.model = this.createDefault();
    }
}
