
import { Component } from '@angular/core';
import { DataService } from '../../../service/data.service';
import CustomStore from 'devextreme/data/custom_store';
import { UIService } from '../../../service/ui.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: 'capitalization.component.html'
})
export class CapitalizationComponent {
    dsCaps: CustomStore;
    dsValorificare: CustomStore;
    wasteTypeId: string;

    constructor(public ds: DataService, public ui: UIService, public activeRoute: ActivatedRoute) {
        this.dsValorificare = this.ds.getEnumValue('Valorificare');

        this.activeRoute.queryParams.subscribe(params => {
            this.wasteTypeId = params['wasteType'];

            this.dsCaps = this.ds.capitalizationInfo(this.wasteTypeId);
        });
    }
}
