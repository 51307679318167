import { Component, OnInit } from '@angular/core';
import { DataService } from '../../service/data.service';

@Component({
    selector: 'app-dashboard-boxes',
    templateUrl: './dashboard-boxes.component.html',
    styleUrls: ['./dashboard-boxes.component.css']
})
export class DashboardBoxesComponent implements OnInit {

    constructor(public ds: DataService) { }

    ngOnInit() {
        if (!this.ds.auth.isAuth) {
            return;
        }
        this.ds.initStatistics();
    }
}
