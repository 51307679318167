import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { DataService } from './service/data.service';
import { EntityStoreOptions } from '@dohu/ibis-entity';
import { UIService } from './service/ui.service';
import { Router } from '@angular/router';
import { locale, loadMessages } from 'devextreme/localization';
import * as devExtremeMessages from '../assets/devExtreme.json';
import * as labelNames from '../assets/label.json';
import { HttpClient } from '@angular/common/http';
import { iBisAuthService } from '@dohu/ibis-auth';
import { ProxyService } from './service/proxy.service';
import { iBisLanguageService, iBisFileService } from '@dohu/ibis-common';
import * as Bowser from 'bowser';
import * as uc from '../assets/uiCustomize.json';

declare var require: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    year: number;
    globalVar: any;
    appVersion: string;
    licensePackage: any;

    constructor(public ds: DataService, public ui: UIService, public proxy: ProxyService, public file: iBisFileService,
        public auth: iBisAuthService, http: HttpClient, private router: Router, public localization: iBisLanguageService) {
        EntityStoreOptions.DefaultServer = environment.defaultUrlServer;
        auth.deployId = environment.deployId;
        auth.authServer = environment.authServer;
        auth.saasServer = environment.saasServer;
        auth.appName = 'QHSE';

        file.server = environment.saasServer;
        EntityStoreOptions.Http = http;

        localization.server = environment.saasServer;
        this.globalVar = JSON.parse(localStorage.getItem('globalVarQHSE')) || { lang: 'ro' };
        // localization.locale(this.globalVar.lang || 'ro');

        const { lastCommitNumber: v, branch, shortSHA } = require('../git-version.json');
        this.appVersion = Math.trunc(v / 1000) + '.' + Math.trunc(v % 1000 / 100) + '.' + v % 100 + ' - ' + branch + '[' + shortSHA + ']';
        this.year = new Date().getFullYear();
        locale('ro');
        loadMessages(devExtremeMessages.default);
        loadMessages(labelNames.default);

        document.documentElement.style.setProperty('--isCompact', '1');
        this.ds.allowRegister = environment.allowRegister;

        if (uc.default.useNativ !== null && uc.default.useNativ !== undefined) {
            ui.isWindows = !uc.default.useNativ;
        } else {
            if (window.navigator.userAgent && Bowser) {
                const bowser = Bowser.getParser(window.navigator.userAgent);
                const os = Bowser ? bowser.getOS() || {} : {};
                if (os && os.name) {
                    ui.isWindows = os.name === 'Windows';
                } else {
                    ui.isWindows = false;
                }
            } else {
                ui.isWindows = false;
            }
        }
    }

    checkUrl() {
        return (this.router.url.indexOf('/login') > -1) || (this.router.url.indexOf('/register') > -1)
            || (this.router.url.indexOf('/confirmInvite') > -1) || (this.router.url.indexOf('/resetPassword') > -1);
    }
}
