import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import CustomStore, { CustomStoreOptions } from 'devextreme/data/custom_store';
import { EntityStore, EntityQuery, EntityStoreOptions, SimpleFunctionType, EntityConditionGroup } from '@dohu/ibis-entity';
import notify from 'devextreme/ui/notify';
import { environment } from '../../environments/environment';
import { iBisAuthService } from '@dohu/ibis-auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { iBisLanguageService } from '@dohu/ibis-common';
import * as sd from '../../assets/static.json';
import DataSource from 'devextreme/data/data_source';

@Injectable()
export class DataService {

    public monthTwo = new Date();
    public isButtonDisable: boolean;
    public allowRegister: boolean;
    isLoginModalVisible: any;
    statistic: { trUnf: number; wtNo: number; wkNo: number; trNo: number; avNo: number; masNo: number; };
    firstMonthDay: Date;
    lastMonthDay: Date;

    defaultParty: { bu: string, ent: string, wc: string };
    environment: { saasServer: any; authServer: any; };
    licenseObj: { licenseNo: string; statusLicense: number; licenseType: number; thruDate: Date; };

    constructor(public router: Router, public http: HttpClient, public auth: iBisAuthService, public lg: iBisLanguageService) {
        EntityStoreOptions.OnError = (jqXHR) => {
            if (jqXHR.status === 401) {
                // auth.logout();
                notify('Authorization has expired. Retry to login.', 'error', 3000);
                this.router.navigateByUrl('/login');
                this.auth.user.isAuth = false;
                this.isLoginModalVisible = true;
            }
        };

        this.firstMonthDay = new Date();
        this.environment = { saasServer: environment.saasServer, authServer: environment.authServer };
        this.firstMonthDay.setDate(1);
        this.lastMonthDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
        this.isButtonDisable = false;
        setTimeout(() => {
            if (this.auth.isAuth) {
                this.initDefaultParty();
                // this.checkInitApp().then((result: any) => { console.log(result); });
            }
        }, 0);
    }

    static getHarzardName(hazard: number): string {
        return hazard === 1 ? 'Periculos' : 'Nepericulos';
    }

    static getPhysicalConditionName(physicalCondition: number): string {
        const f = sd.default.physicalCondition.find(x => x.id === physicalCondition);
        return f.value;
    }

    static getUOM(uom: number): string {
        const f = sd.default.uom.find(x => x.id === uom);
        return f.value || sd.default.defaultUom;
    }

    static formatDate(date: Date) {
        if (date == null || date === undefined) {
            return '';
        }
        date = new Date(date);
        return EntityStore.toD2(date.getDate()) + '.' +
            EntityStore.toD2((date.getMonth() + 1)) + '.' + date.getFullYear();
    }

    static formatDateTime(date: Date) {
        if (date == null || date === undefined) {
            return '';
        }
        date = new Date(date);
        return EntityStore.toD2(date.getDate()) + '.' +
            EntityStore.toD2((date.getMonth() + 1)) + '.' +
            date.getFullYear() + ' ' + date.getHours() + ':' + EntityStore.toD2(date.getMinutes());
    }
    public get static() { return sd.default; }

    public get isAdmin(): boolean {
        return this.auth.isAuth ? (this.auth.user.roles.indexOf('admin') > -1 || this.auth.user.name === 'huzzy') : this.auth.isAuth;
    }
    public get isSuperAdmin(): boolean {
        return this.auth.isAuth ? (this.auth.user.roles.indexOf('superadmin') > -1 || this.auth.user.name === 'huzzy'
            || this.auth.user.name === 'emanuel.daviduta@dohu.ro') : this.auth.isAuth;
    }

    public customHeightSubGrid() {
        return window.innerHeight - 173;
    }

    public getDate(rowData: any) {
        const col = this as any;
        return DataService.formatDate(rowData[col.dataField]);
    }

    public getDateTime(rowData: any) {
        const col = this as any;
        return DataService.formatDateTime(rowData[col.dataField]);
    }

    // public getFile(url: string) {
    //     let headers = new HttpHeaders();
    //     for (const key in EntityStoreOptions.Headers) {
    //         if (EntityStoreOptions.Headers.hasOwnProperty(key)) {
    //             headers = headers.append(key, EntityStoreOptions.Headers[key]);
    //         }
    //     }
    //     this.http.get(url, { responseType: 'blob', observe: 'response' as 'body', headers: headers }).subscribe((res: any) => {
    //         const header = res.headers.get('Content-Disposition');
    //         let fileName;
    //         if (header) {
    //             const startIndex = header.indexOf('filename=') + 9;
    //             const endIndex = header.length;
    //             fileName = header.substring(startIndex, endIndex);
    //         } else {
    //             notify('Download eronat', 'error', 3000);
    //             return;
    //         }

    //         const blob = new Blob([res.body], { type: res.type });
    //         const fileUrl = window.URL.createObjectURL(blob);
    //         const fileLink = document.createElement('a');
    //         fileLink.href = fileUrl;
    //         fileLink.download = fileName;
    //         // fileLink.click();
    //         fileLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window })); // for mozilla
    //         // const pwa = window.open(fileUrl);
    //         // if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
    //         //     alert( 'Please disable your Pop-up blocker and try again.');
    //         // }
    //     }, err => {
    //         console.log(err);
    //     });
    // }

    displayEntWc = (onRowInfo: any) => {
        return onRowInfo ? (onRowInfo.p_name ? onRowInfo.p_name : '') +
            (onRowInfo.p_name && onRowInfo.c_name ? ' / ' : '') + (onRowInfo.c_name ? onRowInfo.c_name : '') : '';
    }

    displayDriverName(rowInfo: any) {
        if (rowInfo) {
            return ((rowInfo.lastName ? rowInfo.lastName : '') + ' ' + (rowInfo.firstName ? rowInfo.firstName : '')).toString();
        }
    }

    initDefaultParty() {
        this.defaultParty = { bu: null, ent: null, wc: null };
        this.lookingForSingleParty().then(data => {
            this.defaultParty = data;
        });
    }


    lookingForSingleParty() {
        return EntityStore.execute('GetSingleFilterParty', {});
    }

    getSymbolOf(name: any) {
        const us = name.split(' ');
        return (us[0][0].toUpperCase() || '') + (us[1] ? us[1][0].toUpperCase() : (us[0][1].toUpperCase() || 'AA'));
    }

    validationCallbackTrueFalse(e: any) {
        return e.value == null ? false : true;
    }

    serverError(error?: any) {
        let msg = '';
        if (error && typeof error === 'string') {
            const start = error.indexOf('The exception message is');
            const end = error.indexOf('The exception stack trace is:');
            msg = error.substring(start + 24, end - 34);
        } else if (error && typeof error === 'object') {
            const start = error._body.indexOf('The exception message is');
            const end = error._body.indexOf('The exception stack trace is:');
            msg = error._body.substring(start + 24, end - 34);
        }
        if (msg.toLocaleLowerCase().indexOf('unauthorized') > -1) {
            this.isLoginModalVisible = true;
        }
        notify('Server error ' + (error ? ('[' + msg + ']') : ''), 'error', 3000);
    }

    getScale(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Scale').addOrderByDesc(['scaleDate']), false, 'id');
        return new CustomStore(cso);
    }

    /* Deșeuri tab */
    getWasteGenerate(wasteTypeTypeId: string = null): CustomStore {
        const q = new EntityQuery('GenerateView').addOrderByDesc(['g.date']);
        if (wasteTypeTypeId != null && wasteTypeTypeId != '') {
            q.link('g.wasteTypeId', 'id', new EntityQuery('WasteType').eq('typeId', wasteTypeTypeId));
        }
        const cso = EntityStore.store(q, false, 'g_id');
        return new CustomStore(cso);
    }

    transportEntry(wasteTypeTypeId: string = null): CustomStore {
        const q = new EntityQuery('TransportView').eq('t_finishDate', null).addOrderByDesc(['t.sendDate']);
        if (wasteTypeTypeId != null && wasteTypeTypeId != '') {
            q.link('t.wasteTypeId', 'id', new EntityQuery('WasteType').eq('typeId', wasteTypeTypeId));
        }
        const cso = EntityStore.store(q, false, 't_id');
        return new CustomStore(cso);
    }

    getTransportFinish(workCenterId?: string, entityId?: string, wasteTypeTypeId: string = null): CustomStore {
        const q = new EntityQuery('TransportView').neq('t_finishDate', null).addOrderBy(['t.no']);
        if (workCenterId) {
            q.eq('t.workCenterId', workCenterId);
        } else if (entityId) {
            q.linkEq('t.workCenterId', 'id', 'Party', 'parentId', entityId);
        }
        if (wasteTypeTypeId != null && wasteTypeTypeId != '') {
            q.link('t.wasteTypeId', 'id', new EntityQuery('WasteType').eq('typeId', wasteTypeTypeId));
        }
        const cso = EntityStore.store(q, false, 't_id');
        return new CustomStore(cso);
    }

    getFullTransport(): CustomStore {
        const q = new EntityQuery('TransportView').addOrderByDesc(['t.sendDate']);
        const cso = EntityStore.store(q, false, 't_id');
        return new CustomStore(cso);
    }

    getTransporter(isValid = false): CustomStore {
        const q = new EntityQuery('TransporterView')
            .link('p.id', 'id', new EntityQuery('Party').like('activitiesType', '%Transport%')).addOrderBy(['p.name']);
        if (isValid) {
            q.gte('tl.validity', new Date());
        }
        const cso = EntityStore.store(q);
        return new CustomStore(cso);
    }

    getEliminateInfo(wasteTypeTypeId: string = null): CustomStore {
        const q = new EntityQuery('TransportView').neq('t_finishDate', null)
            .neq('t_eliminationId', null).addOrderBy(['t.no']);
        if (wasteTypeTypeId != null && wasteTypeTypeId != '') {
            q.link('t.wasteTypeId', 'id', new EntityQuery('WasteType').eq('typeId', wasteTypeTypeId));
        }
        const cso = EntityStore.store(q, false, 't_id');
        return new CustomStore(cso);
    }

    getEliminatonView(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('EliminationView').addOrderByDesc(['e.date']), false, 'e_id');
        return new CustomStore(cso);
    }

    addEditTransport(obj: any, id: string) {
        const s = EntityStore.fromQuery(new EntityQuery('Transport'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    getTransportViewById(id: string) {
        return EntityStore.fromQuery(new EntityQuery('TransportView').eq('t_id', id)).single();
    }

    getCarByTransporterId(id: string) {
        const cso = EntityStore.store(new EntityQuery('Car').eq('transporterId', id).addOrderBy(['registerNo']), false, 'id');
        return new CustomStore(cso);
    }

    getPaginateCarByTransporterId(id: string): DataSource {
        return new DataSource({
            store: this.getCarByTransporterId(id),
            paginate: true,
            pageSize: 25
        });
    }

    getCarNoByTransporterId(id: string): CustomStore {
        const q = new EntityQuery('Car').eq('transporterId', id).addOrderBy(['registerNo']);
        q.fields.push('registerNo');
        const cso = EntityStore.store(q, false, 'registerNo');
        return new CustomStore(cso);
    }

    getCar(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Car').addOrderBy(['registerNo']), false, 'id');
        return new CustomStore(cso);
    }

    getDriverByTransporterId(id: string): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Driver').eq('transporterId', id).addOrderBy(['lastName']), false, 'id');
        return new CustomStore(cso);
    }

    getPaginateDriverByTransporterId(transporterId: string): DataSource {
        return new DataSource({
            store: this.getDriverByTransporterId(transporterId),
            paginate: true,
            pageSize: 25
        });
    }

    getPaginateDriver(): DataSource {
        return new DataSource({
            store: this.getDriver(),
            paginate: true,
            pageSize: 10
        });
    }

    getDriverNameByTransporterId(id: string) {
        const q = new EntityQuery('Driver').eq('transporterId', id).addOrderBy(['lastName']);
        q.fields.push('lastName', 'firstName');
        const cso = EntityStore.store(q, false, 'firstName');
        return new CustomStore(cso);
    }

    getDriver(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Driver').addOrderBy(['lastName']), false, 'id');
        return new CustomStore(cso);
    }

    removeTransport(id: string) {
        return EntityStore.fromQuery(new EntityQuery('Transport')).remove(id);
    }

    capitalizationInfo(wasteTypeTypeId: string = null): CustomStore {
        const q = new EntityQuery('TransportView').neq('t_finishDate', null).neq('t_capitalizationId', null).addOrderBy(['t.no']);
        if (wasteTypeTypeId != null && wasteTypeTypeId != '') {
            q.link('t.wasteTypeId', 'id', new EntityQuery('WasteType').eq('typeId', wasteTypeTypeId));
        }
        const cso = EntityStore.store(q, false, 't_id');
        return new CustomStore(cso);
    }

    getCapsByDate(wasteType: string, fromDate: Date, thruDate: Date) {
        const q = new EntityQuery('TransportView').eq('t_wasteTypeId', wasteType).neq('t_capitalizationId', null).addOrderBy(['t.no']);
        q.gte('t.sendDate', EntityStore.toDateTimeFilter(this.convertToDate(fromDate)));
        q.lte('t.sendDate', EntityStore.toDateTimeFilter(this.convertToDate(thruDate)));
        q.fields.push('t.id', 't.sendDate', 't.quantity', 't.valuedQuantity', 'wt.code');
        return EntityStore.fromQuery(q, false, 't_id').load();
    }

    getTaxByDate(wc: string, fromDate: Date, thruDate: Date) {
        const q = new EntityQuery('TaxView').eq('t_workCenterId', wc).neq('t_payDate', null).addOrderBy(['t.no']);
        q.gte('t.payDate', EntityStore.toDateTimeFilter(this.convertToDate(fromDate)));
        q.lte('t.payDate', EntityStore.toDateTimeFilter(this.convertToDate(thruDate)));
        q.fields.push('t.id', 't.payDate', 't.value', 'wc.name');
        return EntityStore.fromQuery(q, false, 't_id').load();
    }

    getInvoicesByDate(obj: any, isSales: boolean = false) {
        const q = new EntityQuery('Invoice').eq('entityId', obj.entity).eq('isSales', isSales).addOrderByDesc(['date']);
        if (obj.supplier) {
            q.eq('supplierId', obj.supplier);
        }
        q.gte('date', EntityStore.toDateTimeFilter(this.convertToDate(obj.fromDate)));
        q.lte('date', EntityStore.toDateTimeFilter(this.convertToDate(obj.thruDate)));
        q.fields.push('id', 'entityId', 'amount', 'supplierId', 'date');
        return EntityStore.fromQuery(q, false, 't_id').load();
    }

    getControlsByDate(entity: string, wc: string, fromDate: Date, thruDate: Date) {
        const q = new EntityQuery('ControlView').addOrderBy(['c.authority']);
        if (wc) {
            q.eq('c_workCenterId', wc);
        } else if (entity) {
            q.eq('c_entityId', entity);
        }
        q.gte('c.date', EntityStore.toDateTimeFilter(this.convertToDate(fromDate)));
        q.lte('c.date', EntityStore.toDateTimeFilter(this.convertToDate(thruDate)));
        q.fields.push('c.id', 'c.date', 'c.authority', 'wc.name', 'c.cost', 'ent.name');
        return EntityStore.fromQuery(q, false, 't_id').load();
    }

    convertToDate(date: any) {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        return d;
    }

    getTransferView(wasteTypeTypeId: string = null): CustomStore {

        const q = new EntityQuery('TransferView').addOrderByDesc(['t.sendDate']);
        if (wasteTypeTypeId != null && wasteTypeTypeId != '') {
            q.link('t.wasteTypeId', 'id', new EntityQuery('WasteType').eq('typeId', wasteTypeTypeId));
        }
        const cso = EntityStore.store(q, false, 't_id');
        return new CustomStore(cso);
    }

    getTransferViewById(id: string): Promise<any> {
        return EntityStore.fromQuery(new EntityQuery('TransferView').eq('t_id', id)).single();
    }

    removeTrasfer(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Transfer')).remove(id);
    }

    // cod corect
    checkQuantityForGenerator(generatorId: string, wasteTypeId: string): Promise<any[]> {
        const q = new EntityQuery('Collecting').eq('wasteTypeId', wasteTypeId).eq('approvalId', null)
            .linkEq('partyWorkCenterId', 'id', 'Party', 'parentId', generatorId)
            .linkEq('wasteTypeId', 'id', 'WasteType', 'hazard', 1)
            .gte('date', new Date(new Date().getFullYear(), 0, 1)).lte('date', new Date(new Date().getFullYear(), 11, 31));
        return EntityStore.fromQuery(q).function([{ field: 'quantity', ft: SimpleFunctionType.Sum }]);
    }

    addEditGenerate(obj: any, id: string) {
        const s = EntityStore.fromQuery(new EntityQuery('Generate'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    getMonthly(): CustomStore {
        const q = new EntityQuery('MonthlyStock').gt('generated', 0).gt('valued', 0).gt('taken', 0).gt('inProgress', 0)
            .gt('transfer', 0).gt('eliminated', 0).gt('adjustment', 0);
        q.conditionGroups.useOr = true;
        const cso = EntityStore.store(q.addOrderByDesc(['year', 'month']));
        return new CustomStore(cso);
    }

    getMonthlyById(id: string) {
        return EntityStore.fromQuery(new EntityQuery('MonthlyStock').eq('id', id)).single();
    }

    addEditAdjustment(obj: any, id: string) {
        const s = EntityStore.fromQuery(new EntityQuery('GenerateAdjustment'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    recheckMonthlyStock(month: number, year: number) {
        return EntityStore.execute('RecheckMonthlyStock', { month: month, year: year });
    }

    archiveReport(method: string, obj: any) {
        return EntityStore.execute(method, obj);
    }

    getGeneralReport() {
        const cso = EntityStore.store(new EntityQuery('MirrorStock').addOrderBy(['date']), false, 'id');
        return new CustomStore(cso);
    }

    getMirrorStockInOptions(date?: Date): CustomStoreOptions {
        // DON'T TOUCH THIS
        const q = new EntityQuery('MirrorStock').eq('parentId', null).addOrderBy(['date']);
        if(date){
            const d = this.convertToDate(date);
            d.setDate(d.getDate() + 1);
            q.lte('date', d);
        }
        return EntityStore.store(q, false, 'id');
    }

    getMirrorStockOut(parentId: string, date?: Date){
        const q = new EntityQuery('MirrorStock').eq('parentId', parentId).addOrderBy(['date']);
        if(date){
            const d = this.convertToDate(date);
            d.setDate(d.getDate() + 1);
            q.lte('date', d);
        }
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    checkForAdjustment(obj: any) {
        return EntityStore.fromQuery(new EntityQuery('GenerateAdjustment')
            .eq('month', obj.month).eq('year', obj.year).eq('wasteTypeId', obj.wasteTypeId)).single();
    }

    removeAdjustment(id: string) {
        return EntityStore.fromQuery(new EntityQuery('MonthlyStock')).remove(id);
    }

    getTax(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('TaxView').addOrderBy(['t.no']));
        return new CustomStore(cso);
    }

    addEditTax(obj: any, id: string) {
        const s = EntityStore.fromQuery(new EntityQuery('Tax'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    removeTax(id: string) {
        return EntityStore.fromQuery(new EntityQuery('Tax')).remove(id);
    }

    getControlView(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('ControlView').addOrderBy(['c.date']));
        return new CustomStore(cso);
    }

    addEditControl(obj: any, id: string) {
        const s = EntityStore.fromQuery(new EntityQuery('Control'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    deleteControl(id: string) {
        return EntityStore.fromQuery(new EntityQuery('Control')).remove(id);
    }

    getControlMeasures(controlId): CustomStore {
        const cso = EntityStore.store(new EntityQuery('ControlMeasure').eq('controlId', controlId));
        return new CustomStore(cso);
    }
    addEditMeasure(obj: any, id: string) {
        const s = EntityStore.fromQuery(new EntityQuery('ControlMeasure'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    deleteMeasure(id: string) {
        return EntityStore.fromQuery(new EntityQuery('ControlMeasure')).remove(id);
    }

    getControlSanctions(controlId: string): CustomStore {
        const cso = EntityStore.store(new EntityQuery('ControlSanction').eq('controlId', controlId));
        return new CustomStore(cso);
    }
    addEditSanction(obj: any, id: string) {
        const s = EntityStore.fromQuery(new EntityQuery('ControlSanction'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }
    deleteSanction(id: string) {
        return EntityStore.fromQuery(new EntityQuery('ControlSanction')).remove(id);
    }

    /* Documente tab */
    getArchive(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Archive'));
        return new CustomStore(cso);
    }

    addEditArchive(obj: any, id: string) {
        const s = EntityStore.fromQuery(new EntityQuery('Archive'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    removeArchive(id: string) {
        return EntityStore.fromQuery(new EntityQuery('Archive')).remove(id);
    }

    getPartyLinkView(types?: number[], includeActivity?: number[], isActiv = true): CustomStore {
        let q;
        if (types && types.length > 0) {
            // excludere entitati cu tip de activitate;
            const tt = types.filter(x => x <= 2);
            if (tt.length > 0) {
                q = new EntityQuery('PartyLinkView').eq('c.isActiv', isActiv).addOrderBy(['c.name']).in('c.typeId', tt);
            }
        }
        // has clients or pc clients
        const pcc = types.filter(x => x > 2);
        let qe;
        if (pcc.length > 0) {
            qe = new EntityQuery('PartyLinkView').eq('c.isActiv', isActiv).in('c.typeId', pcc);
            if (includeActivity && includeActivity.length > 0) {
                const group = new EntityConditionGroup();
                for (const item of includeActivity) {
                    group.like('c.activitiesType', '%' + this.static.activityTypeKeys[item] + '%');
                }
                group.useOr = true;
                qe.conditionGroups.groups.push(group);

            }
            if (q) {
                qe.union(q);
            }
        }
        const cso = EntityStore.store(qe ? qe : q, false, 'c_id');
        return new CustomStore(cso);
    }

    getPartyLinkViewPaginate(types?: number[], includeActivity?: number[], isActiv = true): DataSource {
        return new DataSource({
            store: this.getPartyLinkView(types ? types : null, includeActivity ? includeActivity : null, isActiv),
            paginate: true,
            pageSize: 25
        });
    }

    getPartyLink(id: string): CustomStore {
        const q = new EntityQuery('WasteTypePartyLinkView').eq('l.senderId', id);
        return new CustomStore(EntityStore.store(q, false, 'l_id'));
    }

    getPartyMemberById(id: string, view?: boolean): CustomStore {
        let q;
        if (view) {
            q = new EntityQuery('PartyView').eq('p.parentId', id).eq('p.typeId', 2);
        } else {
            q = new EntityQuery('Party').eq('parentId', id).eq('typeId', 2);
        }
        if (id) {
            q.eq(view ? 'p.parentId' : 'parentId', id);
        }

        return new CustomStore(EntityStore.store(q, false, view ? 'p.id' : 'id'));
    }

    getWorkCenterByParty(id: string, isView?: boolean): CustomStore {
        if (isView) {
            const q = new EntityQuery('PartyView').eq('p.parentId', id).eq('p.typeId', 4);
            return new CustomStore(EntityStore.store(q, false, 'p_id'));
        } else {
            const q = new EntityQuery('Party').eq('parentId', id).eq('typeId', 4);
            return new CustomStore(EntityStore.store(q, false, 'id'));
        }
    }

    countWorkCenters() {
        const q = new EntityQuery('Party').eq('typeId', 2);
        return EntityStore.fromQuery(q).function([{ field: 'id', ft: SimpleFunctionType.Count }]);
    }

    getEntityByParentId(id: string): CustomStore {
        const q = new EntityQuery('Party').eq('parentId', id).eq('typeId', 1);
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    getCollectingView(): CustomStore {
        const q = new EntityQuery('CollectingView');
        // q.addCondition('wt.code', EntityConditionOperator.Like, '%*', false, false);
        q.addOrderByDesc(['c.date']);
        const cso = EntityStore.store(q, false, 'c_id');
        return new CustomStore(cso);
    }

    checkInitApp() {
        return EntityStore.execute('CheckInitApp', {});
    }

    public getParentParty(typeId: number): CustomStore {
        const q = new EntityQuery('Party').eq('isActiv', true).eq('typeId', typeId - 1).addOrderBy(['name']);
        q.fields.push('id', 'name');
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    getParty(type?: string, isActiv = true, exceptId: string = null, activity: any = null): CustomStore {
        const q = new EntityQuery('Party').eq('isActiv', isActiv).addOrderBy(['name']);
        switch (type) {
            case 'bu': q.eq('typeId', 0); break;
            case 'ent': q.eq('typeId', 1); break;
            case 'pc': q.eq('typeId', 2); break;
            case 'wc': q.eq('typeId', 2).neq('id', exceptId); break;
            case 'un': q.lt('typeId', 3); break;
            case 'wa': q.eq('typeId', 1).eq('isActiv', true); break;
            case 'cr': q.in('typeId', [0, 1, 3]); break;
            case 'ct': q.gt('typeId', 2).eq('isSubcontractor', false); break;
            case 'cs': q.in('typeId', [1, 3]); break;
        }
        if (activity && typeof activity === 'string') {
            q.like('activitiesType', '%' + activity + '%');
        } else if (activity && typeof activity === 'object') {
            const group = new EntityConditionGroup();
            for (const item of activity) {
                group.like('activitiesType', '%' + item + '%');
            }
            group.useOr = true;
            q.conditionGroups.groups.push(group);
        }
        q.fields.push('id', 'parentId', 'name');
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    getPaginateParty(type: string, isActiv = true, exceptId: string = null, activity: any = null): DataSource {
        return new DataSource({
            store: this.getParty(type, isActiv, exceptId, activity),
            paginate: true,
            pageSize: 25
        });
    }

    getPartyView(type: string, isActiv = true, activity: any = null): CustomStore {
        const q = new EntityQuery('PartyView').addOrderBy(['p.name']);
        if (isActiv) {
            q.eq('p.isActiv', isActiv);
        }
        switch (type) {
            case 'pc': q.eq('p.typeId', 1); break;
            case 'bu': q.eq('p.typeId', 0); break;
            case 'ent': q.eq('p.typeId', 1); break;
            case 'un': q.lt('p.typeId', 3); break;
            case 'wc': q.eq('p.typeId', 2); break;
            case 'trt': q.eq('p.typeId', 3); break;
            case 'ct': q.gt('p.typeId', 2).eq('p.isSubcontractor', false); break;
        }
        if (activity && typeof activity === 'string') {
            q.like('p.activitiesType', '%' + activity + '%');
        } else if (activity && typeof activity === 'object') {
            const group = new EntityConditionGroup();
            for (const item of activity) {
                group.like('p.activitiesType', '%' + item + '%');
            }
            group.useOr = true;
            q.conditionGroups.groups.push(group);
        }
        return new CustomStore(EntityStore.store(q, false, 'p_id'));
    }

    getPartById(id: string) {
        return EntityStore.fromQuery(new EntityQuery('Party').eq('id', id)).single();
    }

    getWorkCenterByParent(parentId: string, typeId = 2): CustomStore {
        // 2 - punctele proprii, 4 - punctele de lucru ale clientilor
        const q = new EntityQuery('Party').eq('typeId', typeId).eq('parentId', parentId).addOrderBy(['name']);
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    getPermit(entityId?: string): CustomStore {
        const q = new EntityQuery('Permit').addOrderBy(['no']);
        if (entityId) {
            q.eq('entityId', entityId);
        }
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    getClient(): CustomStore {
        const q = new EntityQuery('ContractView').addOrderBy(['pt.name']);
        q.fields.push('c.toId', 'pt.name', 'pt.cif');
        const cso = EntityStore.store(q);
        return new CustomStore(cso);
    }

    getContractView(type: string, partyId: string = null): CustomStore {
        const q = new EntityQuery('ContractView');
        if (type !== null) {
            q.eq('et.code', type);
        }
        if (partyId !== null) {
            q.eq('c.partyId', partyId);
        }
        const cso = EntityStore.store(q, false, 'c_id');
        return new CustomStore(cso);
    }

    getContract(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Contract'), false, 'id');
        return new CustomStore(cso);
    }

    addEditContract(obj: any, id: string) {
        const s = EntityStore.fromQuery(new EntityQuery('Contract').addOrderByDesc(['endDate']));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    removeContract(id: string) {
        return EntityStore.fromQuery(new EntityQuery('Contract')).remove(id);
    }

    getProject() {
        const cso = EntityStore.store(new EntityQuery('Project').addOrderBy(['startDate']), false, 'id');
        return new CustomStore(cso);
    }

    getContractParty(contractId: string) {
        const q = new EntityQuery('ContractPartyView').eq('cp.contractId', contractId).addOrderBy(['p.name']);
        const cso = EntityStore.store(q);
        return new CustomStore(cso);
    }

    getContractAnnex(contractId: string) {
        const q = new EntityQuery('ContractAnnex').eq('contractId', contractId).addOrderBy(['no']);
        const cso = EntityStore.store(q);
        return new CustomStore(cso);
    }

    invoices(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Invoice').addOrderByDesc(['date']), false, 'id');
        return new CustomStore(cso);
    }

    getInvoiceItems(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('InvoiceItemView').addOrderByDesc(['i.date']), false, 'ii_id');
        return new CustomStore(cso);
    }

    getItemWaste(itemId): CustomStore {
        const cso = EntityStore.store(new EntityQuery('InvoiceItemWaste').eq('invoiceItemId', itemId), false, 'id');
        return new CustomStore(cso);
    }

    addEditInvoice(obj: any, id: string) {
        const s = EntityStore.fromQuery(new EntityQuery('Invoice'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    addEditInvoiceItem(obj: any, id?: string) {
        const s = EntityStore.fromQuery(new EntityQuery('InvoiceItem'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    addEditInvoiceItemWaste(obj: any, id?: string) {
        const s = EntityStore.fromQuery(new EntityQuery('InvoiceItemWaste'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    inviteUser(userDetails: any, roleId: string) {
        if (roleId) {
            userDetails.roleId = roleId;
        }
        userDetails.role = 'user';
        return EntityStore.execute('Invite', userDetails, environment.authServer);
    }
    getTreatment(isView?: boolean): CustomStore {
        let cso;
        if (isView) {
            cso = EntityStore.store(new EntityQuery('TreatmentView').addOrderByDesc(['t.date']), false, 't_id');
        } else {
            cso = EntityStore.store(new EntityQuery('Treatment').addOrderByDesc(['date']), false, 'id');
        }
        return new CustomStore(cso);
    }
    getTreatmentItemView(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('TreatmentItemView').addOrderByDesc(['ti.created']), false, 'i_id');
        return new CustomStore(cso);
    }

    getActualStock(wasteTypeId?: string, workCenterId?: string): CustomStore {
        let cso;
        if (wasteTypeId) {
            const q = new EntityQuery('ActualStock')
                .linkEq('wasteCode', 'code', 'WasteType', 'id', wasteTypeId).gt('remain', 0);
            if (workCenterId) {
                q.linkEq('currentWorkCenter', 'name', 'Party', 'id', workCenterId);
            }
            cso = EntityStore.store(q.addOrderByDesc(['date']));
            return new CustomStore(cso);
        }
        cso = EntityStore.store(new EntityQuery('ActualStock').addOrderBy(['date']), false, 'id');
        return new CustomStore(cso);
    }

    getActualStockFilter(filter: any) {
        const tomorrow = new Date(filter.date);
        tomorrow.setDate(new Date(filter.date).getDate() + 1);

        const currentDay = new Date(filter.date);
        currentDay.setHours(0);
        currentDay.setMinutes(0);
        currentDay.setSeconds(0);
        currentDay.setMilliseconds(0);

        const q = new EntityQuery('ActualStock').gte('date', currentDay).lt('date', tomorrow).addOrderBy(['date']);
        if (filter.wc) {
            q.eq('workCenter', filter.wc);
        }
        if (filter.wasteCode) {
            q.eq('wasteCode', filter.wasteCode);
        }
        if (filter.entity) {
            q.eq('company', filter.entity);
        }

        return new CustomStore(EntityStore.store(q, false, 'id'));
    }


    removeInvoice(id: string) {
        return EntityStore.fromQuery(new EntityQuery('Invoice')).remove(id);
    }

    removeInvoiceItem(id: string) {
        return EntityStore.fromQuery(new EntityQuery('InvoiceItem')).remove(id);
    }

    wasteType(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('WasteType').addOrderBy(['code']), false, 'id');
        return new CustomStore(cso);
    }

    getWasteTypeById(wasteTypeId: string) {
        const q = new EntityQuery('WasteType').eq('id', wasteTypeId);
        return EntityStore.fromQuery(q).single();
    }
    getWasteTypeParent() {
        const q = new EntityQuery('WasteType').eq('parentId', null).eq('parentId', '00000000-0000-0000-0000-000000000000');
        q.conditionGroups.useOr = true;
        return new CustomStore(EntityStore.store(q));
    }
    getWasteTypeChildren() {
        const q = new EntityQuery('WasteType').neq('parentId', null).neq('parentId', '00000000-0000-0000-0000-000000000000');
        q.conditionGroups.useOr = false;
        return new CustomStore(EntityStore.store(q));
    }

    partyWaste(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('WasteTypePartyView').addOrderBy(['wt.code']), false, 'wtp_id');
        return new CustomStore(cso);
    }

    countPartyWaste() {
        const q = new EntityQuery('WasteTypePartyView');
        return EntityStore.fromQuery(q).function([{ field: 'wtp.id', ft: SimpleFunctionType.Count }]);
    }

    getPartyWasteViewByWc(wc: string): CustomStore {
        const cso = EntityStore.store(new EntityQuery('WasteTypePartyView').eq('wtp_workCenterId', wc).
            addOrderBy(['wt.code']), false, 'wtp_id');
        return new CustomStore(cso);
    }

    getWasteTypePartyView(id: string, type: string): CustomStore {
        const cso = EntityStore.store(new EntityQuery('WasteTypePartyView').eq(type, id).
            addOrderBy(['wt.code']), false, 'wtp_id');
        return new CustomStore(cso);
    }

    getWasteType(hazard?: number) {
        const q = new EntityQuery('WasteType');
        if (hazard) {
            q.eq('hazard', hazard);
        }
        return new CustomStore(EntityStore.store(q, false, 'id'));

    }

    getWasteTypeFromStock(workCenterId: string, existing: string = null, wasteTypeTypeId: string = null): CustomStore {
        const q = new EntityQuery('WasteType').neq('parentId', '00000000-0000-0000-0000-000000000000')
            .addOrderBy(['code']);
        q.distinct = true;
        q.linkEq('id', 'wasteTypeId', 'WasteTypeParty', 'workCenterId', workCenterId);
        q.link('code', 'wasteCode', new EntityQuery('ActualStock').gt('remain', 0));
        if (wasteTypeTypeId != null && wasteTypeTypeId != '') {
            q.eq('typeId', wasteTypeTypeId);
        }
        q.fields.push('id', 'code', 'name', 'hazard', 'uom');
        if (existing) {
            let qExist = new EntityQuery('WasteType').eq('id', existing);
            qExist.fields.push('id', 'code', 'name', 'hazard', 'uom');
            q.union(qExist);
        }
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    getWasteTypeForWorkCenter(workCenterId: string, hazard?: number, wasteTypeTypeId: string = null): CustomStore {
        const q = new EntityQuery('WasteType').neq('parentId', '00000000-0000-0000-0000-000000000000')
            .addOrderBy(['code']);
        q.distinct = true;
        if (workCenterId) {
            q.linkEq('id', 'wasteTypeId', 'WasteTypeParty', 'workCenterId', workCenterId);
        }
        if (hazard !== undefined && hazard !== null) {
            q.eq('hazard', hazard);
        }
        if (wasteTypeTypeId != null && wasteTypeTypeId != '') {
            q.eq('typeId', wasteTypeTypeId);
        }
        q.fields.push('id', 'code', 'name', 'hazard', 'uom');
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    getActiveWasteTypes(): CustomStore {
        const q = new EntityQuery('WasteType').neq('parentId', '00000000-0000-0000-0000-000000000000')
            .addOrderBy(['code']);
        q.distinct = true;
        q.link('id', 'wasteTypeId', new EntityQuery('WasteTypeParty'));
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    getWorkCenterForActualStock() {
        const q = new EntityQuery('Party').eq('typeId', 2).addOrderBy(['name']);
        q.distinct = true;
        q.fields.push('id', 'name');
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    getWasteTypeByEntity(entityId?: string, buId?: string) {
        const q = new EntityQuery('WasteType').addOrderBy(['code']);
        const qp = new EntityQuery('Party');
        if (buId) {
            qp.linkEq('parentId', 'id', 'Party', 'parentId', buId);
        }
        if (entityId) {
            qp.eq('typeId', 2).eq('parentId', entityId);
        }
        q.link('id', 'wasteTypeId', new EntityQuery('WasteTypeParty').link('workCenterId', 'id', qp));
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    getWasteTypeParty(wasteTypeId: string): Promise<any> & JQueryPromise<any> {
        const q = new EntityQuery('WasteTypeParty').eq('wasteTypeId', wasteTypeId);
        return EntityStore.fromQuery(q).single();
    }

    getTransportApproval(id: string) {
        return EntityStore.fromQuery(new EntityQuery('TransportApprovalView').eq('ta.id', id)).single();
    }


    // tslint:disable-next-line: max-line-length
    getTransportApprovalView(generatorId?: string, destinationId?: string, transporterId?: string, wasteTypeId?: string, carId?: string, registerNo?: string, driverId?: string, driverName?: string): CustomStore {
        const q = new EntityQuery('TransportApprovalView').addOrderByDesc(['ta.startDate']);
        if (generatorId) {
            q.eq('ta_partyId', generatorId);
        }
        if (destinationId) {
            q.eq('ta_destinationId', destinationId);
        }
        if (transporterId) {
            q.eq('ta_transporterId', transporterId);
        }
        if (wasteTypeId) {
            q.eq('ta_wasteTypeId', wasteTypeId);
        }
        // if (carId) {
        //     q.eq('ta_carId', carId);
        // }
        // if (driverId) {
        //     q.eq('ta_driverId', driverId);
        // }
        const cso = EntityStore.store(q, false, 'ta_id');
        return new CustomStore(cso);
    }

    removeTransportApproval(id: string) {
        return EntityStore.fromQuery(new EntityQuery('TransportApproval')).remove(id);
    }

    resetValidation(component: any) {
        if (component) {
            component.reset();
            component.option('isValid', true);
        }
    }

    addEditWasteParty(obj: any, id: string) {
        const s = EntityStore.fromQuery(new EntityQuery('WasteTypeParty'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    getPartyWasteById(workcenterId: string) {
        return EntityStore.fromQuery(new EntityQuery('WasteTypeParty').eq('workCenterId', workcenterId)).single();
    }

    initStatistics() {
        this.statistic = { trUnf: 0, wtNo: 0, wkNo: 0, trNo: 0, avNo: 0, masNo: 0 };
        EntityStore.execute('GlobalStatistic', {}).then(data => {
            this.statistic = data;
        }, err => {
            this.serverError(err);
        });
    }

    getGraphData(filter: any) {
        const obj: any = {};
        Object.assign(obj, filter);
        obj.fromDate = DataService.formatDate(obj.fromDate);
        obj.thruDate = DataService.formatDate(obj.thruDate);
        return EntityStore.execute('GetGraphData', obj);
    }

    removeWasteParty(id: string) {
        return EntityStore.fromQuery(new EntityQuery('WasteTypeParty')).remove(id);
    }

    aPermits(): CustomStore {
        this.monthTwo.setMonth(new Date().getMonth() + 2);
        const q = new EntityQuery('Permit').eq('typeId', 2).lt('validity', this.monthTwo).eq('isValid', true);
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    aPermitsInt(): CustomStore {
        this.monthTwo.setMonth(this.monthTwo.getMonth() + 4);
        const q = new EntityQuery('Permit').eq('typeId', 3).lt('validity', this.monthTwo).eq('isValid', true);
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    aContracts(): CustomStore {
        this.monthTwo.setMonth(new Date().getMonth() + 3);
        const q = new EntityQuery('Contract').eq('isValid', true).lt('endDate', this.monthTwo);
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    aTransports(): CustomStore {
        const days = new Date();
        days.setDate(days.getDate() - 3);
        const q = new EntityQuery('TransportView').eq('t_finishDate', null).lt('t_sendDate', days);
        return new CustomStore(EntityStore.store(q, false, 't_id'));
    }

    aControlMeasures(): CustomStore {
        const q = new EntityQuery('ControlMeasure').neq('actionStatus', 1);
        return new CustomStore(EntityStore.store(q));
    }

    getAgencies(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('AgencyView').addOrderBy(['a.name']), false, 'a_id');
        return new CustomStore(cso);
    }

    removeAgency(id: string) {
        return EntityStore.fromQuery(new EntityQuery('Agency')).remove(id);
    }

    getAgencyById(id: string): Promise<any> & JQueryPromise<any> {
        return EntityStore.fromQuery(new EntityQuery('Agency').eq('id', id)).single();
    }

    geographicAreas(typeId: number): CustomStore {
        const q = new EntityQuery('GeographicArea').addOrderBy(['name']);
        if (typeId) {
            q.eq('typeId', typeId);
            q.fields.push('id', 'name');
        }
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    addEditGeographicArea(obj: any, id: string) {
        const s = EntityStore.fromQuery(new EntityQuery('GeographicArea'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    removeGeographicArea(id: string) {
        return EntityStore.fromQuery(new EntityQuery('GeographicArea')).remove(id);
    }

    getLocalitiesByCountyId(countyId: string) {
        const q = new EntityQuery('GeographicArea').eq('typeId', 1).eq('parentId', countyId).addOrderBy(['name']);
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    getLocalityById(id: string): Promise<any> & JQueryPromise<any> {
        return EntityStore.fromQuery(new EntityQuery('GeographicArea').eq('id', id)).single();
    }

    nomenclaturesVal(typeId): CustomStore {
        const cso = EntityStore.store(new EntityQuery('EnumValue').eq('typeId', typeId).addOrderBy(['code']), false, 'id');
        return new CustomStore(cso);
    }

    getEnumValue(code: string): CustomStore {
        const q = new EntityQuery('EnumValue').addOrderBy(['code']).
            linkEq('typeId', 'id', 'EnumType', 'code', code);
        q.fields.push('id', 'code', 'description');
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    getEnumValueMultiple(code: string[]): CustomStore {
        const q = new EntityQuery('EnumValue').addOrderBy(['code']).
            link('typeId', 'id', new EntityQuery('EnumType').in('code', code));
        q.fields.push('id', 'code', 'description');
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    enumDisplay(info: any) {
        return info ? info.code + ' - ' + (info.description || '') : '';
    }

    nomenclaturesType(): CustomStore {
        const q = new EntityQuery('EnumType').addOrderBy(['code']);
        if (this.static.excludeEnumType && this.static.excludeEnumType.length > 0) {
            q.nin('code', this.static.excludeEnumType);
        }
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    addEditNomenclatures(obj: any, id: string, model: string) {
        const s = EntityStore.fromQuery(new EntityQuery(model));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    validClients(): CustomStore {
        const q = new EntityQuery('Party').like('activitiesType', '%Generare%').eq('isActiv', true).addOrderBy(['name']);
        q.distinct = true;
        q.fields.push('id', 'name');
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    validTransports(): CustomStore {
        const q = new EntityQuery('Party').like('activitiesType', '%Transport%').eq('isActiv', true).addOrderBy(['name']);
        // .linkEq('id', 'toId', 'Contract', 'isValid', true);
        q.distinct = true;
        q.fields.push('id', 'name');
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    getValidPaginateTransports(): DataSource {
        return new DataSource({
            store: this.validTransports(),
            paginate: true,
            pageSize: 25
        });
    }

    validDest(): CustomStore {
        const q = new EntityQuery('Party').addOrderBy(['name']).
            like('activitiesType', '%Eliminare%').like('activitiesType', '%Valorificare%');
        // .linkEq('id', 'toId', 'Contract', 'isValid', true);
        q.conditionGroups.useOr = true;
        q.distinct = true;
        q.fields.push('id', 'name');
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    getAudit(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('AuditTrail').eq('appId', this.auth.companyId).addOrderByDesc(['eventDate']), false, 'id', environment.saasServer);
        return new CustomStore(cso);
    }

    getLicenseItem(): CustomStore {
        const q = new EntityQuery('LicenseItem');
        q.link('licenseId', 'id', new EntityQuery('License').eq('appId', this.auth.companyId));
        const cso = EntityStore.store(q, false, 'id', environment.saasServer);
        return new CustomStore(cso);
    }

    getLicenseItemExtra(): CustomStore {
        const q = new EntityQuery('LicenseItemExtra');
        q.link('licenseItemId', 'id', new EntityQuery('LicenseItem'));
        const cso = EntityStore.store(q, false, 'id', environment.saasServer);
        return new CustomStore(cso);
    }

    getRoles() {
        const q = new EntityQuery('UserLoginRole').addOrderBy(['name']);
        q.link('groupId', 'rolegroupid', new EntityQuery('ApplicationConfig')
            .linkEq('id', 'configId', 'Application', 'id', this.auth.companyId));
        return new CustomStore(EntityStore.store(q, false, 'id', environment.saasServer));
    }

    getUsersByRoleId(roleId: string) {
        const q = new EntityQuery('ApplicationRole').eq('appId', this.auth.companyId);
        if (roleId) {
            q.eq('roleId', roleId);
        }
        // tslint:disable-next-line:max-line-length
        return new CustomStore(EntityStore.store(new EntityQuery('UserLogin').link('id', 'userId', q), false, 'id', environment.saasServer));
    }

    updateUserRole(newRoleId: string, arId: string) {
        return EntityStore.fromQuery(new EntityQuery('ApplicationRole'), false, 'id', environment.saasServer)
            .update(arId, { roleId: newRoleId });
    }

    getRoleByUserId(userId: string) {
        const q = new EntityQuery('UserLoginRole');
        q.fields.push('id', 'name');
        const arQ = new EntityQuery('ApplicationRole').eq('appId', this.auth.companyId).eq('userId', userId);
        arQ.fields.push('id');
        return EntityStore.fromQuery(q.link('id', 'roleId', arQ), false, 'id', environment.saasServer).query();
    }

    securityUsers(): CustomStore {
        const q = new EntityQuery('UserLoginView').eq('appId', this.auth.companyId).addOrderBy(['u.fullName']);
        const cso = EntityStore.store(q, false, 'u_id', environment.saasServer);
        return new CustomStore(cso);
    }

    countUsers() {
        const q = new EntityQuery('UserLoginView').eq('appId', this.auth.companyId).addOrderBy(['u.fullName']);
        return EntityStore.fromQuery(q, false, 'u_id', environment.saasServer)
            .function([{ field: 'u.id', ft: SimpleFunctionType.Count }]);
    }

    updateUserLogin(obj: any) {
        return EntityStore.fromQuery(new EntityQuery('UserLogin'), false, 'id', environment.saasServer).update(this.auth.user.id, obj);
    }

    userLoginParty(userId: string): CustomStore {
        const q = new EntityQuery('UserLoginParty').eq('userId', userId);
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    userLoginZone(userId: string): CustomStore {
        const q = new EntityQuery('UserLoginZone').eq('userId', userId);
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    getFileInfo(entityId: string): CustomStore {
        const q = new EntityQuery('FileInfo').eq('entityId', entityId).addOrderBy(['name']);
        const cso = EntityStore.store(q, false, 'id', environment.saasServer);
        return new CustomStore(cso);
    }

    removeFile(id: string) {
        const q = new EntityQuery('FileInfo');
        return EntityStore.fromQuery(q, false, 'id', environment.saasServer).remove(id);
    }

    getFileInfoSingle(entityId: string) {
        const q = new EntityQuery('FileInfo').eq('entityId', entityId).addOrderBy(['name']);
        return EntityStore.fromQuery(q, false, 'id', environment.saasServer).single();
    }

    getFiles() {
        const cso = EntityStore.store(new EntityQuery('FileInfo').addOrderBy(['name']), false, 'id');
        return new CustomStore(cso);
    }

    wasteTypes(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('WasteType').addOrderBy(['code']), false, 'id');
        return new CustomStore(cso);
    }

    getUnitWasteTypes(): CustomStore {
        const q = new EntityQuery('WasteType').neq('parentId', '00000000-0000-0000-0000-000000000000')
            .addOrderBy(['code']);
        q.fields.push('id', 'code');
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    getUnitWasteTypesPaginate(): DataSource {
        return new DataSource({ store: this.getUnitWasteTypes(), paginate: true, pageSize: 25 });
    }

    addEditWastetype(obj: any, id: string) {
        const s = EntityStore.fromQuery(new EntityQuery('WasteType'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    removeWasteType(id: string) {
        return EntityStore.fromQuery(new EntityQuery('WasteType')).remove(id);
    }

    getPartyById(id: string): Promise<any> & JQueryPromise<any> {
        return EntityStore.fromQuery(new EntityQuery('Party').eq('id', id)).single();
    }

    getCountId(localityId: string) {
        const q = new EntityQuery('GeographicArea').eq('id', localityId);
        q.fields.push('parentId');
        return EntityStore.fromQuery(q).single();
    }

    getIncineratedStock(ids: string[]) {
        const q = new EntityQuery('ActualStock').in('id', ids);
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    getFullTransportStock(ids: string[]) {
        const q = new EntityQuery('ActualStock').in('id', ids);
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    getPartyViewById(id: string) {
        return EntityStore.fromQuery(new EntityQuery('PartyView').eq('p_id', id)).single();
    }

    addEditParty(obj: any, id: string) {
        const s = EntityStore.fromQuery(new EntityQuery('Party'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }

    addDefaultGroup(entity: any) {
        const group = {
            name: 'Grup',
            cif: entity.cif,
            typeId: 0,
            countyId: entity.countyId,
            localityId: entity.localityId,
            address: entity.address,
            isActiv: true,
            isSubcontractor: false,
        };
        EntityStore.fromQuery(new EntityQuery('Party')).insert(group).then(result => {
            EntityStore.fromQuery(new EntityQuery('Party')).update(entity.id, { parentId: result.id });
        });
    }

    removeParty(id: string) {
        return EntityStore.fromQuery(new EntityQuery('Party')).remove(id);
    }

    setWasteCodeDataSourceForWorkCenter(workCenterId: string, hazard?: number, wasteTypeTypeId: string = null): DataSource {
        return new DataSource({
            store: this.getWasteTypeForWorkCenter(workCenterId, hazard, wasteTypeTypeId),
            paginate: true,
            pageSize: 25
        });
    }

    setWasteCodeDataSource(workCenterId: string, hazard?: number): DataSource {
        return new DataSource({
            store: this.getWasteTypeForWorkCenter(workCenterId, hazard),
            paginate: true,
            pageSize: 25
        });
    }

    boolean(type?): Array<{ id, name }> {
        return type === 'bool' ?
            [{ id: false, name: this.lg.format('NOT', null) }, { id: true, name: this.lg.format('YES', null) }] :
            [{ id: 0, name: this.lg.format('NOT', null) }, { id: 1, name: this.lg.format('YES', null) }];
    }

    getTreatmentType(type?: string) {
        switch (type) {
            case 'eliminare':
                return [{ id: 2, value: 'Sortare' }, { id: 3, value: 'Tratare' }];
            default:
                return [{ id: 0, value: 'Incinerare' }, { id: 1, value: 'Predare' },
                { id: 2, value: 'Sortare' }, { id: 3, value: 'Tratare' }];
        }
    }

    getReportType() {
        return [{ id: 0, value: this.lg.format('WASTE_MNG_SHEET', null) },
        { id: 1, value: this.lg.format('OIL_CARD', null) },
        { id: 2, value: this.lg.format('WASTE_REPORT', null) },
        { id: 3, value: this.lg.format('INTERNAL_REPORTS', null) }];
    }

    getPermitsType() {
        return [{ id: 0, value: this.lg.format('NOTICE', null) },
        { id: 1, value: this.lg.format('AGREEMENT', null) },
        { id: 2, value: this.lg.format('ENVIRONMENTAL_PERMIT', null) },
        { id: 3, value: this.lg.format('INTEGRATED_ENV_AUTH', null) }];
    }

    getMonth() {
        return [{ id: 1, name: this.lg.format('JANUARY', null) },
        { id: 2, name: this.lg.format('FEBRUARY', null) },
        { id: 3, name: this.lg.format('MARCH', null) },
        { id: 4, name: this.lg.format('APRIL', null) },
        { id: 5, name: this.lg.format('MAY', null) },
        { id: 6, name: this.lg.format('JUNE', null) },
        { id: 7, name: this.lg.format('JULY', null) },
        { id: 8, name: this.lg.format('AUGUST', null) },
        { id: 9, name: this.lg.format('SEPTEMBER', null) },
        { id: 10, name: this.lg.format('OCTOBER', null) },
        { id: 11, name: this.lg.format('NOVEMBER', null) },
        { id: 12, name: this.lg.format('DECEMBER', null) }];
    }

    getSemester() {
        return [{ id: 1, name: this.lg.format('SEM_1', null) }, { id: 2, name: this.lg.format('SEM_2', null) }];
    }
}
