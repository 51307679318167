import { Injectable } from '@angular/core';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from '../../../service/data.service';


@Injectable()
export class EditPermitService extends iBisEditService {

    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'permitValidation';
    }
    createDefault() {
        return { isValid: true };
    }
    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            EntityStore.fromQuery(new EntityQuery('Permit').eq('id', data.id)).single().then((obj) => {
                if (!obj) {
                    reject();
                } else {
                    if (data.entityId) {
                        obj.entityId = data.entityId;
                    }
                    obj.isEntityHidden = data.isEntityHidden;
                    resolve(obj);
                }
            });
        });
    }
    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Permit')).remove(id);
    }

    onSaveEv(): Promise<void> {
        const s = EntityStore.fromQuery(new EntityQuery('Permit'));
        return (this.model.id ? s.update(this.model.id, this.model) : s.insert(this.model));
    }

    reset() {
        this.model = this.createDefault();
    }
}
