import { Injectable } from '@angular/core';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import { iBisEditService } from '@dohu/ibis-common';
import { iBisLanguageService } from '@dohu/ibis-common';

@Injectable()
export class EditUnitService extends iBisEditService {

    constructor(lg: iBisLanguageService) {
        super(lg);
        this.validation = 'unitValidation';
    }

    createDefault(): any {
        return { isActiv: true, isSubcontractor: false };
    }

    getById(id: string): Promise<any> {
        return new Promise<any>( (resolve, reject) => {
            EntityStore.fromQuery(new EntityQuery('Party').eq('id', id)).single().
                then((obj) => {
                    if (obj === null) {
                        reject();
                    } else {
                        if (obj.localityId) {
                            const countyQ = new EntityQuery('GeographicArea').eq('id', obj.localityId);
                            countyQ.fields.push('parentId');
                            EntityStore.fromQuery(countyQ).single().then((c) => {
                                obj.countyId = c.parentId;
                                resolve(obj);
                            });
                        } else {
                            resolve(obj);
                        }
                    }
                }, (e) => reject(e));
        });
    }

    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Party')).remove(id);
    }

    onSaveEv() {
        if (!this.model.isSubcontractor) {
            this.model.isSubcontractor = false;
        }
        const s = EntityStore.fromQuery(new EntityQuery('Party'));
        return this.model.id ? s.update(this.model.id, this.model) : s.insert(this.model);
    }

    reset(): void {
        this.model = this.createDefault();
    }
}
