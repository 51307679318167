import { Injectable } from '@angular/core';
import { DataService } from '../../../service/data.service';
import notify from 'devextreme/ui/notify';

// export interface EditTaxInterface {
//      onSave();
// }

@Injectable()
export class EditTaxService {

    constructor(public ds: DataService) { }

    static onSave(funct) {
        return new Promise((resolve, reject) => {
            funct.then((result: any) => {
                resolve();
            }, err => {
                this.serverError(err);
            });
        });

    }

    static serverError(error?: string) {
        let msg = '';
        if (error) {
            const start = error.indexOf('The exception message is');
            const end = error.indexOf('The exception stack trace is:');
            msg = error.substring(start + 24, end - 34);
        }
        notify('Server error ' + (error ? ('[' + msg + ']') : ''), 'error', 3000);
    }

}

