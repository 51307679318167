import { Component, OnInit, ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import { UIService } from '../../../service/ui.service';
import { DataService } from '../../../service/data.service';

@Component({
    templateUrl: 'wastefile.component.html'
})
export class WastefileComponent implements OnInit {

    dsPartyWaste: CustomStore;
    dsReportType: any;
    @ViewChild('filesGrid') filesGrid: DxDataGridComponent;

    constructor(public ds: DataService, public ui: UIService) { }

    ngOnInit() {
        this.dsPartyWaste = this.ds.partyWaste();
    }

}
