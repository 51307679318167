import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { environment } from '../../../environments/environment';
import { DataService } from '../../service/data.service';
import { UIService } from '../../service/ui.service';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import { confirm } from 'devextreme/ui/dialog';
import { iBisFileService } from '@dohu/ibis-common';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html'
})
export class FileUploadComponent implements OnInit {
    @Input() gridData: any;
    @Input() height: any;

    @ViewChild('filesGrid') filesGrid: DxDataGridComponent;
    onDownloadClick: any;
    onUploadNewFileClick: any;
    constructor(public ui: UIService, public ds: DataService, public auth: iBisAuthService, private file: iBisFileService) { }

    ngOnInit() {
        this.onUploadNewFileClick = this.onUploadNewFileClickEv.bind(this);
        this.onDownloadClick = this.onDownloadClickEv.bind(this);
        if (!this.height) { this.height = 300; }
    }

    onUploadNewFileClickEv() {
        this.createUploadEvent(this.gridData.data, this.filesGrid);
    }

    onDownloadClickEv(ev: any) {
        this.file.Download(ev.row.data.id).then(
            () => { notify(this.ds.lg.format('FILES_DOWNLOADED', null), 'success', 2000); },
            () => { notify(this.ds.lg.format('DOWNLOAD_ERROR', null), 'error', 3000); });
    }

    onFilesGridInit(e: any) {
        if (e.component.option('dataSource') == null) {
            e.component.option('dataSource', this.ds.getFileInfo(this.gridData.data.id));
        }
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                icon: 'assets/img/grid_add.svg', hint: this.ds.lg.format('ADD_FILE', null), onClick: (e: any) => {
                    this.createUploadEvent(this.gridData.data, this.filesGrid);
                }
            },
            location: 'after'
        });
    }

    createUploadEvent(entity: any, gridComponent) {
        this.file.Upload(entity.id).then(
            () => {                 
                gridComponent.instance.refresh();
                notify(this.ds.lg.format('FILES_UPLOADED', null), 'success', 2000); 
            },
            () => { notify(this.ds.lg.format('UPLOAD_ERROR', null), 'error', 3000); }
        );
    }

    onDeleteFileClick = (ev: any) => {
        confirm(this.ds.lg.format('DELETE_QUESTION'), this.ds.lg.format('CONFIRM_DELETE')).then((response: boolean) => {
            if (response) {
                const q = new EntityQuery('FileInfo').eq('entityId', ev.row.data.entityId);
                EntityStore.fromQuery(q, false, 'id', environment.saasServer).remove(ev.row.data.id).then(() => {
                    this.filesGrid.instance.refresh();
                }, err => {
                    notify(err, 'error', 3000);
                });
            }
        });
    }
}
