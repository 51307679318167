import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../../service/data.service';
import { UIService } from '../../../../service/ui.service';

import CustomStore from 'devextreme/data/custom_store';
import { EditTransportService } from '../editTransport.service';
import { DxDataGridComponent } from 'devextreme-angular';

import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-full-transport',
  templateUrl: './fullTransport.component.html'
})

export class FullTransportComponent implements OnInit {
  onEditTransportClick: any;
  onDeleteTransportClick: any;
  dsFullTransport: CustomStore;
  dsElimination: CustomStore;
  dsDestinationTypes: CustomStore;

  @ViewChild('gridFullTransport') gridFullTransport: DxDataGridComponent;

  constructor(public ds: DataService, public ui: UIService, public edit: EditTransportService) {
    this.onEditTransportClick = this.onEditTransportClickEv.bind(this);
    this.onDeleteTransportClick = this.onDeleteTransportClickEv.bind(this);
  }

  ngOnInit() {
    this.dsElimination = this.ds.getEliminateInfo();
    this.dsFullTransport = this.ds.getFullTransport();
    this.dsDestinationTypes = this.ds.getEnumValue('Destinatie');
  }

  toolbarPreparing(ev: any) {
    this.ui.prepareToolbar(ev, true);
    ev.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        icon: 'assets/img/grid_add.svg', hint: 'Adaugă rând',
        onClick: () => this.edit.showPopup(null).then(() => ev.component.refresh())
      },
      location: 'after'
    });
  }

  getActions(val: number) {
    const arr = this.ds.static.transportActions;
    const result = [];
    for (const i of arr) {
      // tslint:disable-next-line:no-bitwise
      if ((val & i.id) === i.id) {
        result.push(i.value);
      }
    }
    return result.join(', ');
  }

  onEditTransportClickEv(ev: any) {
    ev.event.preventDefault();

    this.edit.showPopup(ev.row.data.t_id, true).then(
      () => this.gridFullTransport.instance.refresh(),
      (error) => this.ds.serverError(error)
    );
  }

  onDeleteTransportClickEv(ev: any) {
    ev.event.preventDefault();
    confirm('Ești sigur că vrei să ștergi această înregistrare ?', 'Confirmare').then(val => {
      if (val) {
        this.edit.onRemove(ev.row.data.t_id).then(() => {
          notify('Success', 'success', 2000);
          this.gridFullTransport.instance.refresh();
        }, err => {
          this.ds.serverError(err);
        });
      }
    });
  }
}
